import React, { useState } from "react";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DoneIcon from "@mui/icons-material/Done";
import { Button } from "../../../components/Wrappers/Wrappers";

function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      let resp = await navigator.clipboard.writeText(text);
      return resp;
    } else {
      let err = document.execCommand("copy", true, text);
      return err;
    }
  }
  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div>
      <Button
        onClick={handleCopyClick}
        style={{ height: "0px" }}
        sx={{ "&:hover": { backgroundColor: "transparent" } }}
      >
        <span>
          {isCopied ? (
            <DoneIcon
              color="primary"
              style={{ paddingTop: "4px", textTransform: "none" }}
            />
          ) : (
            <InsertDriveFileIcon
              color="primary"
              style={{ paddingTop: "4px", textTransform: "none" }}
            />
          )}
        </span>
      </Button>
    </div>
  );
}
export default ClipboardCopy;
