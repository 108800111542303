import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  Input,
  InputLabel,
  Button,
  CircularProgress,
  Grid,
  FormHelperText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import useStyles from "../styes";
import { addContact } from "../ContactState";
import { createMprId } from "../../material_pickup_request/MprState";
import NotificationContainer from "../../notifications/NotificationContainer";
const Forms = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const pathLocation = location.pathname;
  
  const { t, i18n } = useTranslation();
  const custData = useSelector((state) => state);
  let history = useHistory();
  const dispatch = useDispatch();
  const [fullName, setfullName] = useState(
    Object.keys(custData.selectedUser).length > 0
      ? custData.selectedUser.Name
      : ""
  );
  const [mobileNumber, setmobileNumber] = useState(
    Object.keys(custData.selectedUser).length > 0
      ? custData.selectedUser.mobile.number
      : ""
  );
  const [countryCode, setcountryCode] = useState(
    Object.keys(custData.selectedUser).length > 0
      ? custData.selectedUser.mobile.code
      : "+91"
  );
  const [email, setEmail] = useState(
    Object.keys(custData.selectedUser).length > 0
      ? custData.selectedUser.email
      : ""
  );

  const handleDateChange = (e) => {
    const formattedDateForDatabase = e.toISOString();
    setDateOfJoiningBinbag(formattedDateForDatabase);
  };

  const [dateOfJoiningBinbag, setDateOfJoiningBinbag] = useState(
    custData.selectedUser?.employeeData?.dateOfJoiningBinbag
  );

  const [showLoader, setShowLoader] = useState(false);
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [notify, setNotify] = useState(false);
  const state = useSelector((state) => state.userdata);
  var customerData = custData.selectedCustomer
    ? custData.selectedCustomer._id
    : "";
  var branchData = custData.selectedBranch ? custData.selectedBranch._id : "";
  const [allError, setAllError] = useState({});
  const [errors, setErrors] = useState({});
  const [errorMobiles, setErrorsMobile] = useState({});
  const [isEmailDisabled, setIsEmailDisabled] = useState(true);
  const reset = () => {
    setfullName("");
    setmobileNumber("");
    setEmail("");
  };
  const formBack = () => {
    dispatch({
      type: "SET_SELECTED_USER",
      payload: [],
    });
    if (localStorage.getItem("type") === "Customer") {
      history.push("/app/customerBranch");
    } else {
      history.push("/app/Recyclercontacts");
    }
  };
  const handleClose = () => {
    props.close();
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailValue.trim() === "") {
      setErrors((prevErrors) => ({ ...prevErrors, emailError: "" }));
      setIsEmailDisabled(true);
    } else if (!emailRegex.test(emailValue)) {
      setErrors({ emailError: "Please enter a valid email address" });
      setIsEmailDisabled(true);
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, emailError: "" }));
      setIsEmailDisabled(false);
    }
  };

  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    const numericRegex = /^\d*$/;
    if (value.length <= 10 && numericRegex.test(value)) {
      setErrorsMobile({ mobileNumberError: "" });
      setmobileNumber(value);
    } else {
      setErrorsMobile({
        mobileNumberError: "Please enter a valid 10-digit phone number",
      });
    }
  };

  const handleSubmit = () => {
    if (!fullName || !mobileNumber || !countryCode || !email) {
      setAllError({ overAllError: "All fields are required" });
      return;
    }
    if (
      !dateOfJoiningBinbag &&
      localStorage.getItem("type") === "tenant" &&
      pathLocation == "/app/addRecyclerContact"
    ) {
      setAllError({ overAllError: "All fields are required" });
      return;
    }

    var role =
      localStorage.getItem("type") === "tenant" ? "user" : "Branch_Admin";
    var customerId =
      localStorage.getItem("type") === "Customer"
        ? localStorage.getItem("customerid")
        : customerData;
    var abbriviation =
      localStorage.getItem("type") === "Customer"
        ? state.getData.abbrevation
        : custData.selectedCustomer.abbrevation;

    var data = {
      fullName: fullName,
      mobileNumber: mobileNumber,
      countryCode: countryCode,
      email: email,
      customerId: customerId,
      role: role,
      branch:
        state.getData &&
        "thisCustomerBranch" in state.getData &&
        state.getData.thisCustomerBranch !== ""
          ? state.getData.thisCustomerBranch
          : branchData,
      userId:
        Object.keys(custData.selectedUser).length > 0
          ? custData.selectedUser._id
          : "",
      EditUserId: state.getData._id,
      ...(localStorage.getItem("type") === "tenant" &&
        pathLocation == "/app/addRecyclerContact" && {
          employeeData: {
            dateOfJoiningBinbag: dateOfJoiningBinbag,
            hasLeftBinbag: false,
            casual_leave_left: 1,
            emergency_leave_left: 5,
            marriage_leave_left: 3,
          },
        }),
    };

    let sentData = {
      abbrevation: abbriviation,
      customerid: customerId,
      thisCustomerBranch:
        state.getData &&
        "thisCustomerBranch" in state.getData &&
        state.getData.thisCustomerBranch !== ""
          ? state.getData.thisCustomerBranch
          : branchData,
    };
    setShowLoader(true);
    addContact(data, dispatch).then((response) => {
      if (response.status === "200") {
        setType("success");
        setMessage(response.message);
        setNotify(true);
        createMprId(sentData, dispatch);
        setTimeout(() => {
          setShowLoader(false);
        }, 2000);
        dispatch({
          type: "SET_SELECTED_USER",
          payload: [],
        });
        setTimeout(() => props.close(), 2000);
      } else {
        let msg = [];
        if (response.ErrorData) {
          response.ErrorData.details.map((item) => {
            msg.push(item.message);
          });
          setType("error");
          setMessage(`${msg.join()}`);
          setNotify(true);
          setShowLoader(false);
        } else {
          setType("error");
          setMessage(response.message);
          setNotify(true);
          setShowLoader(false);
        }
      }
    });
  };

  return (
    <div className={classes.formField}>
      {notify ? (
        <NotificationContainer message={message} notificationtype={type} />
      ) : (
        <></>
      )}
      <form autoComplete="off">
        {allError.overAllError && (
          <p style={{ color: "red" }}>{allError.overAllError}</p>
        )}
        <div>
          <FormControl variant="standard" fullWidth className={classes.margin}>
            <InputLabel htmlFor="full_name">{t("common_full_name")}</InputLabel>
            <Input
              id="full_name"
              name="full_name"
              value={fullName}
              onChange={(e) => setfullName(e.target.value)}
            />
          </FormControl>

          {localStorage.getItem("type") == "tenant" &&
            pathLocation == "/app/addRecyclerContact" && (
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                fullWidth
                className={classes.margin}
              >
                <DatePicker
                  id="date_of_joining_binbag"
                  label={t("Date Of Joining Binbag")}
                  type="date"
                  closeOnSelect={true}
                  sx={{ width: "100%" }}
                  InputLabelProps={{ shrink: true }}
                  required
                  disableFuture
                  format="DD-MM-YYYY"
                  disabled={
                    custData.selectedUser.employeeData?.dateOfJoiningBinbag ? true : false
                  }
                  value={dateOfJoiningBinbag ? dayjs(dateOfJoiningBinbag) : null}
                  onChange={(e) => handleDateChange(e)}
                  slotProps={{ textField: { variant: "standard" } }}
                />
              </LocalizationProvider>
            )}
        </div>
        <Grid container spacing={1}>
          <Grid item lg={3} md={3} xs={12} sm={12}>
            <FormControl
              variant="standard"
              fullWidth
              className={classes.margin}
              style={{ pointerEvents: "none" }}
            >
              <InputLabel htmlFor="country_code">
                {t("common_country_code")}{" "}
              </InputLabel>
              <Input
                id="country_code"
                value={countryCode}
                onChange={(e) => setcountryCode(e.target.value)}
                readOnly
              />
            </FormControl>
          </Grid>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <FormControl
              variant="standard"
              fullWidth
              className={classes.margin}
            >
              <InputLabel htmlFor="mobile_number">
                {t("common_mobile_no")}
              </InputLabel>
              <Input
                id="mobile_number"
                value={mobileNumber}
                onChange={handleMobileNumberChange}
              />
              {errorMobiles.mobileNumberError && (
                <FormHelperText style={{ color: "red" }}>
                  {errorMobiles.mobileNumberError}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <div>
          <FormControl variant="standard" fullWidth className={classes.margin}>
            <InputLabel htmlFor="email">{t("common_email")}</InputLabel>
            <Input
              id="email"
              type="email"
              required
              disabled={
                Object.keys(custData.selectedUser).length > 0 ? true : false
              }
              value={email}
              onChange={handleEmailChange}
            />
            {errors.emailError && (
              <FormHelperText style={{ color: "red" }}>
                {errors.emailError}
              </FormHelperText>
            )}
          </FormControl>
        </div>
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {props.where === "page" ? (
            <Button onClick={formBack} className={classes.button}>
              {t("common_back")}
            </Button>
          ) : (
            <></>
          )}
          {props.where === "modal" ? (
            <Button
              variant="outlined"
              onClick={handleClose}
              className={classes.button}
            >
              {t("common_back")}
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={reset}
              className={classes.button}
              disabled={showLoader}
            >
              {t("common_reset")}
            </Button>
          )}

          {showLoader ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleSubmit}
              // disabled={isEmailDisabled || isPhoneDisabled}
              // disabled={
              //   localStorage.getItem("type") === "tenant" &&
              //   !dateOfJoiningBinbag
              // }
            >
              {t("common_finish")}
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Forms;
