import React from "react";
import { Grid, Paper } from "@mui/material";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import useStyles from "./styes";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import Forms from "./components/Forms";

const AddContact = () => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const isCustomer = localStorage.getItem("type") !== "tenant";

  const breadcrumb = [
    { label: t("Home"), link: "" },
    {
      label: isCustomer ? t("common_branch") : t("Administration"),
      link: isCustomer ? "/#/app/customerBranch" : "/#/app/Recyclercontacts",
    },
    { label: isCustomer ? t("contact_add") : "Add Employee", link: "" },
  ];
  const classes = useStyles();
  const handleRedirect = () => {
    localStorage.getItem("type") === "tenant"
      ? history.push("/app/Recyclercontacts")
      : history.push("/app/customerBranch");
  };

  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <Grid container spacing={3}>
          <Grid item lg={3} md={1} xs={0} sm={0}></Grid>
          <Grid item lg={6} md={10} xs={12} sm={12}>
            <Paper
              className={classes.paper}
              classes={{
                root: classnames(classes.widgetRoot, {
                  [classes.noWidgetShadow]: null,
                }),
              }}
            >
              <div className={classes.form}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sm={12}
                    style={{ width: "width: calc(100% - 2*15px)" }}
                  >
                    <h3 className={classes.title}>
                      {t("common_basic_details")}
                    </h3>
                    <Forms where="page" close={handleRedirect} />
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default AddContact;
