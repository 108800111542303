import { Button, Typography } from "@mui/material";
import { useState } from "react";

const ReadMoreText = ({ text, maxLength = 50, fontSize = "14px" }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length <= maxLength) {
    return (
      <Typography variant="body1" fontSize={fontSize}>
        {text}
      </Typography>
    );
  }

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <Typography variant="body1" fontSize={fontSize}>
        {isExpanded ? text : `${text.slice(0, maxLength)}...`}
      </Typography>
      <Button onClick={toggleReadMore} color="primary" size="small">
        {isExpanded ? "Read Less" : "Read More"}
      </Button>
    </>
  );
};

export default ReadMoreText;
