import React from "react";
import {
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
export default function ConfirmDialog({
  open,
  title,
  description,
  onCancel,
  onConfirm,
  isLoading,
}) {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={isLoading} sx={{ color: "gray" }}>
          Cancel
        </Button>
        <Button onClick={onConfirm} autoFocus color="primary">
          {isLoading ? <CircularProgress size={22} /> : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
