import { apiUrl } from "../../data/config";
export function getWebProfileDownloaders() {
  let url = apiUrl + "/v1/web/getProfileDownloaders";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json().then((data) => {
        if (data.status === "200") {
          return data;
        } else if (data.status === "500") {
          return data;
        }
      });
    } else {
      return {
        status: "500",
        message: "Something went wrong",
      };
    }
  });
}
