import React, { useState } from "react";
import { Button, Paper, CircularProgress, Box, Container } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import BasicDetails from "./pages/BasicDetails";
import { addCustomer } from "./CustomerState";

export default function AddCustomer(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);

  const methods = useForm({
    defaultValues: {
      companyName: "",
      contactName: "",
      email: "",
      mobile: "",
      pan_no: "",
      taxNo: "",
      abbreviation: "",
      notificationAllowed: "",
      countryCode: "+91",
      companySize: "",
      createdAt: new Date().toISOString(),
      isActive: true,
      isprimary: true,
      isTemperory: true,
      createdBy: "System",
    },
  });

  const breadcrumb = [
    { label: t("common_customers"), link: "/#/app/customers" },
    { label: t("Add customer"), link: "" },
  ];

  const handleSubmit = (data) => {
    setShowLoader(true);
    addCustomer(data).then((response) => {
      if (response.status === "200") {
        toast.success(response.message);
        setTimeout(() => props.history.push("/app/customers"), 2000);
      } else if (response.status === "401") {
        toast.error(response.message);
        setTimeout(() => {
          dispatch({ type: "LOG_OUT" });
          localStorage.clear();
        }, 2000);
      } else if (response.status === "500") {
        toast.error(response.message);
      } else {
        handleErrorResponse(response);
      }
      setShowLoader(false);
    });
  };

  const handleErrorResponse = (response) => {
    if (response.ErrorData) {
      const msg = response.ErrorData.details
        .map((item) => item.message)
        .join(", ");
      toast.error(msg);
    } else {
      toast.error("Something went Wrong! Please try again!");
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{ width: { md: "80%" }, mx: "auto", mt: 12 }}
    >
      <Box mb={3}>
        <BreadCrumbs data={breadcrumb} />
      </Box>

      <Paper
        elevation={1}
        sx={{
          width: "100%",
          p: { md: 4, xs: 1 },
          backgroundColor: "background.paper",
          borderRadius: 2,
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <BasicDetails />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 4,
                px: 2,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                size="large"
                sx={{
                  textTransform: "none",
                  minWidth: 120,
                  px: 4,
                }}
                disabled={showLoader}
              >
                {showLoader ? <CircularProgress size={28} /> : "Save"}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Paper>
    </Container>
  );
}
