import {
  Typography,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  Box,
  Grid,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "../styles";
import { formatIndianCurrency } from "../../../utils/currencyFormat";
import { ConfirmationDialog } from "../components/ConfirmationDialog";
import {
  actionOnMiscellaneousRequest,
  getAdvancePaymentStatus,
  getFile,
  getPaymentHistoryStatus,
} from "../InternalFormState";
import StatusHistory from "../components/StatusHistory";
import HeaderComp from "../components/HeaderComp";
import {
  ADVANCE_PAYMENT_STATUS,
  commonStatus,
} from "../components/config/contants";

import RenderAttachments from "../components/RenderAttachments";
import toast, { Toaster } from "react-hot-toast";

const EditMiscellaneousReq = () => {
  var classes = useStyles();
  const miscellaneousReqData = useSelector(
    (state) => state.selectedMiscellaneousRequest
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedData, setSelectedData] = useState(miscellaneousReqData);
  const loggedInData = useSelector((state) => state.userdata.getData);
  const [selectedStatus, setSelectedStatus] = useState(selectedData?.status);
  const [isViewer, setIsViewer] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [isAuthorizer, setIsAuthorizer] = useState(false);
  const [isOnlyUser, setIsOnlyUser] = useState(false);
  const [isSameApprover, setIsSameApprover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusHistory, setStatusHistoy] = useState([]);
  const [allStatus, setAllStatus] = useState(commonStatus);
  const { t, i18n } = useTranslation();

  //confirm dialog box
  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedStatus(selectedData?.status);
  };

  const handleConfirmDialog = (
    confirm,
    approverComments,
    rejectionReason,
    transactionID,
    adminComments,
    uploadedFiles,
    amountPaid,
    approvedRejectedStatus
  ) => {
    if (confirm) {
      takeActionOnMiscellaneousRequest(
        selectedData,
        selectedStatus,
        rejectionReason,
        approverComments,
        transactionID,
        adminComments,
        uploadedFiles,
        amountPaid,
        approvedRejectedStatus
      );
    } else {
      setSelectedStatus(selectedData?.status);
      setDialogOpen(false);
    }
  };

  const handleStatusChange = (event) => {
    let selectedValue = event.target.value;
    handleOpenDialog();
    setSelectedStatus(selectedValue);

    if (
      selectedData &&
      loggedInData?._id == selectedData?.raisedBy?.[0]?._id &&
      loggedInData?.roles.some((item) => item === "Approver")
    ) {
      setIsSameApprover(true);
    } else {
      setIsSameApprover(false);
    }
  };

  async function getAllAdvancePaymentStatus() {
    try {
      const response = await getAdvancePaymentStatus();
      if (response.status === "200") {
        setAllStatus(response?.data);
      }
    } catch (error) {
      toast.error("Unable to get advance payment status. Please refresh!");
      setAllStatus([]);
    }
  }

  useEffect(() => {
    if (
      loggedInData &&
      loggedInData?.roles.some((item) => "user" === item) &&
      loggedInData?.roles.some((item) => "Viewer" === item)
    ) {
      setIsViewer(true);
    } else if (
      loggedInData &&
      loggedInData?.roles.some((item) => "user" === item) &&
      loggedInData?.roles.length === 1
    ) {
      setIsOnlyUser(true);
    } else if (loggedInData?.roles.some((item) => "Approver" === item)) {
      setIsApprover(true);
    } else if (loggedInData?.roles.some((item) => "Authorizer" === item)) {
      setIsAuthorizer(true);
    }
    getStatusHistory();

    if (selectedData?.isAdvance) {
      getAllAdvancePaymentStatus();
    }
  }, []);

  useEffect(() => {
    if (selectedData == undefined) {
      history.push("/app/payments");
    }
  }, [miscellaneousReqData]);

  const updateSupplierBuyerState = (data) => {
    dispatch({
      type: "SET_SELECTED_MISCELLANEOUS_REQUEST",
      payload: data,
    });
  };

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while downloading the file.");
      });
  };

  async function getStatusHistory() {
    let updatedFormData = {
      paymentStatusId: selectedData?._id,
    };

    try {
      const response = await getPaymentHistoryStatus(updatedFormData);
      if (response.status === "200") {
        setStatusHistoy(response?.data[0]);
      }
    } catch (error) {
      toast.error("An error occurred in status history fetch. Please refresh!");
      setStatusHistoy([]);
    }
  }

  async function takeActionOnMiscellaneousRequest(
    formData,
    newStatus,
    rejectionReason,
    approverComments,
    transactionID,
    adminComments,
    uploadedFiles,
    amountPaid,
    approvedRejectedStatus
  ) {
    setIsLoading(true);
    try {
      let updatedFormData = {
        ...formData,
        status: newStatus,
        raisedBy: formData?.raisedBy[0]?._id,
        otherOption: formData?.otherOption[0]?._id,
        attachments: uploadedFiles,
      };

      switch (newStatus) {
        case ADVANCE_PAYMENT_STATUS.REJECTED:
          if (rejectionReason) {
            updatedFormData.reasonForRejection = rejectionReason;
          }
          break;
        case ADVANCE_PAYMENT_STATUS.APPROVED:
          updatedFormData.approverComments = approverComments;
          break;
        case "Completed":
          updatedFormData.transactionID = transactionID;
          updatedFormData.adminComments = adminComments;
        case ADVANCE_PAYMENT_STATUS.ADVANCE_DISBURSED:
          updatedFormData.transactionID = transactionID;
          updatedFormData.adminComments = adminComments;
          break;
        case ADVANCE_PAYMENT_STATUS.SUBMIT_EXPENSE_REPORT:
          updatedFormData.status =
            ADVANCE_PAYMENT_STATUS.EXPENSE_REPORT_SUBMITTED;
          updatedFormData.expenseReport = {
            totalExpense: amountPaid,
            comments: approverComments,
          };
          break;
        case ADVANCE_PAYMENT_STATUS.VERIFY_EXPENSE_REPORT:
          updatedFormData.status = approvedRejectedStatus;
          updatedFormData.approverComments = approverComments;
          break;
        case ADVANCE_PAYMENT_STATUS.SETTLED:
          updatedFormData.approverComments = approverComments;
          break;
      }

      const response = await actionOnMiscellaneousRequest(updatedFormData);

      if (response.status === "200") {
        toast.success("Successfully updated!");
        getStatusHistory();
        setIsLoading(false);
        setSelectedStatus(selectedStatus);
        updateSupplierBuyerState(response?.data?.response);

        setDialogOpen(false);
      } else if (response.status === "400") {
        toast.error(response?.message || "Something went wrong!");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      setIsLoading(false);
    }
  }

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("Miscellaneous Request"), link: "/#/app/payments" },
    { label: t("Edit"), link: "" },
  ];

  return (
    <Box>
      <Toaster />
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <HeaderComp
            selectedStatus={selectedStatus}
            selectedData={selectedData}
            backUrlPayload="miscellaneousReqComp"
          />
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <Paper sx={{ height: "100%" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Request type")}</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.otherOption[0]?.option}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Date")}</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {moment(selectedData?.date).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Raised by")}</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.raisedBy[0]?.username ||
                        selectedData?.raisedBy[0]?.userName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Payment from")}</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.paymentFrom}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <RenderAttachments
                attachments={selectedData?.attachments}
                handleDownload={handleDownload}
              />
              <Box sx={{ marginLeft: "16px", padding: "10px 0" }}>
                {selectedData?.remarks && (
                  <Box mb={2}>
                    <Typography fontWeight="600" fontSize="14px">
                      <b>Remarks:</b>
                    </Typography>
                    {selectedData?.remarks}
                  </Box>
                )}
                {selectedData?.reasonForRejection && (
                  <>
                    <Typography
                      sx={{ color: "#FF5C93" }}
                      fontWeight="600"
                      fontSize="14px"
                    >
                      {t("Rejected reason")}
                    </Typography>
                    {selectedData?.reasonForRejection}
                  </>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={7}>
            <Paper sx={{ height: "100%" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>{t("Total amount")}</b>
                    </TableCell>
                    <TableCell>
                      <b> {formatIndianCurrency(selectedData?.amount)} </b>
                    </TableCell>
                  </TableRow>

                  {selectedData?.isAdvance && (
                    <TableRow>
                      <TableCell>
                        <b>{t("Total Expense")}</b>
                      </TableCell>
                      <TableCell>
                        <span style={{ fontWeight: "bold" }}>
                          {selectedData?.expenseReport?.totalExpense
                            ? formatIndianCurrency(
                                selectedData?.expenseReport?.totalExpense
                              )
                            : "N/A"}
                        </span>
                      </TableCell>
                    </TableRow>
                  )}

                  {selectedData?.isAdvance &&
                    (selectedStatus ===
                      ADVANCE_PAYMENT_STATUS.AMOUNT_PAYABLE_TO_USER ||
                      selectedStatus === ADVANCE_PAYMENT_STATUS.SETTLED) &&
                    selectedData?.amountPayable != null &&
                    selectedData?.amountPayable !== 0 && (
                      <TableRow>
                        <TableCell>
                          <b>{t("Payable to User")}</b>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bold" }}>
                            {formatIndianCurrency(selectedData.amountPayable)}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                  {selectedData?.isAdvance &&
                    (selectedStatus ===
                      ADVANCE_PAYMENT_STATUS.AMOUNT_RECEIVABLE_BY_COMPANY ||
                      selectedStatus === ADVANCE_PAYMENT_STATUS.SETTLED) &&
                    selectedData?.amountReceivable != null &&
                    selectedData?.amountReceivable !== 0 && (
                      <TableRow>
                        <TableCell>
                          <b>{t("Receivable by Company")}</b>
                        </TableCell>
                        <TableCell>
                          <span style={{ fontWeight: "bold" }}>
                            {formatIndianCurrency(
                              selectedData.amountReceivable
                            )}
                          </span>
                        </TableCell>
                      </TableRow>
                    )}

                  <TableRow>
                    <TableCell colSpan={2} sx={{ border: "none" }}>
                      <Typography variant="subtitle2" gutterBottom>
                        <b>{t("Account details")}</b>
                      </Typography>
                      <Typography variant="body2">
                        Name:{" "}
                        {selectedData?.paymentDetails?.accountHolderName ||
                          "N/A"}
                        <br />
                        Bank: {selectedData?.paymentDetails?.bankName || "N/A"}
                        <br />
                        Account no:{" "}
                        {selectedData?.paymentDetails?.accountNumber || "N/A"}
                        <br />
                        IFSC: {selectedData?.paymentDetails?.ifscCode || "N/A"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ border: "none" }}>
                      <Box>
                        <Typography variant="subtitle2" gutterBottom>
                          <b>{t("Status")}</b>
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            disabled={
                              selectedStatus === "Completed" ||
                              selectedStatus === "Settled"
                            }
                          >
                            {allStatus.map((option) => (
                              <MenuItem
                                key={option}
                                value={option}
                                disabled={
                                  isOnlyUser
                                    ? !["Submit Expenses"].includes(option)
                                    : option ===
                                        ADVANCE_PAYMENT_STATUS.PENDING ||
                                      (option === "Completed" &&
                                        (isViewer || isApprover)) ||
                                      option ===
                                        ADVANCE_PAYMENT_STATUS.EXPENSE_REPORT_SUBMITTED ||
                                      option ===
                                        ADVANCE_PAYMENT_STATUS.AMOUNT_PAYABLE_TO_USER ||
                                      option ===
                                        ADVANCE_PAYMENT_STATUS.AMOUNT_RECEIVABLE_BY_COMPANY ||
                                      option ===
                                        ADVANCE_PAYMENT_STATUS.REUPLOAD_EXPENSE_REPORT
                                }
                              >
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>

        <div className={classes.statusDiv}>
          <StatusHistory statusData={statusHistory} editable={false} />
        </div>
      </div>
      <ConfirmationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        inputStatus={selectedStatus}
        title="Changing status to "
        textFieldLabel="Reason"
        isLoading={isLoading}
        imageFileName="miscellaneousReqDocs"
      />
    </Box>
  );
};

export default EditMiscellaneousReq;
