import { Box } from "@mui/material";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const paymentTableHeader = [
  {
    label: "Id",
    options: {
      filter: true,
    },
  },
  {
    label: "Date",
    options: {
      filter: true,
    },
  },
  {
    label: "Party name",
    options: {
      filter: true,
    },
  },
  {
    label: "Amount",
    options: {
      filter: false,
    },
  },
  {
    label: "Payment from",
    options: {
      filter: false,
    },
  },
  {
    label: "Raised by",
    options: {
      filter: false,
      display: false,
    },
  },
  {
    label: "Status",
    options: {
      filter: false,
    },
  },
];

export const miscellaneousTableHeader = [
  {
    label: "Id",
    options: {
      filter: false,
      display: false,
    },
  },
  {
    label: "Date",
    options: {
      filter: true,
    },
  },
  {
    label: "Request type",
    options: {
      filter: true,
    },
  },
  {
    label: "Payment from",
    options: {
      filter: true,
    },
  },
  {
    label: "Amount",
    options: {
      filter: false,
    },
  },
  {
    label: "Raised by",
    options: {
      filter: true,
    },
  },
  {
    label: "Status",
    options: {
      filter: false,
    },
  },
];

export const reimburseTableHeader = [
  {
    label: "Id",
    options: {
      filter: true,
      display: false,
    },
  },
  {
    label: "Date",
    options: {
      filter: true,
    },
  },
  {
    label: "Amount",
    options: {
      filter: false,
    },
  },
  {
    label: "Payment from",
    options: {
      filter: true,
    },
  },
  {
    label: "Raised by",
    options: {
      filter: true,
    },
  },
  {
    label: "Status",
    options: {
      filter: false,
    },
  },
];

export const leaveTableHeader = [
  {
    label: "Id",
    options: {
      filter: true,
    },
  },
  {
    label: "Date",
    options: {
      filter: true,
    },
  },
  {
    label: "Leave type",
    options: {
      filter: true,
    },
  },

  {
    label: "No of days",
    options: {
      filter: false,
    },
  },
  {
    label: "Raised by",
    options: {
      filter: false,
    },
  },
  {
    label: "Status",
    options: {
      filter: false,
    },
  },
];

export const getStatusStyle = (status) => {
  switch (status) {
    case "Pending":
      return { color: "#FFA500" }; // Orange
    case "Approved":
      return { color: "#2FA201" }; // Green
    case "Completed":
      return { color: "#0D6937" }; // Dark Green
    case "Rejected":
      return { color: "#FF5C93" }; // Pink
    case "Advance Disbursed":
      return { color: "#4B0082" }; // Indigo
    case "Submit Expense Report":
      return { color: "#1E90FF" }; // Dodger Blue
    case "Expense Report Submitted":
      return { color: "#00CED1" }; // Dark Turquoise
    case "Reupload Expense Report":
      return { color: "#FF4500" }; // Orange Red
    case "Verify Expense Report":
      return { color: "#9932CC" }; // Dark Orchid
    case "Amount Payable to User":
      return { color: "#008080" }; // Teal
    case "Amount Receivable by Company":
      return { color: "#8B4513" }; // Saddle Brown
    case "Settled":
      return { color: "#20B2AA" }; // Light Sea Green
    case "Partial Payment":
      return { color: "#6ba0d1" }; // Light Blue
    case "Payment Completed":
      return { color: "#228B22" }; // Forest Green
    case "Sold":
      return { color: "#4169E1" }; // Royal Blue
    case "Closed":
      return { color: "#708090" }; // Slate Gray
    case "DD Submitted":
      return { color: "#1E90FF" }; // Dodger Blue (same as "Submit Expense Report")
    case "DD Returned":
      return { color: "#FF4500" }; // Orange Red (similar to "Reupload Expense Report")
    case "DD Cancelled":
      return { color: "#9932CC" }; // Pink (same as "Rejected")
    case "DD Adjusted":
      return { color: "#0D6937" }; // Light Blue (same as "Partial Payment")

    default:
      return {}; // Default case remains unchanged
  }
};
