import React,{useState} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress
} from "@mui/material";
const SelectUserDialog = ({
  open,
  handleClose,
  users,
  handleUserSelect,
  handleSelectedUserConfirm,
  showLoader,
  title,
  currentAssignedUser
}) => {
  const [selectedUser, setSelectedUser] = useState(null);

  const filteredUsers = currentAssignedUser
    ? users?.filter((user) => user?.userName !== currentAssignedUser)
    : users;

  const handleUserSelection = (event, value) => {
    setSelectedUser(value);
    handleUserSelect(value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ style: { width: "600px", maxHeight: "80vh" } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent >
        <Autocomplete
          id="user-select"
          sx={{marginTop:'5px'}}
          options={filteredUsers}
          getOptionLabel={(user) => user.userName}
          renderInput={(params) => (
            <TextField {...params} label="Select User" variant="outlined" />
          )}
          onChange={handleUserSelection}
        />
      </DialogContent>
      <DialogActions style={{marginRight:"10px"}}>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {/* <CircularProgress size={30}  style={{marginRight:"9px"}}/>  */}
        {showLoader? <CircularProgress size={30} style={{marginRight:"9px"}} /> : 
        <Button onClick={handleSelectedUserConfirm} color="primary" disabled={!selectedUser}> Confirm</Button>
        }
        
      </DialogActions>
    </Dialog>
  );
};

export default SelectUserDialog;
