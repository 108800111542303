import React from "react";
import {
  Dialog,
  DialogTitle,
  Checkbox,
  InputLabel,
  ListItemText,
  FormControl,
  Select,
  MenuItem,
  TextareaAutosize,
  DialogContent,
  Button,
  Grid,
  Typography,
  DialogActions,
  CircularProgress
} from "@mui/material";

const SelectMode = ({
  openDialog,
  handleDialogClose,
  title,
  options,
  selectedModes,
  onModeChange,
  showTextarea,
  textareaValue,
  onTextareaChange,
  onConfirm,
  showLoader
}) => {
  const isConfirmButtonDisabled = !selectedModes.length || !textareaValue;
  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      PaperProps={{
        style: { width: "100%", maxWidth: "600px" },
      }}
    >
      <DialogTitle>
        <Typography
          style={{ fontSize: "20px", fontWeight: "500", marginBottom: "-16px" }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl
              // fullWidth
              variant="outlined"
              margin={"normal"}
              style={{ width: "100%"}}
            >
              <InputLabel id="demo-multiple-checkbox-label">
                Select Modes
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                  style: {
                    maxHeight: 400,
                  },
                }}
                style={{ width: "100%" }}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                multiple
                variant="outlined"
                value={selectedModes}
                onChange={onModeChange}
                label={"Select Modes"}
                renderValue={(selected) => selected.join(", ")}
              >
                 {options.map((option) => (
                  <MenuItem key={option._id} value={option.mode}>
                    <Checkbox
                      checked={selectedModes.indexOf(option.mode) > -1}
                    />
                    <ListItemText primary={option.mode} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {showTextarea && (
              <TextareaAutosize
                aria-label="Notes"
                placeholder="Notes"
                style={{
                  width: "100%",
                  padding: "12px",
                  fontSize: "16px",
                  borderRadius: "4px",
                }}
                minRows={8}
                value={textareaValue}
                onChange={onTextareaChange}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ marginRight: "10px" , marginTop:"-12px" }}>
      {showLoader? <CircularProgress style={{marginRight:"5px"}} size={30}/> : 
        <Button
          disabled={isConfirmButtonDisabled}
          onClick={onConfirm}
          color="primary"
      
        >
          Confirm
        </Button>}
      </DialogActions>
    </Dialog>
  );
};

export default SelectMode;
