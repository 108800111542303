import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Box,
  Paper,
  Typography,
  Stack,
  Chip,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Table from "../../../components/Table/Table";
import { getDashboardData } from "../employeePortalState";
import toast from "react-hot-toast";

export default function EmployeesLeavesSummaryTable() {
  const { t } = useTranslation();
  const [fetchLoader, setFetchLoader] = useState(false);
  const [allLeavesData, setAllLeavesData] = useState([]);
  const [year] = useState("2024");

  async function getTheDashboardData() {
    setFetchLoader(true);
    try {
      const data = { year };
      const response = await getDashboardData(data);

      if (response.status !== "200") {
        toast.error("An error occurred. Please try again.");
        return;
      }

      const { leave_request } = response.data;
      setAllLeavesData(leave_request.all_leaves);
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    } finally {
      setFetchLoader(false);
    }
  }

  useEffect(() => {
    getTheDashboardData();
  }, []);

  const datatableHeader = [
    {
      label: t("Employee"),
      options: {
        filter: true,
        customBodyRender: (value) => (
          <Typography variant="body2" fontWeight={500}>
            {value}
          </Typography>
        ),
      },
    },
    {
      label: t("Casual Leave"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          const [taken, available] = value.split(" / ");
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Chip
                label={`${taken} taken`}
                size="small"
                color={parseInt(taken) > 0 ? "primary" : "default"}
                sx={{ minWidth: 80 }}
              />
              <Typography variant="body2" color="text.secondary">
                {available} left
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      label: t("Emergency Leave"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          const [taken, available] = value.split(" / ");
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Chip
                label={`${taken} taken`}
                size="small"
                color={parseInt(taken) > 0 ? "warning" : "default"}
                sx={{ minWidth: 80 }}
              />
              <Typography variant="body2" color="text.secondary">
                {available} left
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      label: t("Marriage Leave"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          const [taken, available] = value.split(" / ");
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <Chip
                label={`${taken} taken`}
                size="small"
                color={parseInt(taken) > 0 ? "success" : "default"}
                sx={{ minWidth: 80 }}
              />
              <Typography variant="body2" color="text.secondary">
                {available} left
              </Typography>
            </Stack>
          );
        },
      },
    },
    {
      label: t("Extra Leaves"),
      options: {
        filter: true,
        customBodyRender: (value) => {
          if (value === "None") {
            return (
              <Typography variant="body2" color="text.secondary">
                No extra leaves
              </Typography>
            );
          }

          const leaves = value.split(", ");
          return (
            <Stack direction="column" spacing={1}>
              {leaves.map((leave, index) => (
                <Chip
                  key={index}
                  label={leave}
                  size="small"
                  color="error"
                  sx={{ maxWidth: 200 }}
                />
              ))}
            </Stack>
          );
        },
      },
    },
  ];

  const monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const tableData =
    allLeavesData?.map((item) => {
      const extraLeaveData = item.extra_leave
        .map(
          (extra) =>
            `${extra.total_leave} days in ${monthArray[extra.month - 1]}`
        )
        .join(", ");

      return [
        item?.userName,
        `${item?.casual_leaves} / ${item?.casual_leave_left}`,
        `${item?.emergency_leaves} / ${item?.emergency_leave_left}`,
        `${item?.marriage_leave} / ${item?.marriage_leave_left}`,
        item.extra_leave.length > 0 ? extraLeaveData : "None",
      ];
    }) || [];

  return (
    <Box sx={{ minHeight: "100%" }}>
      {/* Header */}
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 3,
          background: "linear-gradient(to right, #f8fafc, #ffffff)",
          borderBottom: "1px solid",
          borderColor: "divider",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Box>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Leave Overview {year}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Employee leave allocation and usage summary
              </Typography>
            </Box>
          </Stack>

          <Tooltip title="Leave types are color-coded: Casual (Blue), Emergency (Orange), Marriage (Green), Extra (Red)">
            <IconButton size="small">
              <InfoOutlinedIcon sx={{ color: "primary.main" }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Paper>

      {/* Table */}
      <Paper elevation={1} sx={{ p: 2 }}>
        <Table
          data={tableData}
          header={datatableHeader}
          options={{
            filterType: "dropdown",
            print: false,
            download: false,
            filter: true,
            selectableRows: "none",
            elevation: 0,
            customSort: (data, colIndex, order) => {
              return data.sort(
                (a, b) =>
                  (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) *
                  (order === "desc" ? 1 : -1)
              );
            },
            tableBodyHeight: "550px",
            textLabels: {
              body: {
                noMatch: fetchLoader ? (
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                    sx={{ py: 3 }}
                  >
                    <CircularProgress size={20} color="primary" />
                    <Typography>Loading data...</Typography>
                  </Stack>
                ) : (
                  <Typography color="text.secondary" sx={{ py: 3 }}>
                    No leave records found
                  </Typography>
                ),
              },
            },
          }}
        />
      </Paper>
    </Box>
  );
}
