import React, { useState } from "react";
import {
  Box,
  Paper,
  Typography,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Grid,
  useTheme,
} from "@mui/material";

import EditableTextField from "../EditableTextField";
import {
  formatDateAndTime,
  formatDateAndTimeInReadableFormat,
} from "../../helper/utils";

const InquiryOwner = ({
  inquiryOwner,
  onUpdate,
  lastUpdated,
  inquiryLeadsStatus,
  disabledStatus,
  statusLoading,
  handleStatusChange,
  currentStatus,
}) => {
  const theme = useTheme();
  const [owner, setOwner] = useState(inquiryOwner);
  const handleUpdate = (field, value) => {
    const updatedOwner = { ...owner, [field]: value };
    setOwner(updatedOwner);
    onUpdate(updatedOwner);
  };

  return (
    <Paper elevation={1}>
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                p: 0,
                lg: {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              }}
            >
              <Typography variant="h6" fontWeight={"bold"}>
                {" "}
                Owner Details
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Last Updated: {formatDateAndTime(lastUpdated)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <EditableTextField
              initialValue={owner?.userName || "Not Assigned"}
              label="Assigned to"
              onSave={(value) => handleUpdate("userName", value)}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <EditableTextField
              initialValue={owner?.email || "--"}
              label="Email"
              onSave={(value) => handleUpdate("email", value)}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <EditableTextField
              initialValue={owner?.mobile?.number || "--"}
              label="Phone"
              onSave={(value) => handleUpdate("mobile", { number: value })}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <FormControl
                fullWidth
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  component="label"
                  sx={{
                    minWidth: "100px",
                    // fontWeight: "bold",
                    textAlign: "right",
                    color: theme.palette.text.secondary,
                  }}
                >
                  Status:
                </Typography>
                <Select
                  value={currentStatus || ""}
                  onChange={handleStatusChange}
                  disabled={disabledStatus || statusLoading}
                  variant="outlined"
                  sx={{
                    ml: 5,
                  }}
                >
                  {statusLoading ? (
                    <MenuItem disabled>
                      <CircularProgress size={20} />
                      <span style={{ marginLeft: 10 }}>Loading...</span>
                    </MenuItem>
                  ) : (
                    inquiryLeadsStatus.map((option) => (
                      <MenuItem
                        key={option}
                        value={option}
                        disabled={
                          option === "Assigned" || option === "Unassigned"
                        }
                      >
                        {option}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default InquiryOwner;
