import { Box, Typography, Chip, Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from "react";
import YourTimeTable from "./components/YourTimeTable";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";

const Event = () => {
  const [openEventDialog, setOpenEventDialog] = useState(false);

  const breadcrumb = [
    { label: "Home", link: "/#/app/dashboard" },
    { label: "Events", link: "" },
  ];

  const handleAddEvent = () => {
    setOpenEventDialog(true);
  };

  return (
    <Box mt={10} bgcolor={"white"} p={3}>
      <BreadCrumbs data={breadcrumb} />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5">Events</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddEvent}
        >
          Add Event
        </Button>
      </Box>

      <YourTimeTable openEventDialog={openEventDialog} setOpenEventDialog={setOpenEventDialog} />
    </Box>
  );
};

export default Event;