import moment from "moment";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import { tooltipClasses } from "@mui/material/Tooltip";

export const formatDateAndTime = (dateTime) => {
  return moment(dateTime).format("DD/MM/YYYY [at] HH:mm");
};

export function formatDateAndTimeInReadableFormat(dateString) {
  const date = new Date(dateString);


  // Array of month abbreviations
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, hours, and minutes
  const day = date.getDate();
  const month = months[date.getMonth()];
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Determine AM or PM
  const ampm = hours >= 12 ? "pm" : "am";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Construct the formatted string
  return `${day} ${month} at ${hours}:${minutes}${ampm}`;
}

export const getStatusColor = (status) => {
  const colorMap = {
    Unassigned: "#FFA500", // Orange
    Assigned: "#1E90FF", // DodgerBlue
    Addressed: "#32CD32", // LimeGreen
    "Followed Up": "#9370DB", // MediumPurple
    "Field Visit Planned": "#FF69B4", // HotPink
    "Field Visit Completed": "#20B2AA", // LightSeaGreen
    "Quotation Sent": "#FFD700", // Gold
    Accepted: "#228B22", // ForestGreen
    Rejected: "#DC143C", // Crimson
  };

  return colorMap[status] || "#000000"; // Default to black if status not found
};

export const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});
