import { CloudDownload } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import useStyles from "../../styles";
import { getFile } from "../../InquiryState";
import toast from "react-hot-toast";
const Attachments = ({ inquiryLeadData }) => {
  const classes = useStyles();

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while downloading the file.");
      });
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" fontWeight={"bold"} gutterBottom>
          Attachments
        </Typography>
        <List className={classes.attachmentList}>
          {inquiryLeadData[0]?.attachmentDetails?.length > 0 ? (
            inquiryLeadData[0].attachmentDetails.map((item) => (
              <ListItem key={item.fileName} className={classes.attachmentItem}>
                <ListItemText primary={item.fileName} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleDownload(item)}>
                    <CloudDownload />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No attachments found" />
            </ListItem>
          )}
        </List>
      </Grid>
    </Paper>
  );
};

export default Attachments;
