import React, { useCallback, useState } from "react";
import {
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableHead,
  TextField,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import classnames from "classnames";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import TimeRangeSlider from "../../../components/TimeRangeSlider/TimeRangeSlider";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";

const InspectionSlots = ({ setForm, formData }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [indexVal, setIndexVal] = useState(formData.slots);

  const [selectedPickUpDate, setSelectedPickupDate] = useState(new Date());

  const [selectedTimeRange, setSelectedTimeRange] = useState([9, 18]);

  const handleTimeRangeChange = (newRange) => {
    setSelectedTimeRange(newRange);
  };
  function formatTime(value) {
    return `${String(value).padStart(2, "0")}:00`;
  }
  const startTime = formatTime(selectedTimeRange[0]);
  const endTime = formatTime(selectedTimeRange[1]);

  const handlepickupDate = (date) => {
    setSelectedPickupDate(date);
  };
  const handleAdd = () => {
    var data = {
      inspectionDate: moment(selectedPickUpDate).format("YYYY-MM-DD"),
      time: startTime + "-" + endTime,
    };
    setIndexVal((indexVal) => [...indexVal, data]);
    formData.slots.push(data);
  };
  const GenerateRows = () => {
    return indexVal.map((item, key) => {
      return (
        <TableRow key={key}>
          <TableCell>
            {moment(item.inspectionDate).format("DD-MM-YYYY")}
          </TableCell>
          <TableCell>{item.time}</TableCell>
          <TableCell>
            <IconButton onClick={() => handleDelete(key, indexVal)}>
              <DeleteOutlineIcon className={classes.deleteicon} />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };
  const handleDelete = useCallback((key, val) => {
    var data = [];
    formData.slots = [];
    val.map((item, index) => {
      if (key !== index) {
        data.push(item);
      }
    });
    formData.slots = data;
    setIndexVal(data);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item lg={3} md={1} xs={0} sm={0}></Grid>
      <Grid item lg={6} md={10} xs={12} sm={12}>
        <Paper
          className={classes.paper}
          classes={{
            root: classnames(classes.widgetRoot, {
              [classes.noWidgetShadow]: null,
            }),
          }}
        >
          <div className={classes.form}>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <h3 className={classes.title}>
                  {t("common_inspection_slots")}
                </h3>
              </Grid>
              <Grid item lg={12} md={12} xs={12} sm={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{ width: "100%" }}
                    margin="normal"
                    label={t("common_date")}
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    value={selectedPickUpDate}
                    disablePast
                    onChange={handlepickupDate}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    renderInput={
                      <TextField
                        variant="standard"
                        label={t("common_date")}
                      ></TextField>
                    }
                  />
                </LocalizationProvider>
              </Grid>
              {/* <Grid item md={0} xs={0} sm={0}></Grid> */}
              <Grid item lg={12} md={12} xs={9} sm={9}>
                <div className={classes.slider}>
                  <Typography id="range-slider" className={classes.spacing}>
                    {t("mpr_time_range")} - {startTime + " - " + endTime}
                  </Typography>
                  <TimeRangeSlider
                    selectedRange={selectedTimeRange}
                    onRangeChange={handleTimeRangeChange}
                  />
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={handleAdd}
                  >
                    Add <AddIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
            {/* </Grid>
            </Grid> */}
            <Grid container style={{ marginTop: "1rem" }}>
              <Grid lg={12} md={12} xs={12} sm={12} item>
                {indexVal.length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("common_date")}</TableCell>
                        <TableCell>{t("common_time")}</TableCell>
                        <TableCell>{t("common_action")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <GenerateRows />
                    </TableBody>
                  </Table>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid lg={3} md={1} xs={0} sm={0} item></Grid>
    </Grid>
  );
};

export default InspectionSlots;
