import React from "react";
import { Box } from "@mui/material";
import { CircularProgress } from "@mui/material";

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const tableOptions = (
  fetchLoader,
  rowsPerPageOptions,
  setPageData,
  handleRowClick,
  totalCount,
  currentPage
) => ({
  fixedHeader: true,
  print: false,
  download: false,
  // filter: true,
  selectableRows: "none",
  tableBodyHeight: "600px",
  filterType: "dropdown",
  sortable: true,
  rowsPerPageOptions: rowsPerPageOptions,
  page: currentPage - 1, // MUI DataTable uses 0-based index for pages
  count: totalCount,
  // serverSide: true, // search won't work if we use server side true
  onChangePage: (currentPage) => {
    setPageData((prevState) => ({ ...prevState, page: currentPage + 1 }));
  },
  onChangeRowsPerPage: (numberOfRows) => {
    setPageData((prevState) => ({
      ...prevState,
      limit: numberOfRows,
      page: 1,
    }));
  },
  onRowClick: handleRowClick,
  textLabels: {
    body: {
      noMatch: fetchLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "#0D6937", marginRight: "5px" }} />{" "}
          Loading...
        </div>
      ) : (
        "No Inquiries Found"
      ),
    },
  },
  pagination: true,
});

export const inquiryTableHeader = [
  {
    label: "Id",
    options: {
      filter: false,
      display: false,
    },
  },
  {
    label: "Date",
    options: {
      filter: true,
    },
  },
  {
    label: "Name",
    options: {
      filter: true,
    },
  },
  {
    label: "Company",
    options: {
      filter: true,
    },
  },
  {
    label: "Contact",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    label: "Inquiry type",
    options: {
      filter: false,
    },
  },

  {
    label: "Status",
    options: {
      filter: true,
      display: false,
    },
  },
  {
    label: "Assigned to",
    options: {
      filter: false,
      display: false,
    },
  },
  {
    label: "Source",
    options: {
      filter: false,
      sort: false,
    },
  },
];

export const tabdata1 = [
  {
    label: "All Inquiries",
    index: 0,
    name: "",
  },
  { label: "Unassigned", index: 1, name: " " },
];
export const tabdata2 = [
  { label: "Assigned", index: 2, name: " " },
  { label: "Addressed", index: 3, name: " " },
  // {
  //   label: "Awaiting Response",
  //   index: 4,
  //   name: "",
  // },
  { label: "Followed Up", index: 5, name: " " },
  // {
  //   label: "Followup Requested",
  //   index: 11,
  //   name: "",
  // },
  {
    label: "Field Visit Planned",
    index: 6,
    name: "",
  },
  {
    label: "Field Visit Completed",
    index: 7,
    name: "",
  },
  { label: "Quotation Sent", index: 8, name: "" },
  { label: "Accepted", index: 9, name: "" },
  { label: "Rejected", index: 10, name: "" },
];
export const tabdata3 = [
  {
    label: "All Inquiries",
    index: 0,
    name: "",
  },
];

export const statusHistoryHeader = () => {
  return [
    {
      label: "Updated At",
      options: {
        filter: true,
      },
    },
    {
      label: "By",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "From",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "To",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "Notes",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      label: "Additional Info",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      label: "Edit",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
};

export const duplicateInquiryHeader = () => {
  return [
    {
      label: "Updated At",
      options: {
        filter: true,
      },
    },
    {
      label: "Updated By",
      options: {
        filter: true,
      },
    },
    {
      label: "Status From",
      options: {
        filter: true,
      },
    },
    {
      label: "Status To",
      options: {
        filter: true,
      },
    },
    {
      label: "Notes",
      options: {
        filter: false,
      },
    },
    {
      label: "Additional Info",
      options: {
        filter: false,
      },
    },
  ];
};
