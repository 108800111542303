import React, { useEffect, useState } from "react";
import CalendarLayout from "../../../components/Calander/CalanderLayout";
import EventDialog from "./EventDialog";
import SnackBar from "./SnackBar";

const YourTimeTable = ({ openEventDialog, setOpenEventDialog }) => {
  const [myEvents, setMyEvents] = useState([
    {
      id: 1,
      title: "Material pickup of TCS",
      start: new Date(2024, 9, 10, 10, 0),
      end: new Date(2024, 9, 10, 12, 0),
      color: "#3174ad",
      description:
        "Pickup for TCS ewaste. Review and process the request for necessary materials and equipment.",
    },
    {
      id: 2,
      title: "Inspection Mphasis",
      start: new Date(2024, 9, 12, 14, 0),
      end: new Date(2024, 9, 12, 16, 0),
      color: "#32a852",
      description:
        "On-site inspection at Mphasis office to evaluate compliance with project requirements and safety standards.",
    },
    {
      id: 3,
      title: "Tender Submission Deadline",
      start: new Date(2024, 9, 15, 9, 0),
      end: new Date(2024, 9, 15, 18, 0),
      color: "#32a852",
      description:
        "Final day to submit tender documents for the new government infrastructure project. Ensure all paperwork is complete and submitted by 6 PM.",
    },
    {
      id: 4,
      title: "Client Meeting",
      start: new Date(2024, 9, 18, 11, 0),
      end: new Date(2024, 9, 18, 12, 30),
      color: "#32a852",
      description:
        "Meeting with potential new client to discuss their requirements and present our company's capabilities and past projects.",
    },
    {
      id: 5,
      title: "Team Training Session",
      start: new Date(2024, 9, 20, 13, 0),
      end: new Date(2024, 9, 20, 17, 0),
      color: "#32a852",
      description:
        "Mandatory training session for all team members on new project management software and updated safety protocols.",
    },
    {
      id: 7,
      title: "Quarterly Review",
      start: new Date(2024, 9, 28, 9, 0),
      end: new Date(2024, 9, 28, 11, 0),
      color: "#32a852",
      description:
        "Quarterly performance review meeting with senior management. Prepare presentations on project progress, financial performance, and upcoming goals.",
    },
  ]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const [deletedEvent, setDeletedEvent] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSaveEvent = (eventData) => {
    if (selectedEvent) {
      setMyEvents(
        myEvents.map((event) =>
          event.id === selectedEvent.id ? { ...event, ...eventData } : event
        )
      );
    } else {
      setMyEvents([...myEvents, { id: Date.now(), ...eventData }]);
    }
    handleCloseDialog();
  };

  const handleDeleteEvent = (eventToDelete) => {
    setDeletedEvent(eventToDelete);
    setMyEvents(myEvents.filter((event) => event.id !== eventToDelete.id));
    setDialogOpen(false);
    setSnackbarOpen(true);
  };

  const handleUndoDelete = () => {
    if (deletedEvent) {
      setMyEvents([...myEvents, deletedEvent]);
      setDeletedEvent(null);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setDeletedEvent(null);
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setDialogOpen(true);
  };

  const handleSelectSlot = (slotInfo) => {
    setSelectedSlot(slotInfo);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedEvent(null);
    setSelectedSlot(null);
  };

  const handleEventDrop = ({ event, start, end }) => {
    setMyEvents(
      myEvents.map((existingEvent) =>
        existingEvent.id === event.id
          ? { ...existingEvent, start, end }
          : existingEvent
      )
    );
  };

  const handleEventResize = ({ event, start, end }) => {
    setMyEvents(
      myEvents.map((existingEvent) =>
        existingEvent.id === event.id
          ? { ...existingEvent, start, end }
          : existingEvent
      )
    );
  };

  useEffect(() => {
    if (openEventDialog) {
      setSelectedSlot({
        start: new Date(),
        end: new Date(new Date().getTime() + 60 * 60 * 1000), // Default to 1 hour event
      });
      setDialogOpen(true);
      setOpenEventDialog(false); // Reset the prop after opening the dialog
    }
  }, [openEventDialog, setOpenEventDialog]);

  return (
    <>
      <CalendarLayout
        events={myEvents}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
      />
      <EventDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveEvent}
        onDelete={handleDeleteEvent}
        event={selectedEvent}
        slotInfo={selectedSlot}
      />
      <SnackBar
        actionTitle="Event deleted"
        actionButtonTitle="UNDO"
        openSnack={snackbarOpen}
        onClose={handleCloseSnackbar}
        onUndo={handleUndoDelete}
      />
    </>
  );
};

export default YourTimeTable;
