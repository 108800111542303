import React from "react";
import { Box, Stack } from "@mui/material";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import LeaveRequestsTable from "./components/LeaveRequestsTable";
import EmployeesLeavesSummaryTable from "./components/EmployeesLeavesSummaryTable";
import { useSelector } from "react-redux";

const EmployeePortal = () => {
  const breadcrumb = [
    { label: "Home", link: "/#/app/dashboard" },
    { label: "Employee portal", link: "" },
  ];

  const data = useSelector((state) => state.userdata.getData);
   

  return (
    <Box sx={{ minHeight: "100vh", marginTop: "100px" }}>
      <Box sx={{ mb: 3 }}>
        <BreadCrumbs data={breadcrumb} />
      </Box>
      <Stack spacing={3}>
        <LeaveRequestsTable />
        { !data?.roles?.includes("user") && (
          <EmployeesLeavesSummaryTable />
        )}
      </Stack>
    </Box>
  );
};

export default EmployeePortal;
