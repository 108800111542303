import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { Button, CircularProgress, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "../styles";

import NotificationContainer from "./../../notifications/NotificationContainer";
import { AddBranch } from "../AdminState";
import { getCertificateData } from "../../setting/SettingState";
import FieldData from "./FieldData";

const AddCustomerBranch = () => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  let history = useHistory();
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: {
      email: "",
      city: "",
      state: "",
      country: "India",
    },
  });

  const [status, setStatus] = useState({
    type: "",
    message: "",
    notify: false,
  });

  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [tenantName, setTenantName] = useState("");
  const getdata = useSelector((state) => state.userdata.getData);
  // const custData = useSelector((state) => state)

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      if (getdata.tenant_id && getdata.tenant_id === "") {
        handleSubmitNew();
      } else {
        let bodyData = {
          tenID: getdata.tenant_id,
        };
        getCertificateData(bodyData, dispatch).then((response) => {
          if (response.status === "200") {
            setTenantName(response.tenantData.name);
          }
        });
        setOpen(true);
      }
    }
    // eslint-disable-next-line
  }, [formData]);

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("cust_administration"), link: "/#/app/customeradmin" },
    { label: t("common_branch"), link: "/#/app/customerBranch" },
    { label: t("common_add") + t("common_branch"), link: "" },
  ];

  const handleAddBranchResponse = (response) => {
    const newStatus = {};
    if (response.status === "200") {
      newStatus.type = "success";
      newStatus.message =
        "Successfully Added Branch! Link Sent to the given mail id";
      newStatus.notify = true;
      setShowLoader(false);
      setTimeout(() => {
        setStatus({});
        history.push("/app/customerBranch");
      }, 2000);
    } else if (response.status === "401") {
      newStatus.type = "error";
      newStatus.message = response.message;
      newStatus.notify = true;
      setShowLoader(false);
      setTimeout(() => {
        dispatch({ type: "LOG_OUT" });
        localStorage.clear();
        setStatus({});
      }, 2000);
    } else {
      newStatus.type = "error";
      newStatus.message = response.message;
      newStatus.notify = true;
      setShowLoader(false);
      setStatus({});
    }
    setStatus(newStatus);
  };

  const handleSubmitNew = (data) => {
    setShowLoader(true);
    let bodyData = {
      state: data.state,
      district: data.city,
      customerID: getdata.customerid,
      isExclucive: getdata.tenant_id === "" ? false : true,
      tenant_id: getdata.tenant_id === "" ? "" : getdata.tenant_id,
      userEmail: data.email,
      companyName: getdata.customerName,
      adminMail: getdata.email,
      adminName: getdata.userName,
    };
    AddBranch(bodyData, dispatch).then(handleAddBranchResponse);
  };

  const handleShowPopUp = (data) => {
    setFormData(data);
    handleSubmitNew(data);
  };

  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <Paper className={classes.paper}>
        <div className={classes.formField}>
          {status.notify && (
            <NotificationContainer
              message={status.message}
              notificationtype={status.type}
            />
          )}
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleShowPopUp)}>
              <Typography variant="h3">{t("common_add_branch")}</Typography>
              <FieldData />
              <div className={classes.paperButton}>
                <Button
                  variant="outlined"
                  color="primary"
                  href="/#/app/customerBranch"
                  style={{ marginRight: "10px" }}
                >
                  {t("common_back")}
                </Button>
                {showLoader ? (
                  <CircularProgress />
                ) : (
                  <Button variant="contained" color="primary" type="submit">
                    {t("common_finish")}
                  </Button>
                )}
              </div>
            </form>
          </FormProvider>
        </div>
      </Paper>
    </>
  );
};

export default AddCustomerBranch;
