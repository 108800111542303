import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function SnackBar({ actionTitle, actionButtonTitle, openSnack, onClose, onUndo }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };

  const handleUndo = () => {
    onUndo();
    onClose();
  };

  const action = (
    <React.Fragment>
      <Button
        color="primary"
        size="small"
        onClick={handleUndo}
        style={{ fontWeight: "bold" }}
      >
        {actionButtonTitle}
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={openSnack}
      autoHideDuration={6000}
      onClose={handleClose}
      message={actionTitle}
      action={action}
    />
  );
}