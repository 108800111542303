import React from "react";
import { Paper, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatDateAndTime } from "../../helper/utils";
import EditableTextField from "../EditableTextField";
import { updateInquiry } from "../../InquiryState";
import toast, { Toaster } from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  attachmentList: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  attachmentItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  editButton: {
    marginLeft: theme.spacing(1),
  },
}));

const BasicInquiryDetails = ({ inquiryLeadData }) => {
  const classes = useStyles();

  const handleSave = async (fieldName, newValue) => {
    const data = {
      [fieldName]: newValue,
      inquiryID: inquiryLeadData[0]?.inquiryID,
    };
    const response = await updateInquiry(data);
    if (response.status === "200") {
      toast.success("Inquiry updated successfully");
    } else {
      toast.error("Failed to update inquiry");
    }
  };

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Inquiry Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={formatDateAndTime(inquiryLeadData[0]?.createdAt)}
                label="Date"
                onSave={handleSave}
                disabled={true}
                fieldName={"createdAt"}
                type={"text"}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={inquiryLeadData[0]?.name}
                label="Name"
                onSave={handleSave}
                fieldName={"name"}
                type={"text"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={inquiryLeadData[0]?.companyName}
                label="Company"
                onSave={handleSave}
                fieldName={"companyName"}
                type={"text"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={inquiryLeadData[0]?.email}
                label="Email"
                onSave={handleSave}
                fieldName={"email"}
                type={"email"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={inquiryLeadData[0]?.contact}
                label="Contact No"
                onSave={handleSave}
                fieldName={"contact"}
                type={"phone"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={inquiryLeadData[0]?.inquiryType}
                label="Inquiry Type"
                onSave={handleSave}
                disabled={true}
                fieldName={"inquiryType"}
                type={"text"}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <EditableTextField
                initialValue={inquiryLeadData[0]?.shortNotes}
                label="Message"
                onSave={handleSave}
                disabled={true}
                fieldName={"shortNotes"}
                type={"text"}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} md={12}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Address Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={
                  inquiryLeadData[0]?.branchDetails?.address?.address1 || "--"
                }
                label="Address 1"
                onSave={handleSave}
                fieldName={"address1"}
                type={"text"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={
                  inquiryLeadData[0]?.branchDetails?.address?.address2 || "--"
                }
                label="Address 2"
                onSave={handleSave}
                fieldName={"address2"}
                type={"text"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={
                  inquiryLeadData[0]?.branchDetails?.district || "--"
                }
                label="District"
                onSave={handleSave}
                fieldName={"district"}
                type={"text"}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={inquiryLeadData[0]?.branchDetails?.state || "--"}
                label="State"
                onSave={handleSave}
                fieldName={"state"}
                type={"text"}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <EditableTextField
                initialValue={
                  inquiryLeadData[0]?.branchDetails?.pincode || "--"
                }
                label="Pincode"
                onSave={handleSave}
                fieldName={"pincode"}
                type={"text"}
              />
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Paper>
  );
};

export default BasicInquiryDetails;
