import React, { useState } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  Paper,
  Link,
  Box,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import useStyles from "./styles";
import { joinUrl } from "../../../data/config";
import { Userlogin, getData, forgotPassword } from "../LoginState";
import { Player } from "@lottiefiles/react-lottie-player";
import toast, { Toaster } from "react-hot-toast";

function Login(props) {
  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [title, setTitle] = useState("Sign in to Binbag CRM");
  const [subtitle, setSubtitle] = useState(
    "Don't have an account? Contact the admin of the organization to have them add you."
  );
  const [forgot, setForgot] = useState(false);

  const dispatch = useDispatch();

  const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9]+.[a-z]{2,3}$/;

  const onForgot = () => {
    setTitle("Forgot your password?");
    setSubtitle(
      "Enter the email address associated with your account and we'll email you a link to reset your password."
    );
    setForgot(true);
  };

  const onBack = () => {
    setTitle("Sign in to Binbag CRM");
    setSubtitle(
      "Don't have an account? Contact the admin of the organization to have them add you."
    );
    setForgot(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    Userlogin(loginValue, passwordValue, dispatch)
      .then((response) => {
        if (response.status === "success") {
          localStorage.setItem("token", response.token);
          getData(dispatch)
            .then((resp) => {
              if (resp.status === "200") {
                dispatch({ type: "LOGIN_SUCCESS" });
                setIsLoading(false);
                if (resp.getData.type === "tenant") {
                  props.history.push("/app/dashboard");
                } else if (
                  resp.getData.type === "Customer" &&
                  resp.getData.roles.includes("Customer_Admin")
                ) {
                  props.history.push("/app/dashboard");
                } else if (
                  resp.getData.type === "Customer" &&
                  resp.getData.roles.includes("Branch_Admin")
                ) {
                  props.history.push("/app/MPR");
                } else {
                  props.history.push("/app/dashboard");
                }
              }
            })
            .catch((error) => {
              toast.error("An error occurred. Please try again later.");
            });
        } else if (response.status == "failure") {
          setIsLoading(false);
          toast.error("Unauthorized!");
          setLoginValue("");
          setPasswordValue("");
          dispatch({ type: "LOGIN_FAILURE" });
        } else if (response.status == "wrong") {
          toast.error("Invalid Credentials!");
          setIsLoading(false);
          dispatch({ type: "LOGIN_FAILURE" });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong! Please try again!!");
        setIsLoading(false);
      });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  //network calls

  const handleForgotPassword = () => {
    setIsLoading(true);
    let data = {
      mailID: loginValue,
    };
    forgotPassword(data)
      .then((response) => {
        if (response.status === "200") {
          setLoginValue("");
          toast.success("Please check your mail for further reference.");
          setIsLoading(false);
          onBack();
        } else if (response.status === "500") {
          toast.error(response.message);
          setIsLoading(false);
          onBack();
        } else {
          toast.error("Something went wrong! Please try again.");
          setIsLoading(false);
          onBack();
        }
      })
      .catch((error) => {
        toast.error("A network error occurred. Please try again later.");
        setIsLoading(false);
      });
  };

  return (
    <div className={classes.container}>
      <Toaster />
      <Box alignSelf={"flex-start"} className={classes.logo}>
        <a href="https://my.binbag.in">
          <img
            src="assets/img/binbagLogo.png"
            alt="logo"
            style={{ width: "40%", height: "auto" }}
          />
        </a>
      </Box>

      <div className={classes.box}>
        <Paper className={classes.paperContainer1} elevation={2}>
          <Typography
            variant="h5"
            mb={1}
            fontWeight={"bold"}
            color={"primary"}
            textAlign={"start"}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            mb={2}
            fontSize={"14px"}
            color={"gray"}
          >
            {subtitle}
          </Typography>
          <form onKeyDown={handleKeyDown} onSubmit={(e) => handleSubmit(e)}>
            <TextField
              variant="standard"
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Email"
              type="email"
              fullWidth
              label="Email"
            />
            {!forgot ? (
              <TextField
                variant="standard"
                id="password"
                label="Password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ marginRight: "0.5rem" }}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                        disabled={passwordValue.length === 0}
                      >
                        {showPassword ? (
                          <Visibility color="gray" />
                        ) : (
                          <VisibilityOff color="gray" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
              />
            ) : (
              <></>
            )}

            <div className={classes.formButtons}>
              {!forgot ? (
                <Button
                  disabled={
                    loginValue.length === 0 ||
                    passwordValue.length === 0 ||
                    isLoading ||
                    !emailPattern.test(loginValue)
                  }
                  onClick={(e) => handleSubmit(e)}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  style={{ textTransform: "none" }}
                >
                  {isLoading ? <CircularProgress size={26} /> : "Sign in"}
                </Button>
              ) : (
                <Button
                  disabled={
                    loginValue.length === 0 ||
                    !emailPattern.test(loginValue) ||
                    isLoading ||
                    !emailPattern.test(loginValue)
                  }
                  onClick={handleForgotPassword}
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  style={{ textTransform: "none" }}
                >
                  {isLoading ? (
                    <CircularProgress size={26} />
                  ) : (
                    "Send reset link"
                  )}
                </Button>
              )}

              {!forgot ? (
                <Link
                  color="primary"
                  size="small"
                  onClick={onForgot}
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  underline="hover"
                >
                  <Typography variant="p" fontSize={"14px"}>
                    Forgot password?
                  </Typography>
                </Link>
              ) : (
                <Link
                  color="primary"
                  size="small"
                  onClick={onBack}
                  style={{ cursor: "pointer", marginTop: "10px" }}
                  underline="hover"
                >
                  <Typography
                    variant="p"
                    fontSize={"14px"}
                    textAlign={"center"}
                  >
                    Back to Login
                  </Typography>
                </Link>
              )}
            </div>
            {/* <div style={{ textAlign: "center", marginTop: "1.5rem" }}>
              New to Binbag?{" "}
              <Link
                color="primary"
                size="small"
                href={joinUrl}
                style={{ cursor: "pointer" }}
                underline="hover"
              >
                Join now
              </Link>
            </div> */}
          </form>
        </Paper>
        <Paper className={classes.paperContainer2} elevation={2}>
          <Player
            autoplay
            loop
            src="/assets/animations/team.json"
            style={{ height: "300px", width: "300px" }}
          ></Player>
        </Paper>
      </div>

      <Typography className={classes.copyright}>
        ©2024, Binbag Recycling Services Pvt Ltd. All Rights Reserved.
      </Typography>
    </div>
  );
}

export default withRouter(Login);
