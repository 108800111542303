import {
  Grid,
  Card,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import Table from "../../components/Table/Table";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getPossibleDuplicateInquiry,
  statusUpdate,
  getFile,
} from "./InquiryState";
import DescriptionComponent from "../../components/DescriptionComponent";
import { CloudDownload } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import HistoryIcon from "@mui/icons-material/History";
import { Cancel as CancelIcon } from "@mui/icons-material";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useHistory } from "react-router-dom";

import toast, { Toaster } from "react-hot-toast";
import { formatDateAndTime, CustomWidthTooltip } from "./helper/utils";
import { duplicateInquiryHeader } from "./helper/tableConfig";

const DuplicateInquiries = () => {
  var classes = useStyles();
  const { t, i18n } = useTranslation();
  const usersData = useSelector((state) => state.selectedDuplicateInquiry);
  const [tableData, setTableData] = useState([]);
  const [message, setMessage] = useState("");
  const [notify, setNotification] = useState(false);
  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [acceptOpen, setAcceptOpen] = useState(false);
  const [inquiryId, setInquiryId] = useState("");
  const history = useHistory();
  const [rejectInquiry, setRejectInquiry] = useState("");
  const [tooltipStates, setTooltipStates] = useState([]);
  const [statusHistory, setStatusHistory] = useState(false);
  const [historyTableData, setHistoryTableData] = useState([]);
  const [selectedDuplicateInquiryId, setSelectedDuplicateInquiryId] =
    useState("");

  const breadcrumb = [
    { label: t("Inquiries"), link: "/#/app/inquiries" },
    { label: t("Duplicate Inquiry"), link: "" },
  ];

  const columns = [
    "Inquiry Id",
    "Company",
    "Email",
    "Phone number",
    "Status",
    "Reason",
    "Notes",
    "Status history",
    "Action",
  ];

  const handleSubmit = () => {
    let bodyData;
    if (rejectInquiry.duplicateStatus.isDuplicate === true) {
      bodyData = {
        inquiryId: usersData,
        isActive: false,
      };
    } else {
      bodyData = {
        oldInquiry: {
          inquiryId: rejectInquiry._id,
          isActive: false,
        },
        newInquiry: {
          inquiryId: usersData,
          duplicateStatus: {
            isDuplicate: false,
            reason: "",
          },
          duplicateInquiries: [],
        },
      };
    }
    setShowLoader(true);
    statusUpdate(bodyData).then((response) => {
      if (response.status === "200") {
        toast.success(response.message);
        setTimeout(() => {
          setShowLoader(false);
          setNotification(false);
          history.push("/app/inquiries");
        }, 1000);
      } else {
        if (response.status === "401") {
          setShowLoader(false);
          setOpen(false);
          toast.error(response.message);
          setTimeout(() => {
            dispatch({ type: "LOG_OUT" });
            localStorage.clear();
          }, 2000);
        } else {
          toast.error(response.message);
          setShowLoader(false);
          setOpen(false);
        }
      }
    });
  };

  const handleAcceptSubmit = () => {
    let data = {
      inquiryId: inquiryId,
      duplicateStatus: {
        isDuplicate: false,
        reason: "",
      },
      duplicateInquiries: [],
    };
    setShowLoader(true);
    statusUpdate(data).then((response) => {
      if (response.status === "200") {
        toast.success(response.message);
        setAcceptOpen(false);
        setTimeout(() => {
          setShowLoader(false);
          setNotification(false);
          history.push("/app/inquiries");
        }, 1000);
      } else {
        if (response.status === "401") {
          toast.error(response.message);
          setShowLoader(false);
          setAcceptOpen(false);
        } else {
          toast.error("Something went wrong");
          setShowLoader(false);
          setAcceptOpen(false);
        }
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAcceptClose = () => {
    setAcceptOpen(false);
  };

  const handleAcceptOpen = (_id) => {
    setInquiryId(_id);
    setAcceptOpen(true);
  };

  const handleRejectOpen = (item) => {
    setOpen(true);
    setRejectInquiry(item);
  };

  useEffect(() => {
    const isDuplicateRows = [];
    const otherRows = [];
    if (usersData !== undefined) {
      const BodyData = {
        _id: usersData,
      };
      getPossibleDuplicateInquiry(BodyData).then((response) => {
        if (response && response.status === "200") {
          response.data.map((item, index) => {
            const row = [
              item.inquiryID,
              item.companyName,
              item.email ? item.email : "N/A",
              item.contact ? item.contact : "N/A",
              item.status,
              <DescriptionComponent
                description={item.reason[0] ? item.reason[0] : "N/A"}
              />,
              <DescriptionComponent
                description={item.shortNotes ? item.shortNotes : "N/A"}
              />,
              <Grid key={index}>
                <IconButton
                  disabled={
                    item.duplicateStatus.isDuplicate === true ||
                    item.status === "Unassigned"
                  }
                  onClick={() =>
                    handleShowHistoryClick(
                      item.inquiryID,
                      item.statusHistory,
                      item
                    )
                  }
                  color="success"
                >
                  <HistoryIcon />
                </IconButton>
              </Grid>,
              <Grid style={{ display: "flex", alignItems: "center" }}>
                <Grid style={{ width: "2.5rem" }}>
                  {item.duplicateStatus.isDuplicate === true ? (
                    <Tooltip title="Accept">
                      <IconButton
                        className={classes.collapseIcon}
                        onClick={() => handleAcceptOpen(item._id)}
                      >
                        <CheckCircleIcon color="success" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    " "
                  )}
                </Grid>
                <Grid>
                  {item.status !== "Accepted" && item.status !== "Rejected" ? (
                    <Tooltip title="Reject">
                      <IconButton
                        className={classes.collapseIcon}
                        onClick={() => handleRejectOpen(item)}
                      >
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>,
            ];
            if (item.duplicateStatus.isDuplicate === true) {
              isDuplicateRows.push(row);
            } else {
              otherRows.push(row);
            }
          });
          const updatedTableData = isDuplicateRows.concat(otherRows);

          setTableData(updatedTableData);
        } else if (response && response.status === "401") {
          toast.error(response.message);
        }
      });
    } else {
      history.push("/app/inquiries");
    }
  }, []);

  const toggleTooltipState = (index) => {
    const newTooltipStates = [...tooltipStates];
    newTooltipStates[index] = !newTooltipStates[index];
    setTooltipStates(newTooltipStates);
  };

  function generateTooltipContent(status, item, index) {
    let content = "N/A";
    if (status === item.statusForm) {
      content = "N/A";
    } else if (status === "Addressed") {
      content = item.modeOfAddressing?.notes;
    } else if (status === "Awaiting Response") {
      content = item.awaitingResponse?.notes;
    } else if (status === "Followed Up") {
      content = item.modeOfFollowup?.notes;
    } else if (status === "Followup Requested") {
      content = item.followupRequested?.notes;
    } else if (status === "Field Visit Planned") {
      content = item.fieldVisitPlanned?.notes;
    } else if (status === "Field Visit Completed") {
      content = item.fieldVisitCompleted.feedback;
    } else if (status === "Rejected") {
      content = item.rejected?.notes;
    }

    return (
      <Button
        style={{ marginLeft: "-22px" }}
        onClick={() => toggleTooltipState(index)}
      >
        <CustomWidthTooltip
          title={<Typography variant="body1">{content}</Typography>}
          open={tooltipStates[index]}
          onClose={() => toggleTooltipState(index)}
        >
          <DescriptionIcon
            style={{ cursor: "pointer" }}
            sx={{ m: 1 }}
            color="success"
          ></DescriptionIcon>
        </CustomWidthTooltip>
      </Button>
    );
  }

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          setType("error");
          setMessage(response.message);
          setNotification(true);
        }
      })
      .catch((error) => {
        setType("error");
        setMessage("An error occurred while downloading the file.");
        setNotification(true);
      });
  };

  function renderAdditionalInfo(status, item, value) {
    if (status === item.statusForm) {
      return <div>Owner Reassigned</div>;
    } else if (status === "Addressed") {
      return (
        <div>
          <div>
            Mode of contact: {item.modeOfAddressing.addressing.join(",")}
          </div>
        </div>
      );
    } else if (status === "Awaiting Response") {
      return (
        <div>
          <div>
            Last contact :{" "}
            {formatDateAndTime(item.awaitingResponse.dateAndTimeOfLastContact)}
          </div>
          <div>
            Mode of contact: {item.awaitingResponse.modeOfContact.join(",")}
          </div>
        </div>
      );
    } else if (status === "Followed Up") {
      return (
        <div>
          <div>Mode of contact: {item.modeOfFollowup.followup.join(",")}</div>
        </div>
      );
    } else if (status === "Followup Requested") {
      return (
        <div>
          <div>
            Requested Date:{" "}
            {formatDateAndTime(item.followupRequested.dateAndTime)}
          </div>
        </div>
      );
    } else if (status === "Field Visit Planned") {
      return (
        <div>
          <div>
            Scheduled Date:{" "}
            {formatDateAndTime(item.fieldVisitPlanned.dateAndTime)}
          </div>
        </div>
      );
    } else if (status === "Field Visit Completed") {
      return (
        <div>
          <div>
            Completed Date:{" "}
            {formatDateAndTime(item.fieldVisitCompleted.dateAndTime)}
          </div>
          <Grid container style={{ marginLeft: "-12px" }}>
            {item.fieldVisitCompleted.photos?.length > 0 ? (
              item.fieldVisitCompleted.photos.map((photo, index) => (
                <Grid item key={index}>
                  <IconButton
                    className={classes.deleteicon}
                    onClick={() => handleDownload(photo)}
                    size="large"
                  >
                    <CloudDownload />
                  </IconButton>
                </Grid>
              ))
            ) : (
              <Grid item>
                <div style={{ marginLeft: "12px" }}>No Images uploaded</div>
              </Grid>
            )}
          </Grid>
        </div>
      );
    } else if (status === "Quotation Sent") {
      return (
        <div>
          <div>Attached MPR ID: {value?.materialRequestID}</div>
        </div>
      );
    } else {
      return "N/A";
    }
  }

  const handleCloseTable = () => {
    setStatusHistory(false);
  };

  const handleShowHistoryClick = (selectedInquiryId, statusHistory, value) => {
    setSelectedDuplicateInquiryId(selectedInquiryId);
    const isHistoryRows = [];
    setStatusHistory(false);
    if (statusHistory) {
      setStatusHistory(true);
      statusHistory.map((item, index) => {
        const row = [
          item.updatedAt ? formatDateAndTime(item.updatedAt) : "N/A",
          item.updatedBy ? item.updatedBy[0]?.userName : "N/A",
          item.statusForm ? item.statusForm : "N/A",
          item.statusTo ? item.statusTo : "N/A",
          generateTooltipContent(item.statusTo, item, index),
          renderAdditionalInfo(item.statusTo, item, value),
        ];
        isHistoryRows.push(row);
      });
    } else {
      setStatusHistory(false);
    }
    setHistoryTableData(isHistoryRows);
  };

  return (
    <>
      <Toaster />
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <div className={classes.HeaderContainer}>
                  <div className={classes.tenantHeader}>
                    <Typography variant="h4" size="sm">
                      Possible Duplicate Inquiries
                    </Typography>
                  </div>

                  <div>
                    <Button
                      variant="outlined"
                      className={classes.buttonOutlined}
                      href="/#/app/inquiries"
                    >
                      {t("Back")}
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Table
              data={tableData}
              header={columns}
              // name={"Resolve duplicate inquiries to assign it someone"}
              options={{
                filterType: "checkbox",
                print: false,
                download: false,
                filter: false,
                selectableRows: "none",
              }}
            />
          </Grid>
        </Grid>
        {statusHistory && (
          <Grid container spacing={1} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <Grid style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={handleCloseTable}
                  color="primary"
                  size="small"
                  variant="outlined"
                  className={classes.buttonOutlined}
                  style={{ marginBottom: "10px" }}
                >
                  Close
                </Button>
              </Grid>
              <Table
                data={historyTableData}
                header={duplicateInquiryHeader()}
                name={`Status History of ${selectedDuplicateInquiryId}`}
                options={{
                  filterType: "checkbox",
                  print: false,
                  download: false,
                  filter: false,
                  selectableRows: "none",
                }}
              />
            </Grid>
          </Grid>
        )}
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: { width: "100%" },
        }}
      >
        <DialogTitle>
          <Typography style={{ fontWeight: "bold" }}>
            Are you sure you want to reject this inquiry?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            size="small"
            variant="outlined"
            className={classes.buttonOutlined1}
          >
            Cancel
          </Button>
          {showLoader ? (
            <CircularProgress />
          ) : (
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleSubmit}
              className={classes.buttonOutlined1}
            >
              Confirm
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={acceptOpen}
        onClose={handleAcceptClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: { width: "100%" },
        }}
      >
        <DialogTitle>
          <Typography style={{ fontWeight: "bold" }}>
            Are you sure you verify this inquiry as not duplicate ?
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleAcceptClose}
            color="primary"
            size="small"
            variant="outlined"
            className={classes.buttonOutlined1}
          >
            Cancel
          </Button>
          {showLoader ? (
            <CircularProgress style={{ marginBottom: "5px" }} />
          ) : (
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleAcceptSubmit}
              className={classes.buttonOutlined1}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DuplicateInquiries;
