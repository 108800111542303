import React, { useState } from "react";
import { Grid, Paper, CircularProgress, Button } from "@mui/material";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import FileBase64 from "react-file-base64";
import useStyles from "../styles";
import { fileUpload } from "../../marketplace/MarketplaceState";

const UploadImages = ({ onImagesUploaded }) => {
  const [uploadedFileReference, setUploadedFileReference] = useState([]);
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);
  const [imageAlert, setImageAlert] = useState(false);
  const { t } = useTranslation();
  var newArray = [...uploadedFileReference];

  const handleImageChange = async (e) => {
    await e.map(async (item) => {
      if (
        item.type === "image/png" ||
        item.type === "image/jpeg" ||
        item.type === "image/jpg"
      ) {
        setShowLoader(true);
        setImageAlert(false);
        var fileName = item.name;
        var fileType = fileName.split(".").pop();
        var baseData = item.base64.split(",");
        let getTokenData = {
          abbrevation: Math.floor(Math.random() * 100) + "Inquiry_FV_img",
          fileType: fileType,
          folderName: "Inquiries/",
          expireLimt: 1800,
          extension: fileName.split(".").pop(),
        };
        getAwsResponse(baseData, getTokenData, e);
      } else {
        setImageAlert(true);
      }
    });
  };

  const getAwsResponse = async (baseData, getTokenData, e) => {
    await fileUpload(
      baseData.length > 0 ? baseData[1] : "",
      getTokenData,
      e.type
    ).then(async (response) => {
      if (response.status === "200") {
        const objwithRef = {
          fileName: response.fileName || response.filename,
          ref: response.reference ,
          imageId: response.fileName || response.filename,
          folderName: response.folderName || response.folder || response.FolderName,
        };
        newArray.push(objwithRef);
        setUploadedFileReference([...newArray]);
        setShowLoader(false);
        onImagesUploaded(newArray);
      } else {
        setShowLoader(false);
      }
    });
  };

  const deleteFile = (data, index) => {
    let array = [...uploadedFileReference];
    const newArray = array.filter((m) => m.imageId !== data.imageId);
    setUploadedFileReference(newArray);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid lg={12} md={12} xs={12} sm={12} item>
          <Paper
            className={classes.paper}
            classes={{
              root: classnames(classes.widgetRoot, {
                [classes.noWidgetShadow]: null,
              }),
            }}
          >
            <div className={classes.imageForm}>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div style={{ marginBottom: "6px" }}>
                    {t("Upload images")}
                  </div>
                  <Grid container spacing={2} sx={{ paddingBottom: "18px" }}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                      <div className={classes.fileTypeInput}>
                        <FileBase64
                          className={classes.input}
                          multiple={true}
                          onDone={(e) => handleImageChange(e)}
                        />
                      </div>
                      {imageAlert ? (
                        <span className={classes.spanAlert}>
                          Please upload .png,.jpg,.jpeg image formats
                        </span>
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                      <label htmlFor="lot-file">
                        {showLoader ? <CircularProgress /> : <></>}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <div className="result">
        {uploadedFileReference.length > 0 &&
          uploadedFileReference.map((data, index) => {
            return (
              <div className="img-wrap">
                <img
                  src={data.ref}
                  alt=""
                  className="image-wrapper"
                  key={index}
                />
                <CancelIcon
                  className="close"
                  onClick={() => deleteFile(data, index)}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default UploadImages;
