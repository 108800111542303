import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    backgroundColor: "white",
    background: `url('/assets/svgs/bg.svg') no-repeat center center`,
    backgroundSize: "cover",
    height: "100vh",
    width: "100vw",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  logo: {
    position: "absolute",
    marginTop: "-25px",
    marginLeft: "-22px",
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "60%",
      marginLeft: "-32px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "-32px",
    },
  },

  box: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },

  paperContainer1: {
    background: "white",
    padding: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0",
      width: "30%",
      height: "56%",
    },
  },

  paperContainer2: {
    background: "white",
    padding: theme.spacing(4),
    borderTopLeftRadius: "0",
    borderBottomLeftRadius: "0",
    [theme.breakpoints.down("lg")]: {
      display: "none",
      height: "0%",
    },
    height: "56%",
  },

  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },

  textField: {
    borderBottomColor: theme.palette.background.light,
  },

  formButtons: {
    width: "100%",
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },

  copyright: {
    width: "100%",
    color: "black",
    opacity: ".6",
    fontSize: "14px",
    textAlign: "center",
    marginTop: "auto",
  },
}));
