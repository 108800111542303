import React, { useState, useRef, useEffect } from "react";
import {
  TextField,
  Typography,
  IconButton,
  Box,
  useTheme,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function EditableTextField({
  initialValue,
  label,
  onSave,
  disabled = false,
  fieldName,
  type,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [isHovering, setIsHovering] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const lastTapTime = useRef(0);
  const theme = useTheme();

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const validateInput = (input) => {
    if (type === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(input);
    } else if (type === "phone") {
      const phoneRegex = /^[0-9]{10}$/;
      return phoneRegex.test(input);
    }
    return true;
  };
  
  const handleEditClick = () => {
    if (!disabled) {
      setIsEditing(true);
    }
  };

  const handleSave = () => {
    if (isValid) {
      onSave(fieldName, value);
      setIsEditing(false);
    }
  };

  const handleCancel = () => {
    setValue(initialValue);
    setIsEditing(false);
    setIsValid(true);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setValue(newValue);
    setIsValid(validateInput(newValue));
  };

  const handleKeyDown = (event) => {
    if (disabled) return;

    if (event.key === "Enter" && !isEditing) {
      handleEditClick();
    } else if (event.key === "Enter" && isEditing && isValid) {
      handleSave();
    } else if (event.key === "Escape" && isEditing) {
      handleCancel();
    }
  };

  const handleDoubleTap = (event) => {
    if (disabled) return;

    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTapTime.current;
    if (tapLength < 300 && tapLength > 0) {
      handleEditClick();
    }
    lastTapTime.current = currentTime;
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        my: 1,
        gap: 4,
      }}
    >
      <Typography
        component="label"
        htmlFor={`editable-field-${label}`}
        sx={{
          minWidth: "100px",
          color: theme.palette.text.secondary,
          // fontWeight: "bold",
          textAlign: "right",
        }}
      >
        {label}
      </Typography>
      <Box
        ref={containerRef}
        onMouseEnter={() => !disabled && setIsHovering(true)}
        onMouseLeave={() => !disabled && setIsHovering(false)}
        onClick={handleDoubleTap}
        onKeyDown={handleKeyDown}
        sx={{
          display: "flex",
          alignItems: "center",
          flexGrow: 1,
          border: isEditing
            ? `2px solid ${isValid ? theme.palette.primary.main : theme.palette.error.main}`
            : isHovering && !disabled
              ? "1px solid #ccc"
              : "1px solid transparent",
          borderRadius: "4px",
          position: "relative",
          minHeight: "40px",
          cursor: disabled ? "default" : "text",
          transition: "border-color 0.3s ease",
        }}
        tabIndex={disabled ? -1 : 0}
        role={disabled ? "text" : "button"}
        aria-labelledby={`editable-field-${label}`}
        aria-label={
          disabled
            ? "This field is read-only"
            : isEditing
              ? "Press Enter to save, Escape to cancel"
              : "Double-tap or press Enter to edit"
        }
      >
        {isEditing && !disabled ? (
          <>
            <TextField
              id={`editable-field-${label}`}
              inputRef={inputRef}
              value={value}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              variant="standard"
              fullWidth
              autoFocus
              error={!isValid}
              // helperText={!isValid ? `Invalid ${type}` : ''}
              InputProps={{
                disableUnderline: true,
                style: { padding: "0 8px" },
              }}
              sx={{ flexGrow: 1 }}
            />
            <Box sx={{ display: "flex", position: "absolute", right: 0 }}>
              <IconButton
                onClick={handleSave}
                size="small"
                aria-label="Save"
                disabled={!isValid}
              >
                <CheckCircleIcon color={isValid ? "primary" : "disabled"} />
              </IconButton>
              <IconButton
                onClick={handleCancel}
                size="small"
                aria-label="Cancel"
              >
                <HighlightOffIcon />
              </IconButton>
            </Box>
          </>
        ) : (
          <>
            <Typography sx={{ flexGrow: 1, padding: "0 8px" }}>
              {value}
            </Typography>
            {isHovering && !disabled && (
              <IconButton
                onClick={handleEditClick}
                size="small"
                sx={{ position: "absolute", right: 0 }}
                aria-label="Edit"
              >
                <Edit fontSize="small" />
              </IconButton>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default EditableTextField;
