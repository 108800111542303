import React, { useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  Button,
} from "@mui/material";

const EmailBody = ({ body }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 150;

  if (body.length <= maxLength) {
    return <Box sx={{ whiteSpace: "pre-wrap", mt: 1 }}>{body}</Box>;
  }

  return (
    <Box sx={{ mt: 1 }}>
      <Box sx={{ whiteSpace: "pre-wrap" }}>
        {isExpanded ? body : `${body.slice(0, maxLength)}...`}
      </Box>
      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{ mt: 1 }}
        size="small"
      >
        {isExpanded ? "Read Less" : "Read More"}
      </Button>
    </Box>
  );
};

const Emails = ({ inquiryLeadData }) => {
  const emailThread = inquiryLeadData[0]?.emailThread;
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (event, expanded) => {
    setIsExpanded(expanded);
  };

  if (!emailThread || emailThread.length === 0) {
    return (
      <Paper sx={{ p: 2 }}>
        <Grid item xs={12} md={12}>
          <Typography variant="h6" fontWeight={"bold"} gutterBottom>
            Emails
          </Typography>
          <Typography p={3}>No emails available</Typography>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper sx={{ p: 2 }}>
      <Grid item xs={12} md={12}>
        <Typography variant="h6" fontWeight={"bold"} gutterBottom>
          Emails
        </Typography>
        <Accordion expanded={isExpanded} onChange={handleChange}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="email-content"
            id="email-header"
          >
            <Typography>
              <b>{isExpanded ? "Close Emails" : "View Emails"}</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {emailThread?.map((email, index) => (
              <Box key={email._id} mb={3}>
                <Typography variant="h6" gutterBottom>
                  Email {index + 1}
                </Typography>
                <Typography>
                  <b>Subject:</b> {email.subject}
                </Typography>
                <Typography>
                  <b>To:</b> {email.emailReceivedAt.join(", ")}
                </Typography>
                <Typography>
                  <b>Date:</b>{" "}
                  {new Date(email.dateAndTimeOfEmail).toLocaleString()}
                </Typography>
                <Box
                  sx={{
                    mt: 1,
                    mb: 2,
                    backgroundColor: "#f5f5f5",
                    p: 2,
                    borderRadius: 1,
                  }}
                >
                  <EmailBody body={email.emailBody} />
                </Box>
                {index < emailThread.length - 1 && <Divider />}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Paper>
  );
};

export default Emails;
