import React from "react";
import { Typography, Button } from "@mui/material";

const SectionHeader = ({ title, buttonText, buttonAction }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      margin: "0 10px",
    }}
  >
    <Typography variant="h6" style={{ margin: "24px 0" }}>
      {title}
    </Typography>
    <Button
      onClick={buttonAction}
      variant="outlined"
      style={{ margin: "15px 10px", textTransform: "none" }}
    >
      {buttonText}
    </Button>
  </div>
);

export default SectionHeader;
