import React, { useState, useEffect } from "react";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Chip,
  Divider,
  Stack,
} from "@mui/material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { getWebProfileDownloaders } from "./webLeadsState";
import { formatDateAndTime } from "../inquiries/helper/utils";
import toast from "react-hot-toast";

const Webleads = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);

  const breadcrumb = [
    { label: "Home", link: "/#/app/dashboard" },
    { label: "Website Leads", link: "" },
  ];

  useEffect(() => {
    const getLeads = async () => {
      try {
        const data = await getWebProfileDownloaders();
        setLeads(data.data);
        setLoading(false);
      } catch (err) {
        toast.error("Failed to fetch!");
        setLoading(false);
      }
    };

    getLeads();
  }, []);

  return (
    <Box mt={12} height="100vh">
      <BreadCrumbs data={breadcrumb} />

      <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Box>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
              Website Leads
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              Companies who downloaded Binbag Profile
            </Typography>
          </Box>
        </Stack>
      </Paper>

      <Paper elevation={1}>
        <Box p={3}>
          <TableContainer>
            <Table stickyHeader aria-label="website leads table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  >
                    Organization
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  >
                    Source
                  </TableCell>
                  <TableCell
                    sx={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  >
                    Email Sent
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        py={4}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : leads?.length > 0 ? (
                  leads?.map((lead) => (
                    <TableRow
                      key={lead.id}
                      hover
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{formatDateAndTime(lead.createdAt)}</TableCell>
                      <TableCell>{lead.name}</TableCell>
                      <TableCell>{lead.email}</TableCell>
                      <TableCell>{lead.organization}</TableCell>
                      <TableCell>{lead.source || "Binbag Website"}</TableCell>
                      <TableCell>
                        <Chip
                          label={lead.emailSent ? "Sent" : "Not Sent"}
                          color={lead.emailSent ? "success" : "error"}
                          size="small"
                          sx={{ minWidth: 75 }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        py={4}
                      >
                        <Typography color="text.secondary">
                          No data found
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

export default Webleads;
