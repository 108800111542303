import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import SelectUserDialog from "./components/SelectUserDialog";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Badge,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import SelectMode from "./components/SelectMode";
import QuestionsDialog from "./components/QuestionsDialog";
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import {
  getFile,
  statusUpdate,
  getRecyclerUsersUnderAdmin,
  getAllCommunicationMethod,
  getAllinquiryLeadsStatus,
} from "./InquiryState";
import { createMprId } from "./InquiryState";
import InquiryHistory from "./components/InquiryHistory";
import ConfirmDialog from "./components/ConfirmDialog";
import InquiryHeader from "./components/InquiryHeader";
import BasicInquiryDetails from "./components/cards/BasicInquiryDetails";
import InquiryOwner from "./components/cards/InquiryOwner";
import Attachments from "./components/cards/Attachments";
import Emails from "./components/cards/Emails";

const UpdateInquiry = () => {
  const history = useHistory();
  var classes = useStyles();
  const { t } = useTranslation();
  const InquiryLeadData = useSelector((state) => state);
  var usersData = useSelector((state) => state.userdata);

  const [inquiryLeadData, setInquiryLeadData] = useState([
    InquiryLeadData.selectedInquiryLead,
  ]);
  let dispatch = useDispatch();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loggedUserId, setLoggedUserId] = useState("");
  const [status, setStatus] = useState(inquiryLeadData[0]?.status);

  const checkUserRoles = (usersData) => {
    if (usersData && usersData.getData) {
      if (
        usersData.getData.type === "tenant" &&
        !usersData.getData.roles.includes("user")
      ) {
        setIsAdmin(true);
        setLoggedUserId(usersData?.getData?._id);
      } else {
        setIsAdmin(false);
      }
    }
  };

  const [showLoader, setShowLoader] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [inquiryLeadsStatus, setInquiryLeadsStatusStatus] = useState([]);
  //mode of addressing and followup modal
  const [options, setoptions] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedModes, setSelectedModes] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");
  const [dialogTitle, setDialogTitle] = useState("Mode Of Follow up");

  const [mprID, setMprID] = useState(inquiryLeadData[0]?.materialRequestID);
  const [disabledStatus, setDisabledStatus] = useState(false);

  const getInquiryLeadsStatus = async () => {
    try {
      setStatusLoading(true);
      const response = await getAllinquiryLeadsStatus();

      if (response.status === "200") {
        const backendStatusOptions = response.data.data[0].inquiryStatus;
        if (
          usersData &&
          usersData.getData &&
          usersData.getData.type === "tenant" &&
          usersData.getData.roles.includes("user")
          // inquiryLeadData[0]?.statusHistory[0]?.owner[0]?.length != 0
        ) {
          const filteredOptions = backendStatusOptions.filter(
            (option) => option !== "Unassigned"
          );
          setInquiryLeadsStatusStatus(filteredOptions);
        } else {
          setInquiryLeadsStatusStatus(backendStatusOptions);
        }
        setStatusLoading(false);
      } else if (response.status === "401") {
        toast.error(response.message);
      }
    } catch (error) {
      setInquiryLeadsStatusStatus(["No status available"]);
      setStatusLoading(false);
    }
  };
  let leadOwnerId = inquiryLeadData[0]?.statusHistory[0]?.owner[0]?._id;
  let currentLoggedInUser = usersData?.getData?._id;
  const disableStatus = () => {
    if (leadOwnerId !== currentLoggedInUser || leadOwnerId === undefined) {
      setDisabledStatus(true);
    } else if (inquiryLeadData[0]?.status === "Unassigned") {
      setDisabledStatus(true);
    } else {
      setDisabledStatus(false);
    }
  };

  useEffect(() => {
    checkUserRoles(usersData);
    disableStatus();
    getInquiryLeadsStatus();
    if (inquiryLeadData[0] == undefined) {
      history.push("/app/inquiries");
    }
  }, [loggedUserId]);

  ////////// for selectMode dialog
  const getCommunicationMethods = async () => {
    try {
      const response = await getAllCommunicationMethod();
      if (response.status === "200") {
        const responseModes = response.data;
        const activeModes = responseModes?.filter(
          (item) => item.isActive === true
        );
        setoptions(activeModes);
      } else if (response.status === "401") {
        toast.error(response.message);
      }
    } catch (error) {
      setoptions(["No options available"]);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    getCommunicationMethods();
  };

  const handleCloseDialog = () => {
    setSelectedModes([]);
    setTextareaValue("");
    setStatus(inquiryLeadData[0].status);
    setOpenDialog(false);
  };

  const clearAll = () => {
    setSelectedModes([]);
    setTextareaValue("");
    setOpenDialog(false);
    setShowLoader(false);
    setQuestionsDialogOpen(false);
    setTitle("");
    setDateLabel("");
  };
  const handleSelectModeChange = (event) => {
    setSelectedModes(event.target.value);
  };
  const updateStatus = async (data) => {
    setShowLoader(true);
    await statusUpdate(data).then((response) => {
      if (response.status === "200") {
        setStatus(data.status);
        toast.success(response.message);
        clearAll();
        handleConfirmDialogClose();
      } else if (response.status === "401") {
        toast.error(response.message);
        handleConfirmDialogClose();
      } else {
        toast.error("Something went wrong!");
        setStatus(inquiryLeadData[0].status);
        clearAll();
        handleConfirmDialogClose();
      }
    });
    setShowLoader(false);
  };

  function getSelectedModeIds(data, selectedModes) {
    const selectedModeIds = data
      .filter((item) => selectedModes.includes(item.mode))
      .map((item) => item._id);
    return selectedModeIds;
  }

  const handleConfirm = () => {
    const selectedModeIds = getSelectedModeIds(options, selectedModes);
    if (status === "Addressed" && selectedModes.length > 0) {
      let data = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        // owner: usersData.getData._id,
        modeOfAddressing: {
          addressing: selectedModeIds,
          notes: textareaValue,
        },
      };
      updateStatus(data);

      // clearAll();
    } else if (status === "Followed Up" && selectedModes.length > 0) {
      let data = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        // owner: usersData.getData._id,
        modeOfFollowup: {
          followup: selectedModeIds,
          notes: textareaValue,
        },
      };
      updateStatus(data);

      // clearAll();
    } else {
      setStatus(inquiryLeadData[0].status);
    }
  };

  //to assign and reassign user to a lead
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [recyclerUsers, setRecyclerUsers] = useState([]);
  const [userAssignTitle, setUserAssignTitle] = useState("Assign To");
  const [currentAssignedUser, setCurrentAssignedUser] = useState(
    inquiryLeadData[0]?.statusHistory[0]?.owner[0]?.userName
  );
  const fetchRecyclerUsersUnderAdmin = async () => {
    try {
      const response = await getRecyclerUsersUnderAdmin();
      if (response.status === "200") {
        setRecyclerUsers(response.userDetail);
      }
    } catch (error) {
      console.error("API Error:", error.message);
    }
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleConfirmDialogOpen = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleDialogConfirmationCancel = () => {
    handleConfirmDialogClose();
  };

  const handleDialogConfirmation = () => {
    handleMoveToTrash();
  };

  const handleMoveToTrash = () => {
    let data = {
      inquiryId: inquiryLeadData[0]._id,
      status: "Rejected",
      adminId: loggedUserId,
      rejected: {
        notes: "Trash data entered by user",
      },
    };
    updateStatus(data);
  };

  const handleUserAssignOpen = () => {
    setUserAssignTitle("Assign To");
    setOpen(true);
    fetchRecyclerUsersUnderAdmin();
  };
  const handleUserReAssignOpen = () => {
    setUserAssignTitle("Reassign To");
    setOpen(true);
    fetchRecyclerUsersUnderAdmin();
  };

  const handleClose = () => {
    setOpen(false);
    setShowLoader(false);
  };

  const handleUserSelect = (value) => {
    setSelectedUser(value);
  };

  const statusUpdateToAssignUser = async (data) => {
    try {
      setShowLoader(true);
      const response = await statusUpdate(data);
      if (response.status === "200") {
        toast.success("User Assigned Successfully");
        handleClose();
        setTimeout(() => {
          setShowLoader(false);
          history.push("/app/inquiries");
        }, 1000);
      } else if (response.status === "401") {
        toast.error(response.message);
        handleClose();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error(error.message);
      handleClose();
    }
  };

  const handleSelectedUserConfirm = () => {
    const assignData = {
      inquiryId: inquiryLeadData[0]._id,
      owner: selectedUser._id,
      adminId: loggedUserId,
    };
    if (userAssignTitle === "Reassign To") {
      assignData.status = status;
    } else {
      assignData.status = "Assigned";
    }
    statusUpdateToAssignUser(assignData);
  };

  const [quotationStatusUpdateData, setQuotationStatusUpdateData] = useState(
    {}
  );

  //fun to generate mpr id
  const generateMPRid = (callback) => {
    let sendData = {
      abbrevation: inquiryLeadData[0]?.companyName,
    };

    createMprId(sendData, dispatch)
      .then((response) => {
        setMprID(response?.MPRID);
        setQuotationStatusUpdateData({
          inquiryId: inquiryLeadData[0]._id,
          status: "Quotation Sent",
          materialRequestID: response?.MPRID,
        });
        callback();
      })
      .catch((error) => {
        console.error("Error:", error);
        setMprID("Unable to generate MPR ID try again!!!!");
        callback();
      });
  };

  /////////////for Questions dialog
  const [questionsDialogOpen, setQuestionsDialogOpen] = useState(false);
  const [dialogOptionsRenderCondition, setDialogOptionsRenderCondition] =
    useState("");
  const [title, setTitle] = useState("Custom Dialog Title");
  const [dateLabel, setDateLabel] = useState("Custom Date Label");
  const [modeLabel, setModeLabel] = useState("Select Mode");

  const handleOpenQuestionsDialog = (dialogOpenPrompt) => {
    getCommunicationMethods();
    setDialogOptionsRenderCondition(dialogOpenPrompt);
    if (dialogOpenPrompt === "awaiting_response") {
      setTitle("When was the last time you reached out to the customer?");
      setDateLabel("Select Date and Time");
      setQuestionsDialogOpen(true);
    } else if (dialogOpenPrompt === "field_visit_planned") {
      setTitle("When are you planning to do the field visit?");
      setDateLabel("Scheduled Date");
      setQuestionsDialogOpen(true);
    } else if (dialogOpenPrompt === "field_visit_completed") {
      setTitle("When did you do the field visit?");
      setDateLabel("Completed Date:");
      setQuestionsDialogOpen(true);
    } else if (dialogOpenPrompt === "followup_requested") {
      setTitle("When is follow up requested?");
      setDateLabel("Requested Date:");
      setQuestionsDialogOpen(true);
    } else if (dialogOpenPrompt === "quotation_sent") {
      if (!inquiryLeadData[0].materialRequestID) {
        setTitle("Adding Material Pickup Request ID");
        generateMPRid(() => {
          setQuestionsDialogOpen(true);
        });
      } else {
        setTitle("Existing Material Pickup Request ID");
        setQuestionsDialogOpen(true);
        setQuotationStatusUpdateData({
          inquiryId: inquiryLeadData[0]._id,
          status: "Quotation Sent",
        });
      }
    } else if (dialogOpenPrompt === "accepted") {
      setTitle("Are you sure you want to proceed with accepting this inquiry?");
      setQuestionsDialogOpen(true);
    } else if (dialogOpenPrompt === "rejected") {
      setTitle("Reason for rejection");
      setQuestionsDialogOpen(true);
    }
  };
  const handleCloseQuestionsDialog = () => {
    setQuestionsDialogOpen(false);
    setStatus(inquiryLeadData[0].status);
  };
  function convertDateToISOString(inputDateString) {
    const inputDate = new Date(inputDateString);
    return inputDate.toISOString();
  }

  const handleQuestionDataConfirm = (data) => {
    if (status === "Awaiting Response") {
      const selectedModeIds = getSelectedModeIds(options, data?.selectedModes);
      const outputDateString = convertDateToISOString(
        data.selectedDate?.toString()
      );
      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        awaitingResponse: {
          modeOfContact: selectedModeIds,
          dateAndTimeOfLastContact: outputDateString,
          notes: data?.textFieldValue,
        },
      };
      updateStatus(statusUpdateData);
    }
    if (status === "Field Visit Planned") {
      const outputDateString = convertDateToISOString(
        data.selectedDate?.toString()
      );
      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        fieldVisitPlanned: {
          dateAndTime: outputDateString,
          notes: data?.textFieldValue,
        },
      };
      updateStatus(statusUpdateData);
    }
    if (status === "Field Visit Completed") {
      const outputDateString = convertDateToISOString(
        data.selectedDate?.toString()
      );
      const extractedImages = data.uploadedImages?.map((image) => {
        return {
          fileName: image.fileName || image.filename,
          folderName: image.folderName || image.folder || image.FolderName,
        };
      });
      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        // owner: usersData.getData._id,
        fieldVisitCompleted: {
          feedback: data?.textFieldValue,
          dateAndTime: outputDateString,
          photos: extractedImages,
        },
      };
      updateStatus(statusUpdateData);
    }
    if (status === "Followup Requested") {
      const outputDateString = convertDateToISOString(
        data.selectedDate?.toString()
      );

      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        // owner: usersData.getData._id,
        followupRequested: {
          dateAndTime: outputDateString,
          notes: data?.textFieldValue,
        },
      };
      updateStatus(statusUpdateData);
    }
    if (status === "Quotation Sent") {
      updateStatus(quotationStatusUpdateData);
    }
    if (status === "Accepted") {
      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
      };
      updateStatus(statusUpdateData);
    }
    if (status === "Rejected") {
      let statusUpdateData = {
        inquiryId: inquiryLeadData[0]._id,
        status: status,
        rejected: {
          notes: data?.textFieldValue,
        },
      };
      updateStatus(statusUpdateData);
    }

    // setConfirmedData(data);
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
    if (selectedStatus === "Addressed") {
      setDialogTitle("Mode Of Addressing");
      handleOpenDialog(true);
    }
    if (selectedStatus === "Awaiting Response") {
      handleOpenQuestionsDialog("awaiting_response");
    }

    if (selectedStatus === "Followed Up") {
      setDialogTitle("Mode Of Follow up");
      handleOpenDialog(true);
    }
    if (selectedStatus === "Followup Requested") {
      handleOpenQuestionsDialog("followup_requested");
    }
    if (selectedStatus === "Field Visit Planned") {
      handleOpenQuestionsDialog("field_visit_planned");
    }

    if (selectedStatus === "Field Visit Completed") {
      handleOpenQuestionsDialog("field_visit_completed");
    }
    if (selectedStatus === "Quotation Sent") {
      handleOpenQuestionsDialog("quotation_sent");
    }
    if (selectedStatus === "Accepted") {
      handleOpenQuestionsDialog("accepted");
      // updateStatus(statusUpdateData);
    }
    if (selectedStatus === "Rejected") {
      handleOpenQuestionsDialog("rejected");
    }
  };

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while downloading the file.");
      });
  };

  const sectionRefs = {
    "Owner details": useRef(null),
    "Inquiry information": useRef(null),
    Attachments: useRef(null),
    Emails: useRef(null),
    Activity: useRef(null),
  };

  const handleClick = (item) => {
    sectionRefs[item].current.scrollIntoView({ behavior: "smooth" });
  };

  const counts = {
    "Owner details": 0,
    "Inquiry information": 0,
    Attachments: inquiryLeadData[0]?.attachmentDetails?.length || 0,
    Emails: inquiryLeadData[0]?.isAutomated
      ? inquiryLeadData[0]?.emailThread?.length
      : 0,
    Activity: inquiryLeadData[0]?.statusHistory.length || 0,
  };

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("Inquiries"), link: "/#/app/inquiries" },
    { label: t("Update"), link: "" },
  ];

  return (
    <>
      <Toaster />
      <BreadCrumbs data={breadcrumb} />

      <div className={classes.content}>
        <InquiryHeader
          inquiryLeadData={inquiryLeadData}
          isAdmin={isAdmin}
          handleUserReAssignOpen={handleUserReAssignOpen}
          handleUserAssignOpen={handleUserAssignOpen}
          handleConfirmDialogOpen={handleConfirmDialogOpen}
          showLoader={showLoader}
          status={status}
          inquiryLeadsStatus={inquiryLeadsStatus}
          disabledStatus={disabledStatus}
          statusLoading={statusLoading}
          handleStatusChange={handleStatusChange}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Paper
              sx={{
                p: 2,
                position: "sticky",
                top: 0,
                height: "100%",
                overflowY: "auto",
              }}
            >
              <Typography
                variant="h6"
                className={classes.sectionTitle}
                gutterBottom
                fontWeight={"bold"}
              >
                Quick links
              </Typography>
              <List component="nav">
                {Object.keys(sectionRefs).map((item) => (
                  <ListItem
                    button
                    key={item}
                    // selected={activeSection === item}
                    onClick={() => handleClick(item)}
                    sx={{ mb: 1 }}
                  >
                    <Badge
                      badgeContent={counts[item] || null}
                      color="lightPrimary"
                    >
                      <ListItemText primary={item} />
                    </Badge>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box
              ref={sectionRefs["Owner details"]}
              id="Owner details"
              mb={2}
              style={{ scrollMarginTop: "100px" }}
            >
              <InquiryOwner
                inquiryOwner={inquiryLeadData[0]?.statusHistory[0]?.owner[0]}
                lastUpdated={inquiryLeadData[0]?.updatedAt}
                inquiryLeadsStatus={inquiryLeadsStatus}
                disabledStatus={disabledStatus}
                statusLoading={statusLoading}
                handleStatusChange={handleStatusChange}
                currentStatus={inquiryLeadData[0]?.status}
              />
            </Box>
            <Box
              ref={sectionRefs["Inquiry information"]}
              id="Inquiry information"
              style={{ scrollMarginTop: "100px" }}
            >
              <BasicInquiryDetails
                inquiryLeadData={inquiryLeadData}
                handleStatusChange={handleStatusChange}
                handleDownload={handleDownload}
              />
            </Box>
            <Box
              ref={sectionRefs["Attachments"]}
              id="Attachments"
              mb={2}
              style={{ scrollMarginTop: "100px" }}
            >
              <Attachments inquiryLeadData={inquiryLeadData} />
            </Box>
            <Box
              ref={sectionRefs["Emails"]}
              id="Emails"
              style={{ scrollMarginTop: "100px" }}
            >
              <Emails inquiryLeadData={inquiryLeadData} />
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{ px: { xs: 3, md: 0 }, pt: { xs: 3, md: 0 } }}
          ref={sectionRefs["Activity"]}
          id="Activity"
          style={{ scrollMarginTop: "90px" }}
        >
          <InquiryHistory inquiryData={inquiryLeadData[0]} />
        </Box>
      </div>

      <SelectUserDialog
        open={open}
        handleClose={handleClose}
        users={recyclerUsers}
        handleUserSelect={handleUserSelect}
        handleSelectedUserConfirm={handleSelectedUserConfirm}
        showLoader={showLoader}
        title={userAssignTitle}
        currentAssignedUser={currentAssignedUser}
      />
      <SelectMode
        openDialog={openDialog}
        handleDialogClose={handleCloseDialog}
        title={dialogTitle}
        options={options}
        selectedModes={selectedModes}
        onModeChange={handleSelectModeChange}
        showTextarea={true}
        textareaValue={textareaValue}
        onTextareaChange={(e) => setTextareaValue(e.target.value)}
        onConfirm={handleConfirm}
        showLoader={showLoader}
      />
      <QuestionsDialog
        open={questionsDialogOpen}
        onClose={handleCloseQuestionsDialog}
        title={title}
        dateLabel={dateLabel}
        modeLabel={modeLabel}
        modeOptions={options}
        onConfirm={handleQuestionDataConfirm}
        dialogOptionsRenderCondition={dialogOptionsRenderCondition}
        showLoader={showLoader}
        mprID={mprID}
      />
      <ConfirmDialog
        open={confirmDialogOpen}
        title="Moving to trash"
        description="Are you sure you want to proceed?"
        onCancel={handleDialogConfirmationCancel}
        onConfirm={handleDialogConfirmation}
        isLoading={showLoader}
      />
    </>
  );
};

export default UpdateInquiry;
