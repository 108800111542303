import React from "react";
import { CircularProgress } from "@mui/material";

export const tableOptions = (
  fetchLoader,
  rowsPerPageOptions,
  setPageData,
  handleRowClick,
  downloadFileName,
  totalCount,
  currentPage,
  handleSearchChange
) => ({
  print: false,
  download: true,
  downloadOptions: {
    filename: downloadFileName,
  },
  onDownload: (buildHead, buildBody, columns, data) => {
    const modifiedData = data.map((row) => {
      const statusElement = row.data[row.data.length - 1];
      const status = statusElement.props.children;
      const modifiedRow = [...row.data.slice(0, -1), status];
      return { ...row, data: modifiedRow };
    });
    return `${buildHead(columns)}${buildBody(modifiedData)}`;
  },

  filter: true,
  selectableRows: "none",
  filterType: "dropdown",
  rowsPerPageOptions: rowsPerPageOptions,
  page: currentPage - 1,
  count: totalCount,
  serverSide: true,
  onSearchChange: handleSearchChange,
  onChangePage: (currentPage) => {
    setPageData((prevState) => ({ ...prevState, page: currentPage + 1 }));
  },
  onChangeRowsPerPage: (numberOfRows) => {
    setPageData((prevState) => ({
      ...prevState,
      limit: numberOfRows,
      page: 1,
    }));
  },
  onRowClick: handleRowClick,
  textLabels: {
    body: {
      noMatch: fetchLoader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "#0D6937",
              marginRight: "5px",
            }}
          />{" "}
        </div>
      ) : (
        "No Requests Found"
      ),
    },
    pagination: {
      next: "Next Page",
      previous: "Previous Page",
      rowsPerPage: "Rows per page:",
      displayRows: "of",
    },
  },
  sortOrder: {
    name: "Date",
    direction: "desc",
  },
});
