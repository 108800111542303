import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "#fafafa",
    background: `url('/assets/svgs/bg.svg') no-repeat center center`,
    backgroundSize: "cover",
    minHeight: "100vh",
  },

  content: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    // width: `calc(100vw - 240px)`,
    height: "100%",
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },
}));
