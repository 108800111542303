import FormControl from "@mui/material/FormControl";
import { Grid, Box, CircularProgress } from "@mui/material";
import {
  Typography,
  Paper,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../../components/BreadCrumbs/BreadCrumbs";
import useStyles from "../styles";
import HeaderComp from "../components/HeaderComp";
import { formatIndianCurrency } from "../../../utils/currencyFormat";
import { ConfirmationDialog } from "../components/ConfirmationDialog";
import moment from "moment";
import {
  actionOnDD,
  getFile,
  getPaymentHistoryStatus,
  getAllDDRequests,
} from "../InternalFormState";
import StatusHistory from "../components/StatusHistory";
import { EMD_STATUS, TENDER_DOC_STATUS } from "../components/config/contants";
import RenderAttachments from "../components/RenderAttachments";
import toast, { Toaster } from "react-hot-toast";

const EditDDRequest = () => {
  var classes = useStyles();
  const location = useLocation();
  const lastSegment = location.pathname.split("/").pop();
  const [selectedData, setSelectedData] = useState();
  const loggedInData = useSelector((state) => state.userdata.getData);
  const [selectedStatus, setSelectedStatus] = useState(selectedData?.status);
  const [isSameApprover, setIsSameApprover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [statusHistory, setStatusHistoy] = useState([]);
  const [options, setOptions] = useState([]);

  const { t } = useTranslation();

  const [isDialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedStatus(selectedData?.status);
  };

  useEffect(() => {
    let data = {
      page: 1,
      limit: 10,
      searchTerm: lastSegment,
    };
    async function fetchDDRequests() {
      try {
        setFetching(true);
        const allDDRequests = await getAllDDRequests(data);
     
        if (allDDRequests.status === "200" && allDDRequests.count !== 0) {
          setSelectedData(allDDRequests?.data[0]);
          setSelectedStatus(allDDRequests?.data[0]?.status);
   
          if (allDDRequests?.data[0].type === "EMD") {
            setOptions(EMD_STATUS);
          } else {
            setOptions(TENDER_DOC_STATUS);
          }
        }
        if (allDDRequests.status === "204") {
          toast.error(allDDRequests.message);
        }
        setFetching(false);
      } catch (error) {
        toast.error("Error fetching DD requests");
        setFetching(false);
      }
    }
    fetchDDRequests();
  }, [lastSegment]);

  useEffect(() => {
    getPaymentHistory();
  }, [selectedData]);

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          toast.success(response.message);
        }
      })
      .catch(() => {
        toast.error("An error occurred while downloading the file.");
      });
  };

  const handleConfirmDialog = (
    confirm,
    approverComments,
    rejectionReason,
    transactionID,
    adminComments,
    uploadedFiles,
    amountPaid,
    selectedRadioValue,
    saleData,
    ddNumber,
    supplierPayId
  ) => {
    if (confirm) {
      takeActionOnPaymentRequest(
        selectedData,
        selectedStatus,
        rejectionReason,
        approverComments,
        transactionID,
        adminComments,
        uploadedFiles,
        amountPaid,
        saleData,
        ddNumber,
        supplierPayId
      );
    } else {
      setSelectedStatus(selectedData?.status);
      setDialogOpen(false);
    }
  };

  const handleStatusChange = (event) => {
    let selectedValue = event.target.value;
    handleOpenDialog();
    setSelectedStatus(selectedValue);

    if (
      selectedData &&
      loggedInData?._id === selectedData?.raisedBy?.[0]?._id &&
      loggedInData?.roles.some((item) => item === "Approver")
    ) {
      setIsSameApprover(true);
    } else {
      setIsSameApprover(false);
    }
  };

  async function getPaymentHistory() {
    let updatedFormData = {
      paymentStatusId: selectedData?._id,
    };
    try {
      const response = await getPaymentHistoryStatus(updatedFormData);


      if (response.status === "200") {
        setStatusHistoy(response?.data[0]);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      setStatusHistoy([]);
    }
  }

  async function takeActionOnPaymentRequest(
    formData,
    newStatus,
    rejectionReason,
    approverComments,
    transactionID,
    adminComments,
    uploadedFiles,
    amountPaid,
    saleData,
    ddNumber,
    supplierPayId
  ) {
    setIsLoading(true);

    try {
      let updatedFormData = {
        ...formData,
        status: newStatus,
        raisedBy: formData?.raisedBy[0]?._id,
        attachments: uploadedFiles,
      };

      if (newStatus === "Rejected" && rejectionReason !== "") {
        updatedFormData.reasonForRejection = rejectionReason;
      } else if (newStatus === "Approved") {
        updatedFormData.comments = approverComments;
      } else if (newStatus === "DD Submitted") {
        updatedFormData.ddNumber = ddNumber;
        updatedFormData.comments = approverComments;
      } else if (newStatus === "DD Cancelled") {
        updatedFormData.comments = approverComments;
      } else if (newStatus === "DD Adjusted") {
        updatedFormData.tenderAdjustedAgainst = supplierPayId;
        updatedFormData.comments = approverComments;
      } else if (newStatus === "Payment Completed") {
        updatedFormData.transactionID = transactionID;
        updatedFormData.comments = adminComments;
      } else if (newStatus === "Closed") {
        updatedFormData.comments = adminComments;
      }

      const response = await actionOnDD(updatedFormData);
      if (response.status === "200") {
        toast.success("Successfully updated!");
        getPaymentHistory();
        setIsLoading(false);
        setSelectedStatus(selectedStatus);
        setDialogOpen(false);
      } else if (response.status === "400") {
        toast.error(response?.message || "Something went wrong!");
        setIsLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      setIsLoading(false);
      setDialogOpen(false);
    }
  }
  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("DD Requests"), link: "/#/app/payments" },
    { label: t("Edit"), link: "" },
  ];

  if (fetching)
    return (
      <>
        <BreadCrumbs data={breadcrumb} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      </>
    );

  return (
    <div>
      <Toaster />
      <BreadCrumbs data={breadcrumb} />

      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <HeaderComp
            selectedStatus={selectedStatus}
            selectedData={selectedData}
            backUrlPayload="ddRequestComp"
          />
        </div>

        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={5}
            // sx={{ borderRight: { md: ".1px solid gray" } }}
          >
            <Paper sx={{ height: "100%" }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Date")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {moment(selectedData?.date).format("DD-MM-YYYY")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("DD number")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.ddNumber || "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Type")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.type}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Raised by")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.raisedBy[0]?.username ||
                        selectedData?.raisedBy[0]?.userName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <b>{t("Payment from")}:</b>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.paymentFrom}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <RenderAttachments
                attachments={selectedData?.attachments}
                handleDownload={handleDownload}
              />
              <Box sx={{ marginLeft: "16px", padding: "10px 0" }}>
                {selectedData?.purposeOfDD && (
                  <Box mb={2}>
                    <Typography fontWeight="600" fontSize="14px">
                      <b>{t("Purpose")}:</b>
                    </Typography>
                    {selectedData?.purposeOfDD}
                  </Box>
                )}
                {selectedData?.reasonForRejection && (
                  <>
                    <Typography
                      sx={{ color: "#FF5C93" }}
                      fontWeight="600"
                      fontSize="14px"
                    >
                      {t("Rejected reason")}:
                    </Typography>
                    {selectedData?.reasonForRejection}
                  </>
                )}
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={7}>
            <Paper>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <b>{t("Total amount")}:</b>
                    </TableCell>
                    <TableCell>
                      <b> {formatIndianCurrency(selectedData?.amount)} </b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      <Typography variant="subtitle2" gutterBottom>
                        <b>{t("Account details")}</b>
                      </Typography>
                      <Typography variant="body2">
                        Name:{" "}
                        {selectedData?.paymentDetails?.accountHolderName ||
                          "N/A"}
                        <br />
                        Bank: {selectedData?.paymentDetails?.bankName || "N/A"}
                        <br />
                        Account no:{" "}
                        {selectedData?.paymentDetails?.accountNumber || "N/A"}
                        <br />
                        IFSC: {selectedData?.paymentDetails?.ifscCode || "N/A"}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: "none" }}>
                      <Typography variant="subtitle2" gutterBottom>
                        <b>{t("Contact person")}</b>
                      </Typography>
                      <Typography variant="body2">
                        Company: {selectedData?.contactPerson?.company || "N/A"}
                        <br />
                        Name: {selectedData?.contactPerson?.name || "N/A"}
                        <br />
                        Email: {selectedData?.contactPerson?.email || "N/A"}
                        <br />
                        Phone no: {selectedData?.contactPerson?.phone || "N/A"}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ border: "none" }}>
                      {selectedData?.tenderAdjustedAgainst && (
                        <Box mb={2}>
                          <Typography fontWeight="600" fontSize="14px">
                            <b>{t("Adjusted against")}:</b>
                          </Typography>
                          {selectedData?.tenderAdjustedAgainst}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ border: "none" }}>
                      <Box>
                        <Typography variant="subtitle2" gutterBottom>
                          <b>{t("Status")}</b>
                        </Typography>
                        <FormControl variant="outlined" fullWidth>
                          <Select
                            value={selectedStatus}
                            onChange={handleStatusChange}
                            disabled={selectedStatus === "Closed"}
                          >
                            {options.map((status) => (
                              <MenuItem
                                key={status}
                                value={status}
                                disabled={
                                  (loggedInData?.roles?.includes("user") &&
                                    (status === "Approved" ||
                                      status === "Closed" ||
                                      status === "Rejected" ||
                                      status === "DD Cancelled" ||
                                      status === "Payment Completed")) ||
                                  (loggedInData?.roles?.includes("Admin") &&
                                    status === "DD Adjusted")
                                }
                              >
                                {status}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>

        <div className={classes.statusDiv}>
          {statusHistory && (
            <StatusHistory statusData={statusHistory} editable={false} />
          )}
        </div>
      </div>

      <ConfirmationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDialog}
        inputStatus={selectedStatus}
        title="Changing status to "
        textFieldLabel="Reason"
        isLoading={isLoading}
        imageFileName="ddDocs"
      />
    </div>
  );
};

export default EditDDRequest;
