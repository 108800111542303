import React from "react";
import { Typography } from "../../components/Wrappers/Wrappers";
import { Grid, Button, Card } from "@mui/material";
import { useTranslation } from "react-i18next";

//components
import useStyles from "./styles";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import TabPannel from "./components/TabPannel";

const Customers = () => {
  // eslint-disable-next-line
  const { t, i18n } = useTranslation();
  var classes = useStyles();
  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("common_customers"), link: "" },
  ];

  return (
    <>
      <BreadCrumbs data={breadcrumb} />
      <div className={classes.content}>
        <div className={classes.pageTitleContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <div className={classes.HeaderContainer}>
                  <div className={classes.tenantHeader}>
                    <Typography variant="h3" size="sm">
                      {t("customer_span")}
                    </Typography>
                  </div>
                  <div>
                    <Button
                      variant="outlined"
                      href="/#/app/addCustomer"
                      className={classes.buttonOutlined}
                      style={{ textTransform: "none" }}
                    >
                      {t("Add Customer")}
                    </Button>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TabPannel />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default Customers;
