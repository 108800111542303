import { apiUrl } from "../../data/config";

export function getRequestFormsNotifications(data) {
  let url = apiUrl + "/v1/requestFormNotification";
  return fetch(url, {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    if (response.ok) {
      return response.json().then((data) => {
        if (data.status === 200) {
          return data;
        } else if (data.status === "500") {
          return {
            status: "500",
            message: "Something went wrong",
          };
        }
      });
    } else {
      if (response.status === 500) {
        return {
          status: "500",
          message: "Server error",
        };
      } else if (response.status === 401) {
        localStorage.clear();
        return {
          status: "401",
          message: "Unauthorized access",
        };
      } else {
        return {
          status: response.status.toString(),
          message: "Unexpected error",
        };
      }
    }
  }).catch(error => {
    return {
      "status": "500",
      "message": "Network error. Please try again later."
    };
  });
}


export async function updateRequestFormNotification(notificationData) {
  let url = apiUrl + "/v1/requestFormNotification"
  return fetch(url, {
    method: "PUT", 
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(notificationData),
  }).then((response) => {
    if (response.ok) {
      return response.json().then((data) => {
        if (data.status === 200) {
          return data;
        } else if (data.status === "500") {
          return {
            status: "500",
            message: "Something went wrong",
          };
        }
      });
    } else {
      if (response.status === 500) {
        return {
          status: "500",
          message: "Server error",
        };
      } else if (response.status === 401) {
        localStorage.clear();
        return {
          status: "401",
          message: "Unauthorized access",
        };
      } else {
        return {
          status: response.status.toString(),
          message: "Unexpected error",
        };
      }
    }
  }).catch(error => {
    return {
      "status": "500",
      "message": "Network error. Please try again later."
    };
  });
}


export async function markAllNotificationsAsRead(notificationData) {
  let url = apiUrl + "/v1/requestFormNotification/readAll"
  return fetch(url, {
    method: "PUT", 
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(notificationData),
  }).then((response) => {
    if (response.ok) {
      return response.json().then((data) => {
        if (data.status === 200) {
          return data;
        } else if (data.status === "500") {
          return {
            status: "500",
            message: "Something went wrong",
          };
        }
      });
    } else {
      if (response.status === 500) {
        return {
          status: "500",
          message: "Server error",
        };
      } else if (response.status === 401) {
        localStorage.clear();
        return {
          status: "401",
          message: "Unauthorized access",
        };
      } else {
        return {
          status: response.status.toString(),
          message: "Unexpected error",
        };
      }
    }
  }).catch(error => {
    return {
      "status": "500",
      "message": "Network error. Please try again later."
    };
  });
}
