import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Divider,
  Chip,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  styled,
  Popover,
} from "@mui/material";
import {
  Person as AccountIcon,
  Badge as BadgeOutlined,
  Business,
  Phone,
  VerifiedUser,
  AccountTree as Department,
  Draw as SignatureIcon,
  Gavel as StampIcon,
  Close as CloseIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch } from "react-redux";
import useStyles from "./styles";
import { logout } from "../../pages/LoginContainer/LoginState";
import SideDrawer from "../Sidebar/SideDrawer";
import { getRequestFormsNotifications } from "./HeaderState";
import { NotificationBell } from "../Notification/NotificationBell";
import { getFile } from "../../pages/internalForms/InternalFormState";
import toast, { Toaster } from "react-hot-toast";

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    borderRadius: 12,
    boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
    width: 350,
    maxHeight: 500,
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [profileMenu, setProfileMenu] = useState(null);
  const [notificationLists, setNotificationLists] = useState();
  const [notificationStatus, setNotificationStatus] = useState("");
  const [assetDialogOpen, setAssetDialogOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState({ type: "", url: "" });
  const [fetching, setFetching] = useState(false);

  var data = props.data;

  const handleAssetView = (type, assetData) => {
    if (!assetData) return;
    setAssetDialogOpen(true);
    setFetching(true);
    let body = {
      fileName: assetData.fileName || assetData.filename,
      folderName:
        assetData.folderName || assetData.folder || assetData.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          setSelectedAsset({ type, url: response.reference });
          setFetching(false);
        } else {
          toast.message(response.message);
          setFetching(false);
        }
      })
      .catch(() => {
        toast.error("An error occurred while downloading the file.");
        setFetching(false);
      });
  };

  const handleDownload = () => {
    if (!selectedAsset?.url) return;

    try {
      // Open image in new tab
      window.open(selectedAsset.url, "_blank");

      toast.success(`${selectedAsset.type} opened in new tab`);
    } catch (error) {
      console.error("Error opening image:", error);
      toast.error("An error occurred while opening the file");
    }
  };

  const handleNotifyFromChild = (status) => {
    setNotificationStatus(status);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const bodyData = { role: " " };
        if (data && data.getData) {
          bodyData.role = data.getData.roles;
        }
        const response = await getRequestFormsNotifications(bodyData);
        if (response.status === 200) {
          setNotificationLists(response);
        }
      } catch (error) {
        toast.error("Error fetching notifications");
      }
    };

    fetchNotifications();
  }, [data, notificationStatus]);

  const AssetViewDialog = () => (
    <Dialog
      open={assetDialogOpen}
      onClose={() => setAssetDialogOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 1,
        }}
      >
        <Typography variant="h6">
          {selectedAsset.type === "stamp" ? "Company stamp" : "Your signature"}
        </Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            onClick={handleDownload}
            size="small"
            color="primary"
            sx={{
              "&:hover": {
                backgroundColor: "primary.light",
                color:"white"
              },
            }}
            disabled={fetching}
          >
            <DownloadIcon />
          </IconButton>
          <IconButton onClick={() => setAssetDialogOpen(false)} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          {fetching ? (
            <CircularProgress />
          ) : (
            <img
              src={selectedAsset.url}
              alt={selectedAsset.type}
              style={{
                maxWidth: "100%",
                maxHeight: "300px",
                objectFit: "contain",
              }}
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );

  const renderUserInfo = () => {
    if (!data?.getData)
      return (
        <Box sx={{ p: 2, width: 320 }}>
          <Typography
            variant="body2"
            onClick={() => logout(dispatch)}
            sx={{
              color: "error.main",
              cursor: "pointer",
              "&:hover": {
                color: "error.dark",
                fontWeight: 500,
              },
            }}
          >
            Sign out
          </Typography>
        </Box>
      );

    const userData = data.getData;

    return (
      <Box sx={{ p: 2, width: 320 }}>
        <div>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {userData.userName}
          </Typography>
          <Typography variant="body2" color="primary" sx={{ mb: 2 }}>
            {userData.email}
          </Typography>
        </div>

        {/* Add Signature & Stamp section before the sign out button */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body2" sx={{ fontWeight: 500, mb: 1 }}>
            Assets:
          </Typography>

          <Box sx={{ display: "flex", gap: 2, ml: 2 }}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<SignatureIcon />}
              disabled={!userData.signature}
              onClick={() => handleAssetView("signature", userData.signature)}
              sx={{
                textTransform: "none",
                "&.Mui-disabled": {
                  borderColor: "rgba(0, 0, 0, 0.12)",
                },
              }}
            >
              View signature
            </Button>
            <Button
              variant="outlined"
              size="small"
              startIcon={<StampIcon />}
              disabled={!userData.stamp?.filename}
              onClick={() => handleAssetView("stamp", userData.stamp)}
              sx={{
                textTransform: "none",
                "&.Mui-disabled": {
                  borderColor: "rgba(0, 0, 0, 0.12)",
                },
              }}
            >
              View stamp
            </Button>
          </Box>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ mt: 2 }}>
          {/* Roles */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
            <BadgeOutlined color="action" />
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              Roles:
            </Typography>
            <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}>
              {userData.roles.map((role, index) => (
                <Chip
                  key={index}
                  label={role}
                  size="small"
                  sx={{
                    bgcolor: "primary.light",
                    color: "primary.contrastText",
                  }}
                />
              ))}
            </Box>
          </Box>

          {/* Department */}
          {userData?.department[0]?.length > 0 && (
            <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
              <Department color="action" />
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                Department:
              </Typography>
              <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}>
                {userData?.department[0]?.split(",").map((dept, index) => (
                  <Chip
                    key={index}
                    label={dept}
                    size="small"
                    sx={{
                      bgcolor: "success.light",
                      color: "success.contrastText",
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}

          {/* Company Info */}

          {/* Phone */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
            <Phone color="action" />
            <Typography variant="body2">
              {userData.mobile.code} {userData.mobile.number}
            </Typography>
          </Box>

          {/* Verification Status */}
          <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
            <VerifiedUser color="action" />
            <Typography variant="body2">
              Status: {userData.isVerified ? "Verified" : "Unverified"}
            </Typography>
          </Box>

          {/* Certificates */}
          {/* {userData.certificate && userData.certificate.length > 0 && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: 500, mb: 1 }}>
                Certificates:
              </Typography>
              {userData.certificate.map((cert, index) => (
                <Box key={index} sx={{ ml: 3, mb: 1 }}>
                  <Typography
                    variant="body2"
                    sx={{ color: cert.status ? "success.main" : "error.main" }}
                  >
                    {cert.certNumber}
                  </Typography>
                  <Typography variant="caption" display="block">
                    Valid until: {formatDate(cert.lastDate)}
                  </Typography>
                  {!cert.status && (
                    <Typography variant="caption" color="error">
                      {cert.reason}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          )} */}
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box>
          <Typography
            variant="body2"
            onClick={() => logout(dispatch)}
            sx={{
              color: "error.main",
              cursor: "pointer",
              "&:hover": {
                color: "error.dark",
                fontWeight: 500,
              },
            }}
          >
            Sign out
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Toaster />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <SideDrawer />

          <Typography
            variant="h6"
            sx={{ fontWeight: 500 }}
            className={classes.logotype}
          >
            {data && data.getData ? data.getData.customerName : "Binbag CRM"}
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          <NotificationBell
            notifyData={notificationLists}
            sendNotifyToParent={handleNotifyFromChild}
            userRoles={data?.getData?.roles}
          />
          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => setProfileMenu(e.currentTarget)}
            size="large"
          >
            <AccountCircleIcon className={classes.headerIcon} />
          </IconButton>

          <StyledPopover
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            className={classes.headerMenu}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {renderUserInfo()}
          </StyledPopover>
        </Toolbar>
      </AppBar>
      <AssetViewDialog />
    </>
  );
};

export default Header;
