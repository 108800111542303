import React, { useState } from "react";
import { Box, Fab } from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { Toaster } from "react-hot-toast";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import InquiryTable from "./components/InquiryTable";
import InquiryForm from "./components/dialogs/InquiryForm";

const Inquiries = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [refetchTrigger, setRefetchTrigger] = useState(0);

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("Inquiries"), link: "" },
  ];

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmitSuccess = () => {
    setRefetchTrigger((prev) => prev + 1);
    handleClose();
  };

  return (
    <Box sx={{ position: "relative", maxHeight: "100vh", pb: 4, mt: 12 }}>
      <Toaster />
      <BreadCrumbs data={breadcrumb} />

      <Box sx={{ mt: 2, mb: 10 }}>
        <InquiryTable refetchTrigger={refetchTrigger} />
      </Box>

      <Fab
        color="primary"
        variant="extended"
        onClick={handleClickOpen}
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 1000,
        }}
      >
        <AddIcon sx={{ mr: 1 }} />
        Inquiry
      </Fab>

      {open && (
        <InquiryForm
          open={open}
          handleClose={handleClose}
          onSubmitSuccess={handleSubmitSuccess}
        />
      )}
    </Box>
  );
};

export default Inquiries;
