import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Switch,
  IconButton,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const EventDialog = ({ open, onClose, onSave, onDelete, event, slotInfo }) => {
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      title: "",
      description: "",
      start: "",
      end: "",
      color: "#3174ad",
      allDay: false,
      status: "overdue",
    },
  });

  const allDay = watch("allDay");
  const startDate = watch("start");

  const colorOptions = [
    { value: "#3174ad", label: "Blue" },
    { value: "#32a852", label: "Green" },
    { value: "#a87d32", label: "Orange" },
    { value: "#6a5acd", label: "Slate Blue" },
    { value: "#daa520", label: "Goldenrod" },
  ];

  const statusOptions = [
    { value: "overdue", label: "Overdue" },
    { value: "in_progress", label: "In Progress" },
    { value: "completed", label: "Completed" },
    { value: "rejected", label: "Rejected" },
  ];

  useEffect(() => {
    if (event) {
      setValue("title", event.title);
      setValue("start", event.start.toISOString().slice(0, 16));
      setValue("end", event.end.toISOString().slice(0, 16));
      setValue("description", event.description || "");
      setValue("color", event.color || "#3174ad");
      setValue("allDay", event.allDay || false);
      setValue("status", event.status || "overdue");
    } else if (slotInfo) {
      setValue("title", "");
      setValue("start", slotInfo.start.toISOString().slice(0, 16));
      setValue("end", slotInfo.end.toISOString().slice(0, 16));
      setValue("description", "");
      setValue("color", "#3174ad");
      setValue("allDay", false);
      setValue("status", "overdue");
    }
  }, [event, slotInfo, setValue]);

  const onSubmit = (data) => {
    onSave({
      ...data,
      start: new Date(data.start),
      end: new Date(data.end),
    });
  };

  const handleDelete = () => {
    if (event && onDelete) {
      onDelete(event);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle
        sx={{
          backgroundColor: "#f5f5f5",
          borderBottom: "1px solid #e0e0e0",
        }}
      >
        {event ? "Edit event" : "Add event"}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Controller
            name="title"
            control={control}
            rules={{ required: "Title is required!" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                autoFocus
                // margin="normal"
                label="Title"
                type="text"
                fullWidth
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{ required: "Description is required!" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                label="Description"
                type="text"
                fullWidth
                multiline
                minRows={4}
                variant="filled"
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="allDay"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(e) => field.onChange(e.target.checked)}
                    color="primary"
                  />
                }
                label="All day"
              />
            )}
          />

          <Controller
            name="start"
            control={control}
            rules={{ required: "Start date is required" }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                label="Start date"
                type={allDay ? "date" : "datetime-local"}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
          <Controller
            name="end"
            control={control}
            rules={{
              required: "End date is required",
              validate: (value) =>
                new Date(value) > new Date(startDate) ||
                "End date must be later than start date!",
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                label="End date"
                type={allDay ? "date" : "datetime-local"}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />

          {event ? (
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth margin="normal">
                  <FormLabel component="legend">Mark as</FormLabel>
                  <Select {...field}>
                    {statusOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          ) : (
            <></>
          )}

          <FormControl component="fieldset" margin="normal">
            {/* <FormLabel component="legend">Event color</FormLabel> */}
            <Controller
              name="color"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} row>
                  {colorOptions.map((option) => (
                    <FormControlLabel
                      key={option.value}
                      value={option.value}
                      control={<Radio style={{ color: option.value }} />}
                      sx={{ fontSize: "16px" }}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 20px",
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #e0e0e0",
          }}
        >
          {event ? (
            <IconButton
              onClick={handleDelete}
              color="error"
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          ) : (
            <div></div>
          )}
          <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
            <Button
              onClick={onClose}
              color="primary"
              variant="outlined"
              style={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ textTransform: "none" }}
            >
              Save Changes
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EventDialog;
