import React from "react";
import ResponsiveDrawer from "./components/ResponsiveDrawer";
import { Toaster } from "react-hot-toast";

export default function InternalForms() {
  return (
    <div style={{ margin: "0px -30px", height: "100vh",}}>
      <Toaster />
      <ResponsiveDrawer />
    </div>
  );
}
