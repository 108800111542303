export const ADVANCE_PAYMENT_STATUS = {
  PENDING: "Pending",
  APPROVED: "Approved",
  ADVANCE_DISBURSED: "Advance Disbursed",
  SUBMIT_EXPENSE_REPORT: "Submit Expenses",
  REUPLOAD_EXPENSE_REPORT: "Reupload Expense Report",
  EXPENSE_REPORT_SUBMITTED: "Expense Report Submitted",
  EXPENSE_REPORT_DISAPPROVED: "Expense Report Disapproved",
  AMOUNT_PAYABLE_TO_USER: "Payable to User",
  AMOUNT_RECEIVABLE_BY_COMPANY: "Receivable by Company",
  VERIFY_EXPENSE_REPORT: "Verify Expenses",
  EXPENSE_REPORT_VERIFIED: "Expense Report Verified",
  REJECTED: "Rejected",
  SETTLED: "Settled",
};

export const PAYMENT_STATUS = {
  PENDING: "Pending",
  APPROVED: "Approved",
  PARTIAL_PAYMENT: "Partial Payment",
  NEW_PARTIAL_PAYMENT: "New Partial Payment",
  PAYMENT_COMPLETED: "Payment Completed",
  SOLD: "Sold",
  CLOSED: "Closed",
  REJECTED: "Rejected",
};

export const EMD_STATUS = [
  "Pending",
  "Approved",
  "DD Submitted",
  // "DD Returned",
  "DD Cancelled",
  "DD Adjusted",
  "Rejected",
  "Closed",
];

export const TENDER_DOC_STATUS = [
  "Pending",
  "Approved",
  "Payment Completed",
  "Rejected",
  "Closed",
];

export const commonPaymentStatus = [
  "Pending",
  "Approved",
  "Partial Payment",
  "New Partial Payment",
  "Completed",
  "Rejected",
];

export const commonStatus = ["Pending", "Approved", "Completed", "Rejected"];
