import React from "react";
import { AppBar, Paper, Typography, Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import useStyles from "./styles";

export default function BreadCrumbs(props) {
  var classes = useStyles();
  return (
    <AppBar className={classes.breadcrumbs}>
      <Paper className={classes.paper}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.breadCrumbDiv}
          separator={<NavigateNextIcon fontSize="small" />}
        >
          {props.data.map((item, key) => {
            return item.link ? (
              <Link
                color="inherit"
                href={item.link}
                underline="hover"
                key={key}
              >
                {item.label}
              </Link>
            ) : (
              <Typography color="textPrimary" key={key}>
                {item.label}
              </Typography>
            );
          })}
        </Breadcrumbs>
      </Paper>
    </AppBar>
  );
}
