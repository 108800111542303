import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
// import { addCustomer } from "../../../customers/CustomerState";
import { useSelector } from "react-redux";
import {
  searchCustomers,
  getAllCommunicationMethod,
  createInquiry,
} from "../../InquiryState";
import SearchOrAddCustomer from "../SearchOrAddCustomer";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Required"),
    email: yup.string().email("Invalid email"),
    contact: yup
      .string()
      .test(
        "contact-validation",
        "Contact number must be 10 digits",
        function (value) {
          if (!value) return true;
          return /^\d{10}$/.test(value);
        }
      ),
    inquiryType: yup.string().required("Required"),
    inquiryChannel: yup.string().required("Required"),
    shortNotes: yup.string().required("Required"),
  })
  .test(
    "email-or-contact",
    "Either email or contact number is required",
    function (values) {
      if (!values.email && !values.contact) {
        return this.createError({
          path: "email",
          message: "Either email or contact number is required",
        });
      }
      if (values.contact && !/^\d{10}$/.test(values.contact)) {
        return this.createError({
          path: "contact",
          message: "Contact number must be 10 digits",
        });
      }
      return true;
    }
  );

const InquiryForm = ({ open, handleClose, onSubmitSuccess }) => {
  const { t } = useTranslation();
  const usersData = useSelector((state) => state.userdata);
  const [inquiryChannel, setInquiryChannel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      contact: "",
      companyName: "",
      customerId: "",
      branchId: "",
      inquiryType: "",
      shortNotes: "",
      createdBy: usersData?.getData?._id,
      status: "Unassigned",
      bypassRecaptcha: true,
      inquiryChannel: null,
      dateAndTimeOfEmail: new Date().toISOString(),
    },
  });

  const handleSelectCustomer = (customer) => {
    setValue("companyName", customer?.companyName || customer?.inputValue);
    setValue("customerId", customer?.companyId);
    setValue("branchId", customer?.branchId);
  };

  useEffect(() => {
    const fetchInquiryChannels = async () => {
      setIsLoading(true);
      try {
        const response = await getAllCommunicationMethod();
        if (response.status === "200") setInquiryChannel(response.data);
      } catch (error) {
        toast.error("Failed to fetch inquiry channels");
      } finally {
        setIsLoading(false);
      }
    };

    fetchInquiryChannels();
  }, []);

  async function fetchOptions(query) {
    setIsLoading(true);
    try {
      const response = await searchCustomers({ searchTerm: query });
      const options = response.data.flatMap((company) => [
        ...company.branch.map((branch) => ({
          type: "branch",
          branchName: `${branch.address.address1}, ${branch.address.address2}`,
          companyName: company.name,
          companyId: company._id,
          branchId: branch._id,
        })),
      ]);
      return options;
    } catch (error) {
      toast.error("Failed to fetch options");
      return [];
    } finally {
      setIsLoading(false);
    }
  }

  // to add a new cutomer
  // const handleAddNewCustomer = async (newItem) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await addCustomer(newItem);
  //     if (response.status === "200") {
  //       toast.success("Created successfully!");
  //     } else {
  //       toast.error(response?.message || "Something went wrong!");
  //     }
  //   } catch (error) {
  //     toast.error("Failed to add new customer");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (data.companyName === "") toast.error("Please select or add customer");
      else {
        const response = await createInquiry(data);
        if (response.status === "200") {
          onSubmitSuccess();
          toast.success("Successfully created!");
          handleClose();
        } else {
          toast.error("Something went wrong");
        }
      }
    } catch (error) {
      toast.error("Failed to create inquiry");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{t("Create Inquiry")}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SearchOrAddCustomer
              fetchOptions={fetchOptions}
              onSelectCustomer={handleSelectCustomer}
            />
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  label={t("Contact person")}
                  type="text"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  label={t("Contact email")}
                  type="email"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
            <Controller
              name="contact"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  label={t("Contact number")}
                  type="text"
                  fullWidth
                  error={!!errors.contact}
                  helperText={errors.contact?.message}
                />
              )}
            />
            <Controller
              name="inquiryType"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={["Recycling", "EPR", "Marketplace", "Others"]}
                  fullWidth
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Inquiry type")}
                      fullWidth
                      margin="normal"
                      error={!!errors.inquiryType}
                      helperText={errors.inquiryType?.message}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="inquiryChannel"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  options={inquiryChannel}
                  fullWidth
                  getOptionLabel={(option) => option.mode || ""}
                  isOptionEqualToValue={(option, value) => option._id === value}
                  onChange={(_, data) => field.onChange(data ? data._id : null)}
                  value={
                    inquiryChannel.find(
                      (channel) => channel._id === field.value
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Inquiry channel")}
                      fullWidth
                      margin="normal"
                      error={!!errors.inquiryChannel}
                      helperText={errors.inquiryChannel?.message}
                    />
                  )}
                />
              )}
            />
            <Controller
              name="shortNotes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  label={t("What's this inquiry about?")}
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.shortNotes}
                  helperText={errors.shortNotes?.message}
                />
              )}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : t("Submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InquiryForm;
