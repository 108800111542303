import { apiUrl } from "../../data/config";

export function getInquiryLeads() {
  const url = `${apiUrl}/v1/getAllInquiries`;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  };
  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return {
        status: "500",
        message: "Something went wrong",
      };
    }
  });
}

export function getAllCommunicationMethod() {
  const url = `${apiUrl}/v1/getAllCommunicationMethod`;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  };
  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response;
    }
  });
}

// v1/getAllinquiryLeadsStatus

export function getAllinquiryLeadsStatus() {
  const url = `${apiUrl}/v1/getAllInquiryStatus`;
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
  };
  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return {
        status: "500",
        message: "Something went wrong",
      };
    }
  });
}

export function getRecyclerUsersUnderAdmin() {
  const url = `${apiUrl}/v1/customer/adminBelowUser`;
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify({
      referenceId: localStorage.getItem("customerid"),
      type: localStorage.getItem("type"),
    }),
  };

  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return {
        status: "500",
        message: "Something went wrong",
      };
    }
  });
}

export function getAllAssignedLeads(id) {
  // data = data1;
  let url = apiUrl + "/v1/getAllAssiginedInquiries";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(id),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

//this api is to get all the leads that a admin assiged to his user
export function getAllAdminAssiginedLeads(adminId) {
  let url = apiUrl + "/v1/getAllAdminAssiginedInquiries";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(adminId),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}

export function getFile(data) {
  // data = data1;
  let url = apiUrl + "/v1/filegetAWS";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else if (data.status === "500") {
        return {
          status: "500",
          message: "Something went wrong",
        };
      }
    });
  });
}
// {
//   "inquiryLeadId":"64ef8dd2163ce591927642f7",
//   "status":"Field visit",
//   "owner":"64d35075ce998139d03c589e"
//     ie:recycler user id
// }

export function statusUpdate(data) {
  let url = apiUrl + "/v1/inquiry/StatusUpdate";
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(data),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return {
        status: "500",
        message: "Something went wrong!",
      };
    }
  });
}

export function createMprId(data, dispatch) {
  let url = apiUrl + "/v1/customer/MPRLoad";

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    if (response.ok) {
      return response.json().then((data) => {
        if (data.status === "200") {
          dispatch({
            type: "SET_MPR_ID",
            payload: data.MPRID,
          });
          dispatch({
            type: "SET_ADD_MPR_DATA",
            payload: data,
          });
          return data;
        } else if (data.status === "500") {
          dispatch({
            type: "LOG_OUT",
          });
          localStorage.clear();
          return data;
        } else if (data.status === "401") {
          dispatch({
            type: "LOG_OUT",
          });
          localStorage.clear();
          return data;
        }
      });
    }
  });
}

export function getPossibleDuplicateInquiry(data) {
  let url = apiUrl + "/v1/getDuplicateInquiry";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(data),
  }).then((response) => {
    return response.json().then((data) => {
      if (data.status === "200") {
        return data;
      } else {
        return data;
      }
    });
  });
}

export function createInquiry(data) {
  let url = apiUrl + "/v1/insertInquiry";
  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(data),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return {
        status: "500",
        message: "Something went wrong!",
      };
    }
  });
}

export async function searchCustomers(searchTerm) {
  const url = `${apiUrl}/v1/tenant/searchCustomer`;
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "ngrok-skip-browser-warning": true,
      token: localStorage.getItem("token"),
    },
    body: JSON.stringify(searchTerm),
  };



  return fetch(url, requestOptions).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return {
        status: "500",
        message: "Something went wrong",
      };
    }
  });
}

export function updateStatusHisotry(data) {
  let url = apiUrl + "/v1/updateStatusHistory";
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(data),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return {
        status: "500",
        message: "Something went wrong!",
      };
    }
  });
}

export function updateInquiry(data) {
  let url = apiUrl + "/v1/inquiryUpdate";
  return fetch(url, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
      "ngrok-skip-browser-warning": true,
    },
    body: JSON.stringify(data),
  }).then(function (response) {
    if (response.ok) {
      return response.json().then((data) => {
        return data;
      });
    } else {
      return {
        status: "500",
        message: "Something went wrong!",
      };
    }
  });
}
