import React from "react";

import {
  TextField,
  MenuItem,
  FormHelperText,
  InputLabel,
  Select,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import {stateDrop,countryDrop} from '../../../data/config'

import { useTranslation } from "react-i18next";

const FieldData = ({ setForm, formData }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const fields = [
    { label: t("common_email"), name: "email", type: "email" },
    { label: t("common_city"), name: "city", type: "text" },
    { label: t("common_state"), name: "state", type: "dropdown" , data: stateDrop,},
    { label: t("common_country"), name: "country", type: "dropdown" ,data: countryDrop,}
];

  return <>
  
     {// eslint-disable-next-line 
     fields.map((item, key) => {
              switch (item.type) {
                  case "text":
                      return (
                          <Controller
                              key={key}
                              control={control}
                              name={item.name}
                              rules={{ required: item.label + " is required.",minLength:3 }}
                              render={({ field }) => (
                              <TextField
                                  variant="standard"
                                  id="first-name"
                                  label={item.label}
                                  fullWidth
                                //   margin="normal"
                                  {...field}
                                  error={Boolean(errors?.[item.name])}
                                  helperText={errors[item.name]?.type === "required"
                                  ? errors[item.name]?.message
                                  : errors[item.name]?.type === "minLength"
                                  ? item.label + " must have minimum 3 charecters"
                                  : ""} />
                              )}
                          />
                      );
                      case "email":
                      return (
                          <Controller
                              key={key}
                              control={control}
                              name={item.name}
                              rules={{
                              required: item.label + " is required.",
                              pattern:
                                  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                              }}
                              render={({ field }) => (
                              <TextField
                                  variant="standard"
                                  label={item.label}
                                  fullWidth
                                  margin="normal"
                                  {...field}
                                  error={Boolean(errors?.[item.name])}
                                  helperText={
                                  errors[item.name]?.type === "required"
                                      ? errors[item.name]?.message
                                      : errors[item.name]?.type === "pattern"
                                      ? "Invalid email id"
                                      : ""
                                  } />
                              )}
                          />
                      );
                      case "dropdown":
                      return <><InputLabel key={key} style={{marginTop:"10px"}}>{item.label}</InputLabel> 
                      <Controller
                      control={control}
                      name= {item.name}
                      rules={{ required:item.label+" is required." }}
                      render={({ field }) => ( 
                      <Select variant="standard" style={{textAlign:'left'}} {...field} fullWidth>
                          {
                          item.data.map((item,key) => {
                          return( 
                              <MenuItem value={item} key={key}>{item}</MenuItem>
                          )
                          }) 
                          }
                      </Select>
                      )}
                      />
                      <FormHelperText style={{color:'#f44336'}}>{errors[(item.name)]?.message}</FormHelperText></>;
                  default : return <></>
                  }
              })}
  </>;
};

export default FieldData;
