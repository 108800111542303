import { makeStyles } from "@mui/styles";

export default makeStyles(theme => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  content:{
    padding: theme.spacing(2),
    paddingTop: theme.spacing(10),
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(0), 
      paddingTop: theme.spacing(10),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(0), 
      paddingTop: theme.spacing(10),
    },
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonDiv:{
    float: 'right',
    marginTop:theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form:{
    padding: theme.spacing(4),
    marginTop: '6rem',
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(4), 
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(2), 
      paddingBottom : theme.spacing(1), 
    },
  },
  title :{
    font: '400 2.125rem / 1.17647059 Roboto,RobotoDraft,sans-serif',
    fontWeight: '300',
    margin: '16px 0 32px 0',
    textAlign: 'left'
  },
  label:{
      textAlign: 'left',
      marginTop: '2rem',
      marginBottom: '1rem'
  },
  stepper: {
    marginTop:theme.spacing(5)
  },
  paperButton:{
    marginTop:'1rem',
    textAlign:'right',
    padding:'1rem'
  },
  finish:{
    color: '#000',
    textDecoration: 'none',
    "&:hover": {
      textDecoration:'none',
    }
  },
  HeaderContainer:{
    padding:theme.spacing(1.5),
    margin:theme.spacing(1.5),
    justifyContent: "space-between",
    display:'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  tenantHeader:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent:'flex-start'
    },
  },
  tenantSubHeader:{
    paddingTop: "3px",
    marginLeft:"6px",
    [theme.breakpoints.down('sm')]: {
      whiteSpace: 'nowrap', 
      marginBottom:theme.spacing(2)
    },
  },
  tabpanel:{
    backgroundColor: theme.palette.tabBar
  },
  addicon:{
    margin: '16px 0 32px 0',
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  avatarColor:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  deleteicon:{
    color: theme.palette.primary.main,
  },
}));