import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Paper,
  Box,
  Typography,
  Chip,
  Alert,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { createLeaveRequest } from "../employeePortalState";
import EventIcon from "@mui/icons-material/Event";
import SendIcon from "@mui/icons-material/Send";
import toast from "react-hot-toast";

export default function LeaveRequestForm({ toggleView }) {
  const leaveTypes = [
    "Casual Leave",
    "Emergency Leave",
    "Marriage Leave",
    "Other",
  ];
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [reasonForLeave, setReasonForLeave] = useState("");
  const [startDate, setStartDate] = useState(dayjs(new Date()).add(1, "day"));
  const [endDate, setEndDate] = useState(dayjs(new Date()).add(1, "day"));
  const data = useSelector((state) => state.userdata.getData);
  const [loader, setLoader] = useState(false);
  const [weekendWarn, setWeekendWarn] = useState({});
  const [daysDifference, setDaysDifference] = useState(0);
  const [endDateError, setEndDateError] = useState({});

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (date.isBefore(startDate, "day")) {
      setEndDateError({
        isError: true,
        message: "Please select a future date",
      });
    } else if (date.isSame(startDate, "day")) {
      setEndDateError({
        isError: false,
        message: "",
      });
    } else {
      setEndDateError({
        isError: false,
        message: "",
      });
    }
  };

  const checkWeekends = () => {
    const daysOfWeek = [];
    let hasWeekend = false;

    let currentDate = startDate;

    while (
      currentDate.isBefore(endDate) ||
      currentDate.isSame(endDate, "day")
    ) {
      const dayOfWeek = currentDate.day();

      if (dayOfWeek === 0 || dayOfWeek === 6) {
        // 0 is Sunday, 6 is Saturday
        hasWeekend = true;
      }

      daysOfWeek.push({
        date: currentDate.format("YYYY-MM-DD"),
        day: currentDate.format("dddd"),
      });

      currentDate = currentDate.add(1, "day");
    }

    setWeekendWarn({
      hasWeekend,
      message: hasWeekend
        ? "Selected date range includes a Saturday or Sunday"
        : "",
    });

    const timeDifference = endDate.diff(startDate, "day");
    const roundedDaysDifference = Math.round(timeDifference) + 1;

    setDaysDifference(roundedDaysDifference);
  };

  useEffect(() => {
    checkWeekends();
    handleEndDateChange(endDate);
  }, [startDate, endDate]);

  const submitForm = async (event) => {
    event.preventDefault();
    setLoader(true);

    const formData = {
      startDate: startDate.format("DD-MM-YYYY"),
      endDate: endDate.format("DD-MM-YYYY"),
      reasonForLeave,
      selectedLeaveType,
      raisedBy: data?._id,
      noOfDays: daysDifference,
    };

    try {
      await createLeaveRequest(formData);
      toast.success("Successfully submitted!");
      setTimeout(() => {
        toggleView(false);
        setLoader(false);
      }, 2000);
    } catch (error) {
      toast.error("An error occurred! Please try again.");
      setLoader(false);
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        maxWidth: 800,
        mx: "auto",
        p: { xs: 1, md: 4 },
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
        Leave Form
      </Typography>

      <Alert severity="info" sx={{ mb: 3 }}>
        Note: If your leave spans across multiple months, please submit separate
        leave requests for each month.
      </Alert>

      <form onSubmit={submitForm}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="From"
                value={startDate}
                onChange={(e) => setStartDate(e)}
                renderInput={(params) => <TextField {...params} fullWidth />}
                disablePast
                format="DD-MM-YYYY"
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Till"
                value={endDate}
                onChange={handleEndDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={endDateError.isError}
                    helperText={endDateError.message}
                  />
                )}
                disablePast
                minDate={startDate}
                format="DD-MM-YYYY"
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
          </Grid>

          {weekendWarn.hasWeekend && (
            <Grid item xs={12}>
              <Alert severity="warning">
                {weekendWarn.message} - It will be counted as leave
              </Alert>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <EventIcon sx={{ mr: 1, color: "primary.main" }} />
              <Typography variant="body1">
                You are applying leave for:
                <Chip
                  label={`${daysDifference} days`}
                  color="primary"
                  size="small"
                  sx={{ ml: 1 }}
                />
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel id="leavetype-label">Leave Type</InputLabel>
              <Select
                labelId="leavetype-label"
                value={selectedLeaveType}
                onChange={(e) => setSelectedLeaveType(e.target.value)}
                label="Leave Type"
              >
                {leaveTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Reason"
              value={reasonForLeave}
              onChange={(e) => setReasonForLeave(e.target.value)}
              required
              variant="outlined"
              minRows={3}
              multiline
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center", mt: 2 }}>
            <Button
              variant="contained"
              type="submit"
              disabled={endDateError.isError || loader}
              startIcon={loader ? <CircularProgress size={20} /> : <SendIcon />}
              size="large"
              style={{ textTransform: "none" }}
            >
              {loader ? "Submitting..." : "Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}
