import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  DialogActions,
  Alert,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import UploadFilesImages from "../components/UploadFilesImages";
import {
  PAYMENT_STATUS,
  ADVANCE_PAYMENT_STATUS,
  EMD_STATUS,
} from "./config/contants";
import { NumberFormatCustom } from "../../../utils/currencyFormat";
import SaleForm from "./SaleForm";
export const ConfirmationDialog = ({
  open,
  onClose,
  inputStatus,
  title,
  textFieldLabel,
  onConfirm,
  isLoading,
  imageFileName,
}) => {
  const [confirmed, setConfirmed] = useState(false);
  const [approverComments, setApproverComments] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [rejectionReason, setRejectionReason] = useState("");
  const [transactionID, setTransactionID] = useState("");
  const [adminComments, setAdminComments] = useState("");

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [selectedRadioValue, setSelectedRadioValue] = useState(
    ADVANCE_PAYMENT_STATUS.EXPENSE_REPORT_VERIFIED
  );

  const [ddNumber, setDDNumber] = useState("");
  const [supplierPayId, setSupplierPayId] = useState("");

  const [saleData, setSaleData] = useState({});

  const isSaleDataValid = () => {
    if (!saleData) return false;
    return (
      saleData[0]?.soldTo?.trim() !== "" &&
      saleData[0]?.saleDate !== null &&
      saleData[0]?.saleAmount?.trim() !== "" &&
      saleData[0]?.saleInvoiceId?.trim() !== "" &&
      // saleData[0].saleComments?.trim() !== "" &&
      saleData[0]?.attachments?.length > 0
    );
  };
  const handleSaleDataChange = (newSaleData) => {
    setSaleData(newSaleData);
  };

  const handleConfirm = () => {
    setConfirmed(true);
    onConfirm(
      true,
      approverComments,
      rejectionReason,
      transactionID,
      adminComments,
      uploadedFiles,
      amountPaid,
      selectedRadioValue,
      saleData,
      ddNumber,
      supplierPayId
    );
    // handleClose();
  };

  const handleClose = () => {
    setRejectionReason("");
    setApproverComments("");
    setAdminComments("");
    setTransactionID("");
    setAmountPaid("");
    setConfirmed(false);
    onClose();
  };

  const handleInputChange = (setValue) => (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
  };

  const handleUploadedFiles = (files) => {
    const filteredFiles = files.filter((item) => Object.keys(item).length > 0);
    const filesWithoutRef = filteredFiles.map((item) => {
      const { ref, ...newItem } = item;
      return newItem;
    });

    setUploadedFiles(filesWithoutRef);
  };

  const handleRejectionReasonChange = handleInputChange(setRejectionReason);
  const handleAdminComments = handleInputChange(setAdminComments);
  const handleApproverComments = handleInputChange(setApproverComments);
  const handleAmountPaid = handleInputChange(setAmountPaid);
  const handeDDnumber = handleInputChange(setDDNumber);
  const handleSupplierPayId = handleInputChange(setSupplierPayId);

  const handleTransactionIdChange = (e) => {
    const inputValue = e.target.value;
    setTransactionID(inputValue);
  };

  const computeIsDisabled = () => {
    if (inputStatus === PAYMENT_STATUS.SOLD) {
      return !isSaleDataValid();
    }
    if (inputStatus === PAYMENT_STATUS.REJECTED) {
      return rejectionReason.trim().length < 5;
    }
    if (
      inputStatus === PAYMENT_STATUS.NEW_PARTIAL_PAYMENT ||
      inputStatus === ADVANCE_PAYMENT_STATUS.SUBMIT_EXPENSE_REPORT
    ) {
      return amountPaid.trim().length < 1;
    }
    if (inputStatus === "DD Submitted") {
      if (uploadedFiles.length === 0 || ddNumber.trim().length <= 5)
        return true;
    }

    if (inputStatus === "DD Adjusted") {
      return supplierPayId.trim().length < 5;
    }
    return false;
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: { width: "100%", maxWidth: "600px", minHeight: "120px" },
      }}
    >
      <DialogTitle>{`${title} ${inputStatus}`}</DialogTitle>
      {inputStatus === PAYMENT_STATUS.APPROVED && (
        <DialogContent>
          <TextField
            autoFocus
            sx={{ marginTop: "10px", marginBottom: "10px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={approverComments}
            onChange={handleApproverComments}
          />

          <UploadFilesImages
            fileUploadData={handleUploadedFiles}
            setIsUploading={setUploadingFile}
            fileUploadType={imageFileName}
          />
        </DialogContent>
      )}

      {inputStatus === PAYMENT_STATUS.NEW_PARTIAL_PAYMENT && (
        <DialogContent>
          <TextField
            autoFocus
            sx={{ marginTop: "10px", marginBottom: "10px" }}
            label="Amount Paid"
            variant="outlined"
            fullWidth
            value={amountPaid}
            onChange={handleAmountPaid}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          <TextField
            sx={{ marginTop: "10px" }}
            label="Reference ID"
            variant="outlined"
            fullWidth
            multiline
            value={transactionID}
            onChange={handleTransactionIdChange}
          />
          <TextField
            sx={{ marginTop: "16px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={adminComments}
            onChange={handleAdminComments}
          />
          <UploadFilesImages
            fileUploadData={handleUploadedFiles}
            setIsUploading={setUploadingFile}
            fileUploadType={imageFileName}
          />
        </DialogContent>
      )}
      {inputStatus === PAYMENT_STATUS.SOLD && (
        <SaleForm onSaleDataChange={handleSaleDataChange} />
      )}

      {/* todo */}
      {inputStatus === PAYMENT_STATUS.CLOSED && (
        <DialogContent>
          <TextField
            autoFocus
            sx={{ marginTop: "10px", marginBottom: "10px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={adminComments}
            onChange={handleAdminComments}
          />
        </DialogContent>
      )}
      {inputStatus === PAYMENT_STATUS.REJECTED && (
        <DialogContent>
          <TextField
            autoFocus
            sx={{ marginTop: "10px" }}
            label={textFieldLabel}
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            required
            value={rejectionReason}
            onChange={handleRejectionReasonChange}
          />
        </DialogContent>
      )}
      {(inputStatus === PAYMENT_STATUS.PAYMENT_COMPLETED ||
        inputStatus == "Completed" ||
        inputStatus === ADVANCE_PAYMENT_STATUS.ADVANCE_DISBURSED) && (
        <DialogContent>
          <TextField
            autoFocus
            // inputRef={inputReference}
            sx={{ marginTop: "10px" }}
            label="Reference ID"
            variant="outlined"
            fullWidth
            // required
            multiline
            value={transactionID}
            onChange={handleTransactionIdChange}
          />

          <TextField
            sx={{ marginTop: "16px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={adminComments}
            onChange={handleAdminComments}
          />
          <UploadFilesImages
            fileUploadData={handleUploadedFiles}
            setIsUploading={setUploadingFile}
            fileUploadType={imageFileName}
          />
        </DialogContent>
      )}

      {inputStatus === ADVANCE_PAYMENT_STATUS.SUBMIT_EXPENSE_REPORT && (
        <DialogContent>
          <TextField
            autoFocus
            sx={{ marginTop: "10px", marginBottom: "10px" }}
            label="Amount utilized"
            variant="outlined"
            fullWidth
            value={amountPaid}
            onChange={handleAmountPaid}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
          <TextField
            sx={{ marginTop: "16px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={approverComments}
            onChange={handleApproverComments}
          />
          <UploadFilesImages
            fileUploadData={handleUploadedFiles}
            setIsUploading={setUploadingFile}
            fileUploadType={imageFileName}
          />
        </DialogContent>
      )}

      {inputStatus === ADVANCE_PAYMENT_STATUS.SETTLED && (
        <DialogContent>
          <TextField
            sx={{ marginTop: "16px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={approverComments}
            onChange={handleApproverComments}
          />
        </DialogContent>
      )}

      {inputStatus === ADVANCE_PAYMENT_STATUS.VERIFY_EXPENSE_REPORT && (
        <DialogContent>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="expense-report-status-label"
              name="expense-report-status"
              value={selectedRadioValue}
              onChange={(event) => setSelectedRadioValue(event.target.value)}
              defaultValue={selectedRadioValue}
            >
              <FormControlLabel
                value={ADVANCE_PAYMENT_STATUS.EXPENSE_REPORT_VERIFIED}
                control={<Radio />}
                label={ADVANCE_PAYMENT_STATUS.EXPENSE_REPORT_VERIFIED}
              />
              <FormControlLabel
                value={ADVANCE_PAYMENT_STATUS.REUPLOAD_EXPENSE_REPORT}
                control={<Radio />}
                label={ADVANCE_PAYMENT_STATUS.REUPLOAD_EXPENSE_REPORT}
              />
            </RadioGroup>
          </FormControl>

          <TextField
            sx={{ marginTop: "16px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={approverComments}
            onChange={handleApproverComments}
          />
        </DialogContent>
      )}

      {/* for dd */}

      {inputStatus === "DD Submitted" && (
        <DialogContent>
          <Alert severity="info">
            Upload a clear image of dd and its number printed.
          </Alert>
          <TextField
            sx={{ marginTop: "16px" }}
            label="DD Number"
            variant="outlined"
            fullWidth
            value={ddNumber}
            onChange={handeDDnumber}
            required
          />
          <UploadFilesImages
            fileUploadData={handleUploadedFiles}
            setIsUploading={setUploadingFile}
            fileUploadType={imageFileName}
            title={"Upload dd*"}
          />
          <TextField
            sx={{ marginTop: "16px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={approverComments}
            onChange={handleApproverComments}
          />
        </DialogContent>
      )}

      {inputStatus === "DD Returned" && (
        <DialogContent>
          <TextField
            sx={{ marginTop: "16px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={approverComments}
            onChange={handleApproverComments}
          />
        </DialogContent>
      )}

      {inputStatus === "DD Cancelled" && (
        <DialogContent>
          <TextField
            sx={{ marginTop: "16px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={approverComments}
            onChange={handleApproverComments}
          />
        </DialogContent>
      )}

      {inputStatus === "DD Adjusted" && (
        <DialogContent>
          <Alert severity="info">
            Include the supplier payment id of the party with whom this DD is
            adjusted. If the supplier payment ID is not available, please create
            a new supplier payment request.
          </Alert>
          <TextField
            sx={{ marginTop: "16px" }}
            label="Supplier payment id"
            variant="outlined"
            fullWidth
            value={supplierPayId}
            onChange={handleSupplierPayId}
            required
            placeholder="eg: PR-2024-0000"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            sx={{ marginTop: "16px" }}
            label="Comments"
            variant="outlined"
            fullWidth
            minRows={5}
            multiline
            value={approverComments}
            onChange={handleApproverComments}
          />
        </DialogContent>
      )}

      <DialogActions>
        <div
          style={{
            padding: "16px",
            paddingTop: "0px",
            display: "flex",
            justifyContent: "flex-end",
            gap: 10,
          }}
        >
          <Button
            onClick={handleClose}
            color="secondary"
            variant="outlined"
            disabled={isLoading}
          >
            Cancel
          </Button>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              disabled={computeIsDisabled()}
            >
              Confirm
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};
