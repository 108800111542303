import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { makeStyles } from "@mui/styles";

// Import icons
import ContactsIcon from "@mui/icons-material/Contacts";
import SettingsIcon from "@mui/icons-material/Settings";
import BusinessIcon from "@mui/icons-material/Business";
import DetailsIcon from "@mui/icons-material/Details";
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3),
    },
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.3s, box-shadow 0.3s",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    "&:hover": {
      // transform: "translateY(-5px)",
      boxShadow: theme.shadows[4],
    },
  },
  cardContent: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  icon: {
    fontSize: 30,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  searchBar: {
    marginBottom: theme.spacing(3),
    width: "100%",
    maxWidth: 500,
  },
  chipContainer: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const DashboardItem = ({ title, link, icon: Icon }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    history.push(link.replace("/#", ""));
  };

  return (
    <Paper
      className={classes.card}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
      elevation={1}
    >
      <div className={classes.cardContent}>
        <Icon className={classes.icon} />
        <Typography variant="h6" component="h2" gutterBottom>
          {title}
        </Typography>
      </div>
    </Paper>
  );
};

export default function Index() {
  const classes = useStyles();
  const { t } = useTranslation();
  const data = useSelector((state) => state.userdata.getData);

  const breadcrumb = [
    { label: t("common_home"), link: "/#/app/dashboard" },
    { label: t("common_admin"), link: "" },
  ];

  const getStructure = () => {
    if (data && data.type === "tenant") {
      return [
        {
          title: t("Employees & Roles"),
          link: "/#/app/Recyclercontacts",
          icon: BadgeOutlinedIcon,
        },
        {
          title: t("Certificates"),
          link: "/#/app/setting",
          icon: FactCheckOutlinedIcon,
        },
      ];
    }
    if (
      data &&
      data.type === "Customer" &&
      data.roles.includes("Customer_Admin")
    ) {
      return [
        {
          title: t("common_branch"),
          link: "/#/app/customerBranch",
          icon: BusinessIcon,
        },
        {
          title: t("settings_details"),
          link: "/#/app/details",
          icon: DetailsIcon,
        },
      ];
    }
    return [];
  };

  const structure = getStructure();

  return (
    <div className={classes.root} style={{ marginTop: "100px" }}>
      <BreadCrumbs data={breadcrumb} />
      <Grid container spacing={{ xs: 2, sm: 3, md: 4, lg: 5 }}>
        {structure.map((item, key) => (
          <Grid item xs={6} sm={6} md={4} lg={3} key={key}>
            <DashboardItem {...item} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
