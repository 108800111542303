import React, { useState } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  TextField,
  Link,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useStyles from "../login/styles";
import { passwordSet } from "../LoginState";
import toast, { Toaster } from "react-hot-toast";

function PasswordSet(props) {
  var classes = useStyles();
  var [isLoading, setIsLoading] = useState(false);
  var [loginValue, setLoginValue] = useState(props.email);
  var [passwordValue, setPasswordValue] = useState("");
  var [confirmValue, setConfirmValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  var [showButton, setShowButton] = useState(false);
  const [showError, setshowError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [confirmError, setConfirmError] = useState(false);
  const history = useHistory();

  const confirmPassword = (val) => {
    const isActive = hasSpecialCharacter(val);
    setConfirmValue(val);
    if (val.length > 8 && isActive) {
      if (val === passwordValue) {
        setConfirmError(false);
        setShowButton(true);
      } else {
        setShowButton(false);
        setConfirmError(true);
      }
    } else {
      setShowButton(false);
      setConfirmError(true);
    }
  };

  const handleSubmit = () => {
    setshowError(false);
    setIsLoading(true);
    passwordSet(props.email, passwordValue).then((response) => {
      if (response.status === "200") {
        setIsLoading(false);
        setLoginValue("");
        setPasswordValue("");
        setConfirmValue("");
        toast.success(response.message);
        setTimeout(() => history.push("/login"), 2000);
      } else {
        setIsLoading(false);
        toast.error(response.message);
      }
    });
  };

  function hasSpecialCharacter(password) {
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
    return specialCharacterRegex.test(password);
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const HandlePasswordValue = (value) => {
    const isActive = hasSpecialCharacter(value);
    setPasswordValue(value);
    setConfirmValue("");
    setshowError(false);
    if ((value.length > 0 && value.length < 8) || !isActive) {
      setshowError(true);
      setDisabled(true);
      setShowButton(false);
    } else if (value.length > 8 && isActive) {
      setTimeout(() => {
        setshowError(false);
        setDisabled(false);
        if (confirmValue === "") {
          setShowButton(false);
        }
      }, 500);
    } else {
      setshowError(false);
      setShowButton(false);
    }
  };

  return (
    <>
      <Toaster />
      <Typography variant="h5" mb={1} fontWeight={"bold"} color={"primary"}>
        Set new password to access CRM
      </Typography>
      <Typography variant="subtitle1" mb={1} fontSize={"14px"} color={"gray"}>
        Password should be atleast 8 characters in length and one special
        character.
      </Typography>
      <>
        <TextField
          variant="standard"
          id="password"
          label="Password"
          InputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
            endAdornment: (
              <InputAdornment position="end" style={{ marginRight: "0.5rem" }}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                  disabled={passwordValue.length === 0}
                >
                  {showPassword ? (
                    <Visibility className={classes.iconButton} />
                  ) : (
                    <VisibilityOff className={classes.iconButton} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={passwordValue}
          onChange={(e) => HandlePasswordValue(e.target.value)}
          margin="normal"
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          fullWidth
          required
        />
        {showError && (
          <Typography color="secondary" fontSize={"14px"}>
            {passwordValue.length < 8
              ? "Password should be 8 characters in length."
              : "Password should contain at least one special character"}
          </Typography>
        )}
        <TextField
          variant="standard"
          disabled={disabled}
          id="confirm password"
          label="Confirm password"
          InputProps={{
            classes: {
              underline: classes.textFieldUnderline,
              input: classes.textField,
            },
            endAdornment: (
              <InputAdornment position="end" style={{ marginRight: "0.5rem" }}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleToggleConfirmPasswordVisibility}
                  edge="end"
                  disabled={confirmValue.length === 0}
                >
                  {showConfirmPassword ? (
                    <Visibility className={classes.iconButton} />
                  ) : (
                    <VisibilityOff className={classes.iconButton} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={confirmValue}
          onChange={(e) => confirmPassword(e.target.value)}
          margin="normal"
          placeholder="Confirm password"
          type={showConfirmPassword ? "text" : "password"}
          fullWidth
        />
        {confirmError && (
          <Typography color="secondary" fontSize={"14px"}>
            Password and Confirm Password does not match.
          </Typography>
        )}
        <div className={classes.formButtons}>
          <Button
            disabled={showButton === false || isLoading}
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            size="large"
            fullWidth
          >
            {!isLoading ? (
              "Submit"
            ) : (
              <CircularProgress size={26} className={classes.loginLoader} />
            )}
          </Button>
        </div>
        <div style={{ marginTop: "10px", textAlign: "center" }}>
          <Link
            color="primary"
            size="small"
            onClick={() => history.replace("/login")}
            style={{ cursor: "pointer", marginTop: "10px" }}
            underline="hover"
          >
            <Typography variant="p" fontSize={"14px"} textAlign={"center"}>
              Back to Login
            </Typography>
          </Link>
        </div>
      </>
    </>
  );
}


export default PasswordSet;
