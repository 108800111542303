import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card, Tabs, Tab } from "@mui/material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getInquiryLeads,
  getAllAssignedLeads,
  getAllAdminAssiginedLeads,
} from "../InquiryState";
import useStyles from "../styles";
import Table from "../../../components/Table/Table";
import TabPanel, {
  tableOptions,
  a11yProps,
  inquiryTableHeader,
  tabdata1,
  tabdata2,
  tabdata3,
} from "../helper/tableConfig";
import toast, { Toaster } from "react-hot-toast";
import { getStatusColor } from "../helper/utils";
export default function InquiryTable({ refetchTrigger }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [tabData, setTabData] = useState([...tabdata3, ...tabdata2]);
  const [inquiryLeads, setInquiryLeads] = useState([]);
  const [adminAssignedLeads, setAdminAssignedLeads] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const [pageData, setPageData] = useState({ page: 1, limit: 10 });
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPageOptions, setRowsPerPageOptions] = useState([10, 20, 50]);

  const editUnclassifiedLead = (data) => {
    dispatch({
      type: "SET_SELECTED_INQUIRY",
      payload: data,
    });

    history.push("/app/updateInquiry");
  };

  const handleViewDuplicate = (data) => {
    dispatch({
      type: "SET_SELECTED_DUPlICATE",
      payload: data,
    });
    history.push("/app/duplicateInquiries");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem("TabValue", newValue);
  };

  var data = useSelector((state) => state.userdata);
  let isRUser = "";
  let isAuser = "";

  isRUser =
    data &&
    data.getData &&
    data.getData.type === "tenant" &&
    data.getData.roles.includes("user");

  isAuser =
    data &&
    data.getData &&
    data.getData.type === "tenant" &&
    !data.getData.roles.includes("user");

  const fetchInquiryLeads = async () => {
    try {
      setShowLoader(true);
      const response = await getInquiryLeads();
      if (response.status === "200") {
        setInquiryLeads(response.data);
        setShowLoader(false);
      } else if (response.status === "401") {
        setShowLoader(false);

        toast.error(response.message);
      }
    } catch (error) {
      setShowLoader(false);
      toast.error(error?.message);
      // console.error("API Error:", error);
    }
  };

  const fetchAllAdminAssiginedLeads = async (adminId) => {
    try {
      const response = await getAllAdminAssiginedLeads(adminId);
      if (response.status === "200") {
        setAdminAssignedLeads(response.data);
      }
    } catch (error) {}
  };

  const fetchAssignedInquiryLeadsToUser = async (data) => {
    try {
      setShowLoader(true);
      const response = await getAllAssignedLeads(data);
      if (response.status === "200") {
        setInquiryLeads(response.data);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (isAuser) {
      setTabData([...tabdata1, ...tabdata2]);
      fetchInquiryLeads();
      fetchAllAdminAssiginedLeads({ adminId: data?.getData?._id });
    } else if (isRUser) {
      fetchAssignedInquiryLeadsToUser({
        ownerId: data?.getData?._id,
      });
    } else {
      return;
    }
  }, [isAuser, isRUser, refetchTrigger]);

  const filteredData = tabData.map((tab) => {
    if (isAuser) {
      if (tab.label === "All Inquiries") {
        return inquiryLeads;
      } else if (tab.label === "Unassigned") {
        return inquiryLeads.filter((item) => {
          // here return in a sorted way
          return item.status === tab.label;
        });
      } else {
        return adminAssignedLeads.filter((item) => {
          return item.status === tab.label;
        });
      }
    } else if (tab.label === "All Inquiries") {
      return inquiryLeads;
    } else {
      return inquiryLeads.filter((item) => {
        return item.status === tab.label;
      });
    }
  });

  const formatDateAndTime = (dateTime) => {
    return moment(dateTime).format("DD/MM/YYYY");
  };

  const handleRowClick = useCallback(
    (rowData) => {
      const selectedId = rowData[0];
      const filteredData = inquiryLeads?.find(
        (inquiryReq) => inquiryReq?.inquiryID === selectedId
      );
      if (filteredData.duplicateStatus?.isDuplicate) {
        handleViewDuplicate(filteredData?._id);
      } else {
        editUnclassifiedLead(filteredData);
      }
    },
    [inquiryLeads]
  );

  const inquiryDataMapping = (dataItem) => [
    dataItem?.inquiryID ? dataItem?.inquiryID : "N/A",
    dataItem?.dateAndTimeOfEmail
      ? formatDateAndTime(dataItem.dateAndTimeOfEmail)
      : "N/A",
    dataItem?.name ? dataItem.name : "N/A",
    dataItem?.companyName ? dataItem.companyName : "N/A",
    dataItem?.contact
      ? dataItem.contact
      : dataItem.email
        ? dataItem.email
        : "N/A",

    dataItem?.inquiryType ? dataItem.inquiryType : "N/A",
    dataItem?.status ? (
      <span style={{ color: getStatusColor(dataItem.status) }}>
        {dataItem.status}
      </span>
    ) : (
      "N/A"
    ),
    isAuser && dataItem.statusHistory[0]?.owner[0]?.userName
      ? dataItem.statusHistory[0].owner[0].userName
      : "-----",
    dataItem?.inquiryChannel
      ? Array.isArray(dataItem.inquiryChannel)
        ? dataItem.inquiryChannel.join(", ")
        : dataItem.inquiryChannel
      : dataItem.isAutomated
        ? "Email"
        : "Website",
  ];

  return (
    <>
      <Toaster />
      <div style={{ height: "100%" }}>
        <Card>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs"
          >
            {tabData.map((item, key) => {
              return (
                <Tab
                  label={t(item.label)}
                  {...a11yProps(key)}
                  classes={{ root: classes.tab }}
                  key={key}
                />
              );
            })}
          </Tabs>
          {tabData?.map((item, key) => {
            return (
              <TabPanel value={value} index={key} key={item.index}>
                <Table
                  data={filteredData[value].map((dataItem) =>
                    inquiryDataMapping(dataItem)
                  )}
                  header={inquiryTableHeader}
                  name={t(item.name)}
                  options={{
                    ...tableOptions(
                      showLoader,
                      rowsPerPageOptions,
                      setPageData,
                      handleRowClick,
                      totalPages,
                      pageData.page
                    ),
                    tableBodyHeight: "550px",
                    sortOrder: {
                      name: "Date",
                      direction: "desc",
                    },
                  }}
                />
              </TabPanel>
            );
          })}
        </Card>
      </div>
    </>
  );
}
