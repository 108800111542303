import React, { useState } from "react";
import {
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Divider,
  Box,
  styled,
  Tabs,
  Tab,
  Badge,
} from "@mui/material";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { useHistory } from "react-router-dom";
import {
  markAllNotificationsAsRead,
  updateRequestFormNotification,
} from "../Header/HeaderState";
import toast from "react-hot-toast";

const StyledPopover = styled(Popover)(({ theme }) => ({
  "& .MuiPopover-paper": {
    borderRadius: 12,
    boxShadow: "0 4px 20px 0 rgba(0,0,0,0.12)",
    width: 350,
    maxHeight: 500,
  },
}));

const NotificationHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const NotificationList = styled(List)(({ theme }) => ({
  maxHeight: 400,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "0.4em",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    borderRadius: 4,
  },
}));

const NotificationItem = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const NotificationButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  textAlign: "left",
  width: "100%",
  justifyContent: "flex-start",
  padding: theme.spacing(1, 2),
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));


export const NotificationBell = ({
  notifyData,
  sendNotifyToParent,
  userRoles,
}) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const sendNofticationUpdateToParent = () => {
    sendNotifyToParent(Date.now());
  };

  const filterNotifications = (type) => {
    return (
      notifyData?.data?.filter((notification) =>
        type === "leave"
          ? notification.type === "leaveRequest"
          : notification.type !== "leaveRequest"
      ) || []
    );
  };

  const updateAndGoToNotification = (notification) => {
    const notificationData = {
      notificationId: notification._id,
    };
    updateRequestFormNotification(notificationData)
      .then((response) => {
        if (response.status === 200) {
          sendNofticationUpdateToParent();
        }
        handleClose();
      })
      .catch((error) => {
        console.error("Error updating notification:", error);
      });

    // Check if the notification type is for leave requests
    if (notification.type === "leaveRequest") {
      history.push("/app/employeePortal");
    } else {
      history.push("/app/payments?tab=" + notification.type);
    }
  };


  const markNotificationsAsRead = () => {
    const updateData = {
      role: userRoles,
    };
    markAllNotificationsAsRead(updateData)
      .then((response) => {
        if (response.status === 200) {
          sendNofticationUpdateToParent();
        }
        handleClose();
      })
      .catch(() => {
        toast.error("Error updating notification!");
      });
  };

  return (
    <div>
      <IconButton
        aria-describedby={id}
        color="inherit"
        size="large"
        onClick={handleClick}
      >
        <Badge badgeContent={notifyData?.data?.length} color="error">
          <NotificationsOutlinedIcon />
        </Badge>
      </IconButton>

      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <NotificationHeader>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" fontWeight="medium">
              Notifications
            </Typography>
            <Button
              size="small"
              onClick={markNotificationsAsRead}
              disabled={!notifyData?.data?.length}
              style={{ textTransform: "none" }}
            >
              Read all
            </Button>
          </Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="notification tabs"
          >
            <Tab label="Payments" style={{textTransform:"none"}}/>
            <Tab label="Leaves" style={{textTransform:"none"}}/>
          </Tabs>
        </NotificationHeader>
        <NotificationList>
          {tabValue === 1 ? (
            filterNotifications("leave").length === 0 ? (
              <NotificationItem>
                <Typography variant="body1" color="textSecondary">
                  No new leave notifications
                </Typography>
              </NotificationItem>
            ) : (
              filterNotifications("leave").map((notification) => (
                <React.Fragment key={notification?._id}>
                  <NotificationItem>
                    <NotificationButton
                      onClick={() => updateAndGoToNotification(notification)}
                    >
                      <ListItemText
                        primary={notification?.message}
                        primaryTypographyProps={{ variant: "body2" }}
                      />
                    </NotificationButton>
                  </NotificationItem>
                  <Divider />
                </React.Fragment>
              ))
            )
          ) : filterNotifications("payments").length === 0 ? (
            <NotificationItem>
              <Typography variant="body1" color="textSecondary">
                No new payment notifications
              </Typography>
            </NotificationItem>
          ) : (
            filterNotifications("payments").map((notification) => (
              <React.Fragment key={notification?._id}>
                <NotificationItem>
                  <NotificationButton
                    onClick={() => updateAndGoToNotification(notification)}
                  >
                    <ListItemText
                      primary={notification?.message}
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                  </NotificationButton>
                </NotificationItem>
                <Divider />
              </React.Fragment>
            ))
          )}
        </NotificationList>
      </StyledPopover>
    </div>
  );
};
