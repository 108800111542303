import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Card,
  Typography,
  Button,
  Tooltip,
  CircularProgress,
  IconButton,
  Box,
} from "@mui/material";
import {
  DeleteOutline,
  ArrowBack,
  AssignmentInd,
  Refresh,
} from "@mui/icons-material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ClipboardCopy from "./ClipboardCopy";
import useStyles from "../styles";
import ConvertToMpr from "./dialogs/ConvertToMpr";

const InquiryHeader = ({
  inquiryLeadData,
  isAdmin,
  handleUserReAssignOpen,
  handleUserAssignOpen,
  handleConfirmDialogOpen,
  showLoader,
  status,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [openConvertDialog, setOpenConvertDialog] = useState(false);
  const assignedUser = inquiryLeadData[0]?.statusHistory[0]?.owner[0];
  const inquiryID = inquiryLeadData[0]?.inquiryID;

  const BackButton = () => (
    <IconButton onClick={() => history.push("/app/inquiries")}>
      <ArrowBack />
    </IconButton>
  );

  const AdminActions = () => (
    <Grid container spacing={1}>
      {assignedUser ? (
        <>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              startIcon={<Refresh />}
              onClick={handleUserReAssignOpen}
              color="primary"
              className={classes.actionButton}
              fullWidth
            >
              {t("common_reassign")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              startIcon={<LocalShippingIcon />}
              onClick={() => setOpenConvertDialog(true)}
              color="primary"
              className={classes.actionButton}
              fullWidth
            >
              {t("Convert to MPR")}
            </Button>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              startIcon={<AssignmentInd />}
              onClick={handleUserAssignOpen}
              color="primary"
              className={classes.actionButton}
              fullWidth
            >
              {t("common_assign")}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Tooltip title={t("Move to trash")}>
              <Button
                variant="outlined"
                onClick={handleConfirmDialogOpen}
                color="secondary"
                className={classes.deleteButton}
                disabled={status === "Rejected"}
                aria-label={t("Move to trash")}
                fullWidth
                sx={{ ml: 1 }}
              >
                {showLoader ? (
                  <CircularProgress size={22} color="secondary" />
                ) : (
                  <>
                    <DeleteOutline sx={{ mr: 1 }} /> Move to trash
                  </>
                )}
              </Button>
            </Tooltip>
          </Grid>
        </>
      )}
    </Grid>
  );

  const UserActions = () => (
    <Button
      variant="outlined"
      startIcon={<LocalShippingIcon />}
      onClick={() => setOpenConvertDialog(true)}
      color="primary"
      className={classes.actionButton}
      // fullWidth
    >
      {t("Convert to MPR")}
    </Button>
  );

  return (
    <>
      <Card className={classes.headerCard}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={8}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <BackButton />
                <Typography
                  variant="h6"
                  noWrap
                  sx={{ flexGrow: 1, minWidth: 0 }}
                >
                  {/* {inquiryLeadData[0]?.name ? inquiryLeadData[0]?.name : ""} - */}

                  {inquiryLeadData[0]?.companyName
                    ? inquiryLeadData[0]?.companyName
                    : ""}
                </Typography>
              </Box>
              <ClipboardCopy copyText={inquiryID} />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={classes.actionContainer}>
              {isAdmin ? <AdminActions /> : <UserActions />}
            </Box>
          </Grid>
        </Grid>
      </Card>
      <ConvertToMpr
        open={openConvertDialog}
        onClose={() => setOpenConvertDialog(false)}
        data={inquiryLeadData[0]}
      />
    </>
  );
};

export default InquiryHeader;
