import React, { useState } from "react";
import Table from "../../../components/Table/Table";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import { Box, CircularProgress } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DescriptionIcon from "@mui/icons-material/Description";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { getFile, updateStatusHisotry } from "../InquiryState";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import { CloudDownload, Edit } from "@mui/icons-material";
import toast, { Toaster } from "react-hot-toast";
import { statusHistoryHeader } from "../helper/tableConfig";
import { formatDateAndTime } from "../helper/utils";
import { useSelector } from "react-redux";
import UploadImages from "./UploadImages";
import { CustomWidthTooltip } from "../helper/utils";
import ReadMoreText from "./ReadMoreText";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box py={3}>{children}</Box>}
    </div>
  );
}

const InquiryHistory = (inquiryData) => {
  var classes = useStyles();
  const { t } = useTranslation();

  var usersData = useSelector((state) => state.userdata);

  var finalInquiryData = [];

  const [open, setOpen] = useState(false);
  const [editedText, setEditedText] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [tooltipStates, setTooltipStates] = useState([]);
  const [loader, setloader] = useState(false);

  const toggleTooltipState = (index) => {
    const newTooltipStates = [...tooltipStates];
    newTooltipStates[index] = !newTooltipStates[index];
    setTooltipStates(newTooltipStates);
  };
  let statusHistory = inquiryData?.inquiryData?.statusHistory;

  const handleDownload = (file) => {
    let body = {
      fileName: file.fileName || file.filename,
      folderName: file.folderName || file.folder || file.FolderName,
      expireLimt: 1000,
    };
    getFile(body)
      .then((response) => {
        if (response.status === "200") {
          window.open(response.reference, "_blank");
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error("An error occurred while downloading the file.");
      });
  };

  function generateTooltipContent(status, item, index) {
    let content = "N/A";
    if (item?.statusForm === item?.statusTo && !item?.isSystemGenerated)
      return (content = "Owner Reassigned");
    if (status === undefined) {
      return (content = item?.notes);
    }
    if (status === "Assigned") {
      content = item?.notes || "N/A";
    } else if (status === "Addressed") {
      content = item?.notes || item.modeOfAddressing?.notes;
    } else if (status === "Awaiting Response") {
      content = item?.notes || item.awaitingResponse?.notes;
    } else if (status === "Followed Up") {
      content = item?.notes || item.modeOfFollowup?.notes;
    } else if (status === "Followup Requested") {
      content = item?.notes || item.followupRequested?.notes;
    } else if (status === "Field Visit Planned") {
      content = item?.notes || item.fieldVisitPlanned?.notes;
    } else if (status === "Field Visit Completed") {
      content = item?.notes || item.fieldVisitCompleted.feedback;
    } else if (status === "Rejected") {
      content = item?.notes || item.rejected?.notes;
    }

    return <ReadMoreText text={content || "N/A"} />;
  }
  function renderAdditionalInfo(status, item) {
    if (status === "Addressed") {
      return (
        <div>
          {item?.modeOfAddressing?.addressing?.length != 0 ? (
            <>
              Mode of contact:{" "}
              {item?.modeOfAddressing?.addressing
                ?.map((addr) => addr.mode)
                ?.join(", ")}
            </>
          ) : (
            "N/A"
          )}
        </div>
      );
    } else if (status === "Followed Up") {
      return (
        <div>
          {item?.modeOfFollowup?.followup?.length != 0 ? (
            <>
              Mode of contact:{" "}
              {item.modeOfFollowup.followup
                ?.map((addr) => addr.mode)
                ?.join(", ")}
            </>
          ) : (
            "N/A"
          )}
        </div>
      );
    } else if (status === "Field Visit Planned") {
      return (
        <div>
          Scheduled Date:{" "}
          {formatDateAndTime(item.fieldVisitPlanned.dateAndTime)}
        </div>
      );
    } else if (status === "Field Visit Completed") {
      return (
        <div>
          <div>
            Completed Date:{" "}
            {formatDateAndTime(item.fieldVisitCompleted.dateAndTime)}
          </div>
          <Grid container style={{ marginLeft: "-12px" }}>
            {item.fieldVisitCompleted.photos?.length > 0 ? (
              item.fieldVisitCompleted.photos.map((photo, index) => (
                <Grid item key={index}>
                  <IconButton
                    className={classes.deleteicon}
                    onClick={() => handleDownload(photo)}
                    size="large"
                  >
                    <CloudDownload />
                  </IconButton>
                </Grid>
              ))
            ) : (
              <Grid item>
                <div style={{ marginLeft: "12px" }}>No Images uploaded</div>
              </Grid>
            )}
          </Grid>
        </div>
      );
    } else if (status === "Quotation Sent") {
      return (
        <div>
          Attached MPR ID: {inquiryData?.inquiryData?.materialRequestID}
        </div>
      );
    } else {
      return "N/A";
    }
  }
  // add a dialog box
  const handleClickOpen = (item) => {
    setSelectedItem(item);
    if (item.statusTo === "Addressed") {
      setEditedText(item.modeOfAddressing.notes);
    } else if (item.statusTo === "Followed Up") {
      setEditedText(item.modeOfFollowup.notes);
    } else if (item.statusTo === "Field Visit Planned") {
      setEditedText(item.fieldVisitPlanned.notes);
      setSelectedDateTime(moment(item.fieldVisitPlanned.dateAndTime));
    } else if (item.statusTo === "Field Visit Completed") {
      setEditedText(item.fieldVisitCompleted.feedback);
      setSelectedDateTime(moment(item.fieldVisitCompleted.dateAndTime));
    } else if (item.statusTo === "Rejected") {
      setEditedText(item.rejected.notes);
    }
    setOpen(true);
  };

  const handleDateTimeChange = (newDateTime) => {
    setSelectedDateTime(newDateTime);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = async () => {
    setloader(true);
    let updatedItem = { ...selectedItem };
    let body = {
      id: selectedItem._id,
    };

    if (selectedItem.statusTo === "Addressed") {
      updatedItem.modeOfAddressing.notes = editedText;
      body.modeOfAddressing = {
        notes: editedText,
        addressing: selectedItem?.modeOfAddressing?.addressing,
      };
    } else if (selectedItem.statusTo === "Followed Up") {
      updatedItem.modeOfFollowup.notes = editedText;
      body.modeOfFollowup = {
        notes: editedText,
        followup: selectedItem?.modeOfFollowup?.followup,
      };
    } else if (selectedItem.statusTo === "Field Visit Planned") {
      updatedItem.fieldVisitPlanned.notes = editedText;
      body.fieldVisitPlanned = {
        notes: editedText,
        dateAndTime: selectedDateTime.toISOString(),
      };
    } else if (selectedItem.statusTo === "Field Visit Completed") {
      updatedItem.fieldVisitCompleted.feedback = editedText;

      let photos = [];

      if (
        selectedItem.fieldVisitCompleted &&
        selectedItem.fieldVisitCompleted.photos
      ) {
        photos = [...selectedItem.fieldVisitCompleted.photos];
      }

      // Add new uploaded images
      if (uploadedImages && uploadedImages.length > 0) {
        uploadedImages.forEach((image) => {
          photos.push({
            fileName: image.fileName,
            folderName: image.folderName,
          });
        });
      }

      body.fieldVisitCompleted = {
        feedback: editedText,
        dateAndTime: selectedDateTime.toISOString(),
        photos: photos,
      };
    } else if (selectedItem.statusTo === "Rejected") {
      updatedItem.rejected.notes = editedText;
      body.rejected = updatedItem.rejected;
    }

    try {
      const response = await updateStatusHisotry(body);
      // const response = body

      if (response.status === "200") {
        toast.success("Notes updated successfully");
        const updatedStatusHistory = statusHistory.map((item) =>
          item.updatedAt === selectedItem.updatedAt ? updatedItem : item
        );
        inquiryData.inquiryData.statusHistory = updatedStatusHistory;
        setloader(false);
      } else {
        toast.error("Failed to update notes");
        setloader(false);
      }
    } catch (error) {
      toast.error("An error occurred while updating notes");
      setloader(false);
    }
    handleClose();
  };
  const handleEditClick = (item) => {
    handleClickOpen(item);
  };

  const handleImagesUploaded = (imagesData) => {
    setUploadedImages(imagesData);
  };

  return (
    <div>
      <Toaster />
      <TabPanel value={0} index={0} key={1}>
        {statusHistory?.map((item, index) => {
          finalInquiryData?.push([
            item.updatedAt ? formatDateAndTime(item.updatedAt) : "N/A",
            item.updatedBy ? item.updatedBy[0]?.userName : "N/A",
            item.statusForm
              ? item?.isSystemGenerated
                ? "N/A"
                : item.statusForm
              : "N/A",
            item.statusTo
              ? item?.isSystemGenerated
                ? "N/A"
                : item.statusTo
              : "N/A",
            generateTooltipContent(item.statusTo, item, index),
            renderAdditionalInfo(item.statusTo, item),
            <IconButton
              disabled={
                item.statusTo == "Assigned" || item.statusForm === item.statusTo
              }
              onClick={() => handleEditClick(item)}
              size="small"
            >
              <Edit />
            </IconButton>,
          ]);
        })}
        <Table
          data={finalInquiryData}
          header={statusHistoryHeader()}
          name={t("Activity")}
          options={{
            filterType: "checkbox",
            tableBodyHeight: "600px",
            print: false,
            download: false,
            filter: true,
            filterType: "dropdown",
            selectableRows: "none",
            sortOrder: {
              name: t("Updated At"),
              direction: "desc",
            },
          }}
        />
      </TabPanel>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Edit data for {selectedItem?.statusTo}</DialogTitle>
        <DialogContent sx={{ minWidth: { md: "400px", xs: "300px" } }}>
          <TextField
            autoFocus
            margin="normal"
            label="Notes"
            type="text"
            fullWidth
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            multiline
            minRows={4}
            sx={{ mb: 2 }}
          />
          {(selectedItem?.statusTo === "Field Visit Planned" ||
            selectedItem?.statusTo === "Field Visit Completed") && (
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateTimePicker
                sx={{ width: "100%", mb: 2 }}
                label={
                  selectedItem?.statusTo === "Field Visit Planned"
                    ? "Field visit date "
                    : "Completion date "
                }
                value={selectedDateTime}
                onChange={handleDateTimeChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    sx={{ mb: 2 }}
                  />
                )}
              />
            </LocalizationProvider>
          )}

          {selectedItem?.statusTo === "Field Visit Completed" && (
            <UploadImages onImagesUploaded={handleImagesUploaded} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loader}>
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary" disabled={loader}>
            {loader ? <CircularProgress size={20} /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InquiryHistory;
