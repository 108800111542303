import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CalendarLayout = ({
  events,
  onSelectEvent,
  onSelectSlot,
  onEventDrop,
  onEventResize,
}) => {
  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: event.color || "#3174ad", // Use event color or default
      borderRadius: "5px",
      opacity: 0.8,
      color: "white",
      border: "none",
      display: "block",
    };
    return {
      style: style,
    };
  };

  return (
    <div style={{ height: "100vh", padding: "0px" }}>
      <DnDCalendar
        localizer={localizer}
        events={events}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        resizable
        selectable
        popup
        views={["month", "week", "day", "agenda"]}
        style={{ height: "calc(100% - 40px)" }}
        eventPropGetter={eventStyleGetter} 
      />
    </div>
  );
};

export default CalendarLayout;