import React, { useState, useCallback } from "react";
import { TextField, Autocomplete } from "@mui/material";

export default function SearchOrAddCustomer({
  fetchOptions,
  onSelectCustomer,
}) {
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = useCallback(
    async (event, newInputValue) => {
      if (newInputValue.length >= 3) {
        setLoading(true);
        try {
          const fetchedOptions = await fetchOptions(newInputValue);
          setOptions(fetchedOptions);
        } catch (error) {
          console.error("Failed to fetch options:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setOptions([]);
      }
    },
    [fetchOptions]
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue) {
      onSelectCustomer(newValue);
    }
  };

  const filterOptions = (options, params) => {
    const filtered = [...options];
    const inputValue = params.inputValue.toLowerCase();
    const isExisting = options.some(
      (option) => option.branchName.toLowerCase() === inputValue
    );

    if (inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue: params.inputValue,
        branchName: `Add "${params.inputValue}"`
      });
    }
    return filtered;
  };

  const getOptionLabel = (option) => {

    if (typeof option === "string") return option;
    if (option.inputValue) return option.inputValue;
    return option.branchName || "";
  };

  const renderOption = (props, option) => (
    <li {...props} style={{ padding: "8px" }}>
      <div style={{ padding: "4px 8px" }}>
        {option.inputValue ? `Add "${option.inputValue}"` : option.branchName}
      </div>
    </li>
  );

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      filterOptions={filterOptions}
      options={options}
      groupBy={(option) => option.companyName}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      loading={loading}
      freeSolo
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search or Add Customer"
          margin="normal"
          value={value || ""}
        />
      )}
    />
  );
}
